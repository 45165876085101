import React, { Component } from "react";

class ServiceFee extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        {
          this.props.cartServiceFee > 0 ?
            <>
              <div className="d-flex justify-content-between" style={{ marginBottom: 10 }}>
                <div style={{ fontSize: 14, fontWeight: "bold" }}>
                  Total
                </div>
                <div style={{ fontSize: 14 }}>
                  ${(this.props.cartTotal + this.props.cartTaxTotal).toFixed(2)}
                </div>
              </div>
              <div className={"seperator"}></div>

              <div className="d-flex justify-content-between" style={{ marginBottom: 10 }}>
                <div style={{ fontSize: 14, fontWeight: "bold" }}>
                  Service Fee
                </div>
                <div style={{ fontSize: 14 }}>
                  ${this.props.cartServiceFee.toFixed(2)}
                </div>
              </div>

              {(this.props.cartServiceFeeTax > 0 ?
                <>
                  <div className="d-flex justify-content-between" style={{ marginBottom: 10 }}>
                    <div style={{ fontSize: 14, fontWeight: "bold" }}>
                      Service Fee Tax
                    </div>
                    <div style={{ fontSize: 14 }}>
                      ${this.props.cartServiceFeeTax.toFixed(2)}
                    </div>
                  </div>
                </>
                : null)
              }
            </>
            : null
        }

      </>
    );
  }
}

export default (ServiceFee);
