import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "../common/navbar";
import Footer from "../common/footer";
import Loader from "../common/loader";
import DialogModal from "../common/dialogModal";
import { confirmCart } from "../../actions/public/paymentAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as valid from "card-validator";
import { styles } from "../../styles/commontStyles";
import { updateCardDetails } from "../../actions/vendor/orderProcessAction";
import { Container, Col, Row } from "react-bootstrap";
import { SIMULATE_TEST_CARD } from "../../Config/CustomConfig";
import ServiceFee from "../common/ServiceFee";

class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardHolderName: { value: "", error: "" },
      cardNumber: { value: "", error: "" },
      expiryDate: { value: "", error: "" },
      cvv: { value: "", error: "" },
      displayDialog: false,
      dialogTitle: null,
      dialogBody: null,
      dialogFooter: null,
      useDefaultGuestInfo: false,
    };
  }
  componentDidMount = () => {
    window.scrollTo(0, 0);

    if (this.props.cartItems.length === 0) {
      this.props.history.push("/");
    }

    let carddetails = this.props.cardedetails;

    console.log("carddetails", carddetails);
    this.setState({
      cardHolderName: {
        ...this.state.cardHolderName,
        value: carddetails.cardHolderName,
      },
      cardNumber: {
        ...this.state.cardNumber,
        value: carddetails.cardNumber,
      },
      expiryDate: {
        ...this.state.expiryDate,
        value: carddetails.expiryDate,
      },
      cvv: {
        ...this.state.cvv,
        value: carddetails.cvv,
      },
    });
  };

  closeDialog = () => {
    this.setState({
      displayDialog: false,
    });
  };

  resetFormError = () => {
    this.setState({
      cardHolderName: { ...this.state.cardHolderName, error: "" },
      cardNumber: { ...this.state.cardNumber, error: "" },
      expiryDate: { ...this.state.expiryDate, error: "" },
      cvv: { ...this.state.cvv, error: "" },
    });
  };
  handleCardDetailSubmit = (event) => {
    event.preventDefault();
    const logindetails = JSON.parse(localStorage.getItem("loginDetail"));
    const cardNumber = this.state.cardNumber;
    const cardHolderName = this.state.cardHolderName;
    const expiryDate = this.state.expiryDate;
    const cvv = this.state.cvv;
    const cardnumberData = valid.number(cardNumber.value);

    console.clear();
    console.log("cardnumberData", cardnumberData);

    if (cardHolderName.value === null || cardHolderName.value.trim() === "") {
      this.setState({
        cardHolderName: {
          ...cardHolderName,
          error: "Card Holder is Required.",
        },
      });
    }

    if (cardNumber.value === "") {
      this.setState({
        cardNumber: { ...cardNumber, error: "Card Number is Required." },
      });
    } else if (cardnumberData.card) {
      if (
        cardnumberData.card.type === "visa" ||
        cardnumberData.card.type === "mastercard"
      ) {
        if (!SIMULATE_TEST_CARD && !cardnumberData.isValid) {
          this.setState({
            cardNumber: {
              ...cardNumber,
              error: "Please Enter a valid Card Number.",
            },
          });
        }
      } else {
        this.setState({
          cardNumber: {
            ...cardNumber,
            error: "Please Enter a valid Card Number.",
          },
        });
      }
    } else {
      this.setState({
        cardNumber: {
          ...cardNumber,
          error: "Please Enter a valid Card Number.",
        },
      });
    }

    if (expiryDate.value === "") {
      this.setState({
        expiryDate: { ...expiryDate, error: "Expiry Date is Required." },
      });
    } else if (!valid.expirationDate(expiryDate.value).isValid) {
      this.setState({
        expiryDate: {
          ...expiryDate,
          error: "Please Enter a valid Expiry Date.",
        },
      });
    }

    if (cvv.value === "") {
      this.setState({ cvv: { ...cvv, error: "CVV is Required." } });
    } else if (!valid.cvv(cvv.value, 3).isValid) {
      this.setState({ cvv: { ...cvv, error: "Please Enter a valid CVV." } });
    }
    if (
      cardHolderName.value &&
      cardNumber.value &&
      expiryDate.value &&
      cvv.value
    ) {
      if (
        valid.expirationDate(expiryDate.value).isValid &&
        valid.cvv(cvv.value, 3).isValid
      ) {
        if (!SIMULATE_TEST_CARD && !cardnumberData.isValid) {
          return false;
        }
        if (
          cardnumberData.card.type === "visa" ||
          cardnumberData.card.type === "mastercard"
        ) {
          const data = {
            user: this.props.guestInfo,
            sessionid: this.props.userDetail.sessionid,
            cart: this.props.cartItems.map((item) => ({
              productid: item.productid,
              quantity: item.quantityincart,
              taxrate: item.taxrate,
              total: item.total,
              unitcost: item.unitcost,
              vendorid: item.vendorid,
              vendorname: item.vendorname,
            })),
            cardInfo: {
              cardtype: cardnumberData.card.type,
              cardholder: this.state.cardHolderName.value.trim(),
              cvv: this.state.cvv.value,
              cardnumber: this.state.cardNumber.value,
              expirydate: this.state.expiryDate.value,
            },
            cartTotal: parseFloat((this.props.cartTotal + this.props.cartTaxTotal + this.props.cartServiceFee + this.props.cartServiceFeeTax).toFixed(2)),
            servicefee:this.props.cartServiceFee,
            servicefeetax:this.props.cartServiceFeeTax,
            scheduleId: this.props.formData.scheduleId,
            direction: this.props.formData.direction,
            logindata: {
              username: logindetails.username,
              password: logindetails.password,
              internal: logindetails.internal,
            },
          };

          // return false;
          // this.props.confirmCart(data, this.props.history);
          this.props.history.push("/cart/review", { request: data });
        }
      }
    }
  };

  setUseDefaultInfo = () => {
    const cardedetails = this.props.cardedetails;
    if (this.state.useDefaultGuestInfo === true) {
      this.setState({
        useDefaultGuestInfo: false,
        cardHolderName: {
          ...this.state.cardHolderName,
          value: "",
        },
      });

      this.props.updateCardDetails({
        ...cardedetails,
        cardHolderName: this.state.cardHolderName,
      });
    } else {
      this.setState({
        useDefaultGuestInfo: true,
        cardHolderName: {
          ...this.state.cardHolderName,
          value:
            this.props.userDetail.userdefault.firstname +
            " " +
            this.props.userDetail.userdefault.lastname,
        },
      });
      this.props.updateCardDetails({
        ...cardedetails,
        cardHolderName:
          this.props.userDetail.userdefault.firstname +
          " " +
          this.props.userDetail.userdefault.lastname,
      });
    }
  };

  handelCardNumberInput = (inputValue) => {
    const cardnumberData = valid.number(inputValue);

    if (cardnumberData.card) {
      if (SIMULATE_TEST_CARD && !cardnumberData.isValid) {
        this.setState({
          cardNumber: {
            ...this.state.cardNumber,
            error: "",
          },
        });
      } else if (!SIMULATE_TEST_CARD && !cardnumberData.isValid) {
        this.setState({
          cardNumber: {
            ...this.state.cardNumber,
            error: "",
          },
        });
      } else {
        this.setState({
          cardNumber: {
            ...this.state.cardNumber,
            error: "Please Enter a valid Card Number.",
          },
        });
      }

      this.setState({
        cardNumber: {
          ...this.state.cardNumber,
          value: inputValue,
          error: "Please Enter a valid Card Number.",
        },
      });
    } else {
      this.setState({
        cardNumber: {
          ...this.state.cardNumber,
          value: inputValue,
          error: "",
        },
      });
    }

    this.props.updateCardDetails({
      ...this.props.cardedetails,
      cardNumber: inputValue,
    });
    // form.value.cardnumber = inputValue.replace(/[^0-9]/g, "");
    // this.props.updateCardInfo(form);
  };

  handelExpDateInput = (inputValue) => {
    if (inputValue.length === 1 && inputValue > 1) {
      inputValue = `0${inputValue}`;
    }
    if (valid.expirationDate(inputValue).isValid) {
      this.setState({
        expiryDate: {
          ...this.state.expiryDate,
          value: inputValue,
          error: "",
        },
      });
    } else {
      this.setState({
        expiryDate: {
          ...this.state.expiryDate,
          value: inputValue,
          error: "Please Enter a valid Expiry Date.",
        },
      });
    }

    // form.value.expirydate = inputValue.replace(/[^0-9]/g, "");
    // this.props.updateCardInfo(form);

    this.props.updateCardDetails({
      ...this.props.cardedetails,
      expiryDate: inputValue,
    });
  };

  render() {
    const cardedetails = this.props.cardedetails;
    if (
      this.props.cartItems.length === 0 ||
      this.props.formData.scheduleId === null
    ) {
      this.props.history.push("/");
    }
    return (
      <>
        <NavBar />
        <Loader message="Processing..." />
        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />

        <Container className="back-bar-container" fluid>
          <Row className={"back-bar categoryBar"}>
            <Col lg="12" md="12">
              <a
                className={"back-link"}
                onClick={() => this.props.history.goBack()}
              >
                <FontAwesomeIcon
                  icon="chevron-left"
                  color="#777"
                  className={"back-icon"}
                />
                Back
              </a>
            </Col>
          </Row>
        </Container>

        <div className="container-fluid stores-container">
          <form onSubmit={this.handleCardDetailSubmit}>
            <div className="row">
              <div className="col-md-8">
                <div className="card">
                  <div
                    style={{
                      padding: 15,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: 18,
                        color: "#555",
                      }}
                    >
                      <h3 className="summary-title">Payment Details</h3>
                    </div>
                  </div>
                  <div className="card-body">
                    {this.props.userDetail.userdefault.subscriber === "Y" &&
                    this.props.userDetail.internal === "N" ? (
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="dafualtvalue">
                              <input
                                onChange={(e) => this.setUseDefaultInfo()}
                                id="dafualtvalue"
                                style={{ marginLeft: 10 }}
                                type="checkbox"
                                value={this.state.useDefaultGuestInfo}
                                name="dafualtvalue"
                                checked={this.state.useDefaultGuestInfo}
                              />
                              &nbsp; Use Subscription Details for Billing
                            </label>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label
                            htmlFor="cardholder"
                            style={styles.inputLabelStyle}
                          >
                            Card Holder Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            autoFocus={true}
                            value={this.state.cardHolderName.value}
                            onFocus={this.resetFormError}
                            placeholder="Enter Card Holder Name"
                            onChange={(e) => {
                              this.setState({
                                cardHolderName: {
                                  ...this.state.cardHolderName,
                                  value: e.target.value.replace(
                                    /[^a-zA-Z\s]/g,
                                    ""
                                  ),
                                },
                              });

                              this.props.updateCardDetails({
                                ...cardedetails,
                                cardHolderName:
                                  e.target.value.replace(/[^a-zA-Z\s]/g),
                              });
                            }}
                          />
                          {this.state.cardHolderName.error && (
                            <small className="text-danger">
                              {this.state.cardHolderName.error}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label
                            htmlFor="cardnumber"
                            style={styles.inputLabelStyle}
                          >
                            Card Number
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Card Number"
                            value={this.state.cardNumber.value}
                            maxLength={16}
                            onFocus={this.resetFormError}
                            // onChangeText={(val) =>
                            //   this.handelCardNumberInput(val)
                            // }
                            onChange={(e) => {
                              this.handelCardNumberInput(
                                e.target.value.replace(/[^0-9]/g, "")
                              );
                              // this.setState({
                              //   cardNumber: {
                              //     ...this.state.cardNumber,
                              //     value: e.target.value.replace(/[^0-9]/g, ""),
                              //   },
                              // });

                              // this.props.updateCardDetails({
                              //   ...cardedetails,
                              //   cardNumber: e.target.value.replace(
                              //     /[^0-9]/g,
                              //     ""
                              //   ),
                              // });
                            }}
                          />
                          <small className="form-text text-muted">
                            Only VISA and Mastercard are accepted.
                          </small>
                          {this.state.cardNumber.error && (
                            <small className="text-danger">
                              {this.state.cardNumber.error}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label
                            htmlFor="expirydate"
                            style={styles.inputLabelStyle}
                          >
                            Expiry Date
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Expiry Date in MMYY"
                            value={this.state.expiryDate.value}
                            maxLength={4}
                            onFocus={this.resetFormError}
                            onChange={(e) => {
                              this.handelExpDateInput(
                                e.target.value.replace(/[^0-9]/g, "")
                              );
                            }}
                          />

                          {this.state.expiryDate.error && (
                            <small className="text-danger">
                              {this.state.expiryDate.error}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="cvv" style={styles.inputLabelStyle}>
                            CVV
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={this.state.cvv.value}
                            maxLength={3}
                            onFocus={this.resetFormError}
                            placeholder="Enter CVV"
                            onChange={(e) => {
                              this.setState({
                                cvv: {
                                  ...this.state.cvv,
                                  value: e.target.value.replace(/[^0-9]/g, ""),
                                },
                              });

                              this.props.updateCardDetails({
                                ...cardedetails,
                                cvv: e.target.value.replace(/[^0-9]/g, ""),
                              });
                            }}
                          />
                          {this.state.cvv.error && (
                            <small className="text-danger">
                              {this.state.cvv.error}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card">
                  <div
                    style={{
                      padding: 15,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: 18,
                        color: "#555",
                      }}
                    >
                      <h3 className="summary-title">Order Summary</h3>
                    </div>
                  </div>
                  <div className="card-body">
                    <div
                      className="d-flex justify-content-between"
                      style={{ marginBottom: 10 }}
                    >
                      <div className="FS14 FWB">Sub Total</div>
                      <div className="FS14">
                        ${this.props.cartTotal.toFixed(2)}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between MB10">
                      <div className="FS14 FWB">Tax</div>
                      <div className="FS14">
                        ${this.props.cartTaxTotal.toFixed(2)}
                      </div>
                    </div>
                    <div className={"seperator"}></div>

                    <ServiceFee {...this.props} />

                    <div className="d-flex justify-content-between MB10">
                      <div className="FS16 FWB">Grand Total</div>
                      <div className="FS16 FWB">
                        $
                        {(
                          this.props.cartTotal + this.props.cartTaxTotal + this.props.cartServiceFee + this.props.cartServiceFeeTax
                        ).toFixed(2)}
                      </div>
                    </div>
                    <button
                      type="submit"
                      style={{
                        backgroundColor: "#FED23D",
                        marginTop: 20,
                        fontSize: 14,
                        fontWeight: "bold",
                        color: "#333",
                      }}
                      className="btn btn-block btn-lg"
                    >
                      Proceed
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div className="spacer"></div>
        </div>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  cartItems: state.CartReducer.cart,
  cartTotal: state.CartReducer.cartTotal,
  cartTaxTotal: state.CartReducer.cartTaxTotal,
  cartServiceFee: state.CartReducer.cartServiceFee,
  cartServiceFeeTax: state.CartReducer.cartServiceFeeTax,
  userDetail: state.SignInReducer.logindetails,
  formData: state.FlightInfoReducer.formData,
  cardedetails: state.OrderProcessReducer.cardedetails,
  guestInfo: state.PaymentReducer.guestInfo,
});

export default connect(mapStateToProps, { confirmCart, updateCardDetails })(
  Payment
);
