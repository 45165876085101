import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import "bootstrap/dist/css/bootstrap.min.css";

class Loader extends Component {
  render() {
    return (
      <Modal show={this.props.loading} centered className={'loader-model'}>
        <Modal.Body>
          <div className="text-center">
            <img alt="Loading..."
              src={require("../../../src/images/loader.gif")}
              className={"loader-img"}
            />
            <p className={"loader-msg"}>Loading</p>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => ({
  loading: state.LoaderReducer.loading,
});

export default connect(mapStateToProps)(Loader);
