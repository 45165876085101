import {
    ORDER_SUCCESS,
    ORDER_PENDING,
    ORDER_ERROR,
    ORDER_EDIT,
    ORDER_QTY_UPDATE,
    ORDER_ASSIGN_TO_AGENT,
    SHOW_QRCODE_SCANNER,
    HIDE_QRCODE_SCANNER,
    QRCODE_SCANNER_SUCCESS,
    QRCODE_SCANNER_ERROR
} from '../../actions/vendor/actionTypes';

const initialState = {
    loading: false,
    error: false,
    orders: [],
    order: [],
    assignto: "",
    assigntoname: "",
    actiontype: "",
    errormessage: "",
    displayQRScanner: false,
    QRScannerError:false,
    QRScannerErrorMsg:"",
    QRScannerSuccess:false,
}

const VendorOrderReducer = (state = initialState, action) => {
    switch (action.type) {
        case ORDER_PENDING:
            return {
                ...state,
                loading: true,
                error: false,
                orders: [],
            }
        case ORDER_SUCCESS:
            return {
                ...state,
                orders: action.payload.orders,
                actiontype: action.payload.actiontype,
                assignto: "",
                assigntoname: "",
                loading: false,
                error: false,
                errormessage: "",
            }
        case ORDER_ERROR:
            return {
                ...state,
                assignto: "",
                loading: false,
                assigntoname: "",
                errormessage: action.payload,
                error: true,
            }
        case ORDER_EDIT:
            return {
                ...state,
                order: action.payload.order,
            }
        case ORDER_QTY_UPDATE:
            const tempOrder = { ...state.order };
            tempOrder.orderitems.forEach((item) => {
                if (item.orderitemid === action.payload.orderitemid) {
                    item.appliedamount = action.payload.qty
                }
            });
            return {
                ...state,
                order: tempOrder
            }
        case ORDER_ASSIGN_TO_AGENT:
            return {
                ...state,
                assignto: action.payload.agentid,
                assigntoname: action.payload.displayName,
            }
        case SHOW_QRCODE_SCANNER:
            return {
                ...state,
                displayQRScanner: true,
                QRScannerError:false,
                QRScannerErrorMsg:"",
                QRScannerSuccess:false,
            }
        case HIDE_QRCODE_SCANNER:
            return {
                ...state,
                displayQRScanner: false,
            }
        case QRCODE_SCANNER_SUCCESS:
            return {
                ...state,
                QRScannerError:false,
                QRScannerErrorMsg:"",
                QRScannerSuccess:true,
            }
        case QRCODE_SCANNER_ERROR:
            return {
                ...state,
                QRScannerError:true,
                QRScannerErrorMsg:action.payload,
                QRScannerSuccess:false,
            }
        default:
            return state;
    }
}

export default VendorOrderReducer;