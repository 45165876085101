import {
  PRODUCT_SERVICE_ERROR,
  PRODUCT_SERVICE_PENDING,
  PRODUCT_SERVICE_SUCCESS,
  PRODUCT_CATEGORY,
  EDIT_PRODUCT_SERVICE_ERROR,
  EDIT_PRODUCT_SERVICE_PENDING,
  EDIT_PRODUCT_SERVICE_SUCCESS,
  GET_CLASSIFICATION_SUCCESS,
  GET_CLASSIFICATION_FAILED,
  UPDATE_CLASSIFICATION_SUCCESS,
  UPDATE_CLASSIFICATION_FAILED,
  GET_CLASS_TYPE_FAILED,
  GET_CLASS_TYPE_SUCCESS,
  UPDATE_CLASS_TYPE_FAILED,
  UPDATE_CLASS_TYPE_SUCCESS,
  SET_PRODUCT_MASTER_BOOLEAN,
  UPDATE_PRODUCT_MASTER_LIST_SUCCESS,
  UPDATE_PRODUCT_MASTER_LIST_FAILED,
  DELETE_PRODUCT_ERROR,
  DELETE_PRODUCT_PENDING,
  DELETE_PRODUCT_SUCCESS,
  GET_CATEGORY_FAILED,
  GET_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAILED,
  UPLOAD_PRODUCT_IMAGE_SUCCESS,
  UPLOAD_PRODUCT_IMAGE_FAILED,
  SET_PRODUCT_PREVIOUS_QUANTITY
} from "../../actions/vendor/actionTypes";

const initialState = {
  updateProductError: null,
  updateProductSuccess: null,
  products: [],
  category: [],
  classificationlist: [],
  categorylist: [],
  previousquantity: 0,
  classificationerror: false,
  classificationerrormessage: "",
  classtypeslist: [],
  classtypeerror: false,
  classtypeerrormessage: "",
  productmaster: false,
  productmasterlist: [],
  deletesuccess: false,
  deletesuccessmessage: "",
  deleteerror: false,
  deleteerrormessage: "",
  categoryerror: false,
  categoryerrormessage: "",
  uploadproductimageerror: false,
  uploadproductimageerrormessage: "",
};

const VendorProductReducer = (state = initialState, action) => {
  switch (action.type) {
    /*******************get products reducer case *********************/
    case PRODUCT_SERVICE_PENDING:
      return {
        ...state,
        error: null,
        success: null,
        classtypeerror: false,
        classtypeerrormessage: "",
        classificationerror: false,
        classificationerrormessage: "",
        categoryerror: false,
        categoryerrormessage: "",
        uploadproductimageerror: false,
        uploadproductimageerrormessage: "",
        // products: [],
      };
    case PRODUCT_SERVICE_SUCCESS:
      return {
        ...state,
        products: action.payload.products,
        success: "success",
        error: null,
      };
    case PRODUCT_SERVICE_ERROR:
      return {
        ...state,
        error: action.payload,
        success: null,
        products: [],
      };

    case EDIT_PRODUCT_SERVICE_PENDING:
      return {
        ...state,
        updateProductError: null,
        updateProductSuccess: null,
      };
    case EDIT_PRODUCT_SERVICE_SUCCESS:
      return {
        ...state,
        updateProductSuccess: action.payload,
        updateProductError: null,
      };
    case EDIT_PRODUCT_SERVICE_ERROR:
      return {
        ...state,
        updateProductError: action.payload,
        updateProductSuccess: null,
      };
    case PRODUCT_CATEGORY:
      return {
        ...state,
        category: action.payload,
      };
    case GET_CLASSIFICATION_SUCCESS:
      return {
        ...state,
        classificationlist: action.payload.classifications,
        loading: false,
        classificationerror: false,
        classificationerrormessage: "",
      };

    case GET_CLASSIFICATION_FAILED:
      return {
        ...state,
        classificationlist: [],
        classificationerror: true,
        loading: false,
        classificationerrormessage: action.payload,
      };
    case UPDATE_CLASSIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        classificationerror: false,
        classificationerrormessage: "",
      };

    case UPDATE_CLASSIFICATION_FAILED:
      return {
        ...state,
        classificationerror: true,
        loading: false,
        classificationerrormessage: action.payload,
      };

    case GET_CLASS_TYPE_SUCCESS:
      return {
        ...state,
        classtypeslist: action.payload.classtypes,
        loading: false,
        classtypeerror: false,
        classtypeerrormessage: "",
      };

    case GET_CLASS_TYPE_FAILED:
      return {
        ...state,
        classtypeslist: [],
        classtypeerror: true,
        loading: false,
        classtypeerrormessage: action.payload,
      };
    case UPDATE_CLASS_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        classtypeerror: false,
        classtypeerrormessage: "",
      };

    case UPDATE_CLASS_TYPE_FAILED:
      return {
        ...state,
        classtypeerror: true,
        loading: false,
        classtypeerrormessage: action.payload,
      };
    case SET_PRODUCT_MASTER_BOOLEAN:
      return {
        ...state,
        productmaster: action.payload,
      };

    case UPDATE_PRODUCT_MASTER_LIST_SUCCESS:
      return {
        ...state,
        productmasterlist: action.payload.products,
        loading: false,
        error: false,
        errormessage: "",
      };
    case UPDATE_PRODUCT_MASTER_LIST_FAILED:
      return {
        ...state,
        error: true,
        loading: false,
        errormessage: action.payload,
      };
    case DELETE_PRODUCT_PENDING:
      return {
        ...state,
        deletesuccess: false,
        deletesuccessmessage: "",
        deleteerror: false,
        deleteerrormessage: "",
      };
    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        deletesuccess: true,
        deletesuccessmessage: action.payload,
        deleteerror: false,
        deleteerrormessage: "",
      };
    case DELETE_PRODUCT_ERROR:
      return {
        ...state,
        deletesuccess: false,
        deletesuccessmessage: "",
        deleteerror: true,
        deleteerrormessage: action.payload,
      };
    case GET_CATEGORY_SUCCESS:
      return {
        ...state,
        categorylist: action.payload.categories,
        loading: false,
        categoryerror: false,
        categoryerrormessage: "",
      };

    case GET_CATEGORY_FAILED:
      return {
        ...state,
        categorylist: [],
        categoryerror: true,
        loading: false,
        categoryerrormessage: action.payload,
      };
    case UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        categoryerror: false,
        categoryerrormessage: "",
      };

    case UPDATE_CATEGORY_FAILED:
      return {
        ...state,
        categoryerror: true,
        loading: false,
        categoryerrormessage: action.payload,
      };
    case UPLOAD_PRODUCT_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        uploadproductimageerror: false,
        uploadproductimageerrormessage: "",
      };

    case UPLOAD_PRODUCT_IMAGE_FAILED:
      return {
        ...state,
        loading: false,
        uploadproductimageerror: true,
        uploadproductimageerrormessage: action.payload,
      };
    case SET_PRODUCT_PREVIOUS_QUANTITY:
      return {
        ...state,
        previousquantity: action.payload,
      };
    default:
      return state;
  }
};

export default VendorProductReducer;
