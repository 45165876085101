import {
    SEND_RECEIPT_SUCCESS,
    SEND_RECEIPT_PENDING,
    SEND_RECEIPT_ERROR
} from '../../actions/vendor/actionTypes';

const initialState = {
    status: null,
    errorMessage: null,
}

const SendReceiptReducer = (state = initialState, action) => {
    switch (action.type) {
        /*******************get products reducer case *********************/
        case SEND_RECEIPT_PENDING:
            return {
                ...state,
                status: 'pending',
                errorMessage: null,
            }
        case SEND_RECEIPT_SUCCESS:
            return {
                ...state,
                status: 'success',
                errorMessage: null,
            }
        case SEND_RECEIPT_ERROR:
            return {
                ...state,
                status: 'error',
                errorMessage: action.payload,
            }
        default:
            return state;
    }
}

export default SendReceiptReducer;
