import {
  RESET_FORM_DATA,
  RESET_SERVICE_PENDING,
  RESET_SERVICE_SUCCESS,
  RESET_SERVICE_ERROR,
} from "./actionTypes";
import { APPLICATION_CONST } from "../../Config/CustomConfig";
import axios from "axios";
import { loaderStatus } from "../vendor/loaderAction";

export const updateResetForm = (key, value) => (dispatch) => {
  dispatch({
    type: RESET_FORM_DATA,
    payload: { key: key, value: value },
  });
};

export const resetServicePending = () => ({
  type: RESET_SERVICE_PENDING,
});

export const resetServiceSuccess = (data) => ({
  type: RESET_SERVICE_SUCCESS,
  payload: data,
});

export const resetServiceError = (error) => ({
  type: RESET_SERVICE_ERROR,
  payload: error,
});

export const resetPasswordSubmit = (data, history) => (dispatch) => {
  dispatch(resetServicePending());
  dispatch(loaderStatus(true));
  const request = {
    username: APPLICATION_CONST.username,
    sessionid: APPLICATION_CONST.sessionid,
    failstatus: 0,
    request: {
      username: data.username,
    },
  };

  axios({
    method: "post",
    url: APPLICATION_CONST.url + "resetpassword",
    data: request,
  })
    .then((response) => {
      dispatch(loaderStatus(false));

      if (response.data.status === 0) {
        dispatch(resetServiceSuccess(response.data.statusMessage));
      } else if (response.data.status > 0) {
        dispatch(resetServiceError(response.data.statusMessage));
      } else {
        dispatch(resetServiceError(APPLICATION_CONST.message));
      }
    })
    .catch((error) => {
      dispatch(loaderStatus(false));
      dispatch(resetServiceError(error.message));
    });
};
