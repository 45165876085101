import React from 'react';
import { styles } from "../../styles/commontStyles";
import BootstrapModal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import NiceModal, { useModal, bootstrapDialog } from '@ebay/nice-modal-react';

const alertDialog  = NiceModal.create(({ title , message }) => {
    const modal = useModal();
    return (
        <BootstrapModal {...bootstrapDialog(modal)}>
        <BootstrapModal.Header closeButton>
            <BootstrapModal.Title>{title}</BootstrapModal.Title>
        </BootstrapModal.Header>

        <BootstrapModal.Body>
            <p style={{wordWrap:"break-word"}}>{message}</p>
        </BootstrapModal.Body>

        <BootstrapModal.Footer>
            <Button style={styles.dialogCancelButtonStyle} onClick={modal.hide}>
            Close
            </Button>
        </BootstrapModal.Footer>
        </BootstrapModal>
    );
})

export default alertDialog;