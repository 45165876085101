import axios from "axios";
import {
  SEND_RECEIPT_ERROR,
  SEND_RECEIPT_PENDING,
  SEND_RECEIPT_SUCCESS,
} from "./actionTypes";

import { APPLICATION_CONST } from "../../Config/CustomConfig";
import { loaderStatus } from "../../actions/vendor/loaderAction";

export const callSendReceiptWebService = (data, navigation) => (dispatch) => {
  return new Promise((res, rej)=> {
    const loginDetail = JSON.parse(localStorage.getItem("loginDetail"));
    dispatch(loaderStatus(true));
  
    var body = {
      username: loginDetail.username,
      sessionid: loginDetail.sessionid,
      failstatus: 0,
      request: data,
    };
  
    dispatch(serviceActionPending());
  
    axios({
      method: "post",
      url: APPLICATION_CONST.url + "sendreceipt",
      timeout: 10000,
      data: JSON.stringify(body),
    })
      .then((response) => {
        if (response.data.status === 0) {
          dispatch(loaderStatus(false));
          dispatch(serviceActionSuccess());
          res();
        } else if (response.data.status > 0) {
          dispatch(loaderStatus(false));
          dispatch(serviceActionError(response.data.statusMessage));
        } else {
          dispatch(loaderStatus(false));
          dispatch(serviceActionError(APPLICATION_CONST.message));
        }
        rej();

      })
      .catch((error) => {
        dispatch(loaderStatus(false));
        dispatch(serviceActionError(error.message));
        rej();
      });
  });
};

export const serviceActionPending = () => ({
  type: SEND_RECEIPT_PENDING,
});

export const serviceActionError = (error) => ({
  type: SEND_RECEIPT_ERROR,
  payload: error,
});

export const serviceActionSuccess = () => ({
  type: SEND_RECEIPT_SUCCESS,
});
