import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "../common/navbar";
import Loader from "../common/loader";
import {
  getVendorService,
} from "../../actions/vendor/vendorAction";
import {getVendorCommissionService} from "../../actions/vendor/vendorProductCommissionAction";

import Footer from "../common/footer";
import { styles } from "../../styles/commontStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { APPLICATION_CONST, AIRPORTS } from "../../Config/CustomConfig";
import { Image, Badge, Container, Col, Row } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";

class VendorProductCommission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaderMessage: "Retrieving Vendors...",
      dataTable: {
        columns: [
          {
            label: "#",
            field: "index",
            width: 10,
          },
          // {
          //   label: "Image",
          //   field: "image",
          //   sort: "asc",
          //   width: 150,
          // },
          {
            label: "Vendor Name",
            field: "vendorname",
            sort: "asc",
            width: 270,
          },
          {
            label: "Airport",
            field: "airport",
            sort: "asc",
            width: 100,
          },
          {
            label: "Direction",
            field: "direction",
            sort: "asc",
            width: 100,
          },
          {
            label: "Product Name",
            field: "productname",
            sort: "asc",
            width: 270,
          },
          {
            label: "Status",
            field: "status",
            sort: "asc",
            width: 150,
          },
          {
            label: "Use Default Rate",
            field: "usedefaultrate",
            sort: "asc",
            width: 150,
          },
          {
            label: "Default Commission Rate",
            field: "defaultcommissionrate",
            sort: "asc",
            width: 100,
          },
          {
            label: "Commission Rate",
            field: "commissionrate",
            sort: "asc",
            width: 100,
          },
          {
            label: "Action",
            field: "action",
            width: 100,
          },
        ],
        // rows: [
        //   ...this.props.vendorslist.map((vendor, index) => ({
        //     index: index + 1,
        //     image: (
        //       <Image
        //         style={{
        //           height: 50,
        //           width: 50,
        //           margin: 3,
        //           alignSelf: "center",
        //         }}
        //         resizemode="contain"
        //         src={
        //           APPLICATION_CONST.storeImageUrl +
        //           vendor.vendorreferenceid +
        //           ".jpg" +
        //           "?" +
        //           new Date()
        //         }
        //       />
        //     ),
        //     name: vendor.vendorname,
        //     airport: vendor.airportid === "NMIA" ? AIRPORTS.NMIA : AIRPORTS.SIA,
        //     contactno: vendor.contactemail,
        //     description: vendor.description.replace(
        //       /(?:\r\n|\r|\n)/g,
        //       "<br />"
        //     ),
        //     status:
        //       vendor.status === "A" ? (
        //         <Badge variant="success">Active</Badge>
        //       ) : (
        //         <Badge variant="danger">Inactive</Badge>
        //       ),
        //     action: (
        //       <span>
        //         <button
        //           onClick={() => this.onEditBtnClick(vendor)}
        //           className="btn btn-sm btn-info btn-warning"
        //           style={{ marginRight: 10, marginLeft: 10 }}
        //         >
        //           <i className="fa fa-pencil"></i>
        //         </button>
        //         <button
        //           className="btn btn-sm btn-info btn-danger"
        //           onClick={() => this.showDeleteCategoryDialog(vendor)}
        //         >
        //           <FontAwesomeIcon
        //             icon="trash"
        //             style={{ widht: 15, height: 15 }}
        //           />
        //         </button>
        //       </span>
        //     ),
        //   })),
        // ],
      },
    };
  }

  componentDidMount = () => {
    window.scrollTo(0,0);
    this.props.getVendorService();
    console.log("gajendra",this?.props?.location?.state?.vendorid)
    if(this?.props?.location?.state?.vendorid) {
      this.setState({
        ...this.state,
        vendorid:this?.props?.location?.state?.vendorid
      });
      
      let request = {}
      if(this?.props?.location?.state?.vendorid !== "ALL" && this?.props?.location?.state?.vendorid !== "-1") {
        request = {"vendorid": this?.props?.location?.state?.vendorid}
      }

      this.props.getVendorCommissionService(request);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    
  }

  onEditBtnClick = (vendorproduct) => {
    this.props.history.push("/vendorproductcommission/edit", {
      vendorproduct: vendorproduct,
      vendorid:this.state.vendorid
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.vendorproductcommissionslist !== this.props.vendorproductcommissionslist) {
      this.setState({
        dataTable: {
          ...this.state.dataTable,
          rows: [
            ...nextProps.vendorproductcommissionslist.map((vendorproduct, index) => ({
              index: index + 1,
              // image: (
              //   <Image
              //     style={{
              //       height: 50,
              //       width: 50,
              //       margin: 3,
              //       alignSelf: "center",
              //     }}
              //     resizemode="contain"
              //     src={ APPLICATION_CONST.storeImageUrl + vendorproduct.productid + ".jpg" + "?" + new Date()}
              //   />
              // ),
              vendorname: vendorproduct.vendorname,
              airport: vendorproduct.airportid === "NMIA" ? AIRPORTS.NMIA : AIRPORTS.SIA,
              direction: vendorproduct.direction === "A" ? "Arrival":"Departure",
              productname: vendorproduct.productname,
              status:
                vendorproduct.status === "A" ? (
                  <Badge variant="success">Active</Badge>
                ) : (
                  <Badge variant="danger">Inactive</Badge>
                ),
              usedefaultrate: vendorproduct.usedefaultrate,
              defaultcommissionrate: vendorproduct.defaultcommissionrate,
              commissionrate: vendorproduct.commissionrate == -1 ? "" : vendorproduct.commissionrate,
              action: (
                <span>
                  <button
                    onClick={() => this.onEditBtnClick(vendorproduct)}
                    className="btn btn-sm btn-info btn-warning"
                    style={{ marginRight: 10, marginLeft: 10 }}
                  >
                    <i className="fa fa-pencil"></i>
                  </button>
                </span>
              ),
            })),
          ],
        },
      });
    }
  }

  vendorIDChanged = (e) => {
    this.setState({
      ...this.state,
      vendorid:e.target.value
    });
  
    let request = {}
    if(e.target.value !== "ALL" && e.target.value !== "-1") {
      request = {"vendorid": e.target.value}
    }

    this.props.getVendorCommissionService(request);
  }

  render() {
    return (
      <>
        <NavBar />
        <Loader message={this.state.loaderMessage} />
        <Container className={"back-bar-container"} fluid>
          <Row className={"categoryBar back-bar"}>
            <Col lg="12" md="12">
              <a className={"back-link"} onClick={() => this.props.history.push("/")}>
                <FontAwesomeIcon
                  icon="chevron-left"
                  color="#777"
                  className={"back-icon "}
                />
                Back
              </a>
              <ul>
                <li>Vendor Products</li>
              </ul>
            </Col>
          </Row>
        </Container>

        <Container fluid>
          <Row className="stores-container">
            <Col lg="2" md="2">Select Vendor</Col>

            <Col lg="10" md="10">
              <select id={"vendorid"}
                  className="form-control"
                  onChange={this.vendorIDChanged}
                  autoFocus={true}
                  value={this.state.vendorid}
                >
                  <option value="-1">--Select Vendor--</option>
                  <option value="ALL">--All Vendors--</option>
                  {this.props.vendorslist.map(
                      (vendor) => (
                        <option
                          key={vendor.vendorid}
                          value={vendor.vendorid}
                          data-vendorid={vendor.vendorid}
                        >
                          {vendor.vendorname + " - " + (vendor.airportid === "NMIA" ? AIRPORTS.NMIA : AIRPORTS.SIA) + " - " + (vendor.direction === "A" ? "Arrival":"Departure" ) }
                        </option>
                      )
                    )
                  }
                </select>
            </Col>
          </Row>
        </Container>

        <Container className="stores-container " fluid>
          { (this.state.vendorid === undefined || this.state.vendorid === "-1") ? null : 
            <div style={{ padding: 15 }} className="custom_datatable ">
              
                <MDBDataTable
                  striped
                  bordered
                  hover
                  small
                  data={this.state.dataTable}
                />
            </div>
          }
          <div className="spacer"></div>
          </Container>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  logindetails: state.SignInReducer.logindetails,
  vendorslist: state.VendorReducer.vendorslist,
  vendorerror: state.VendorReducer.vendorerror,
  vendorerrormessage: state.VendorReducer.vendorerrormessage,
  vendorproductcommissionslist:state.VendorProductCommissionReducer.vendorproductcommissionslist
});
export default connect(mapStateToProps, {
  getVendorService,
  getVendorCommissionService,
})(VendorProductCommission);
