import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { styles } from "../../styles/commontStyles";
import NavBar from "../common/navbar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Footer from "../common/footer";
import { getReportService } from "../../actions/vendor/reportAction";
//import PDFViewer from "pdf-viewer-reactjs";
import { APPLICATION_CONST } from "../../Config/CustomConfig";
import {
  WhatsappIcon,
  EmailShareButton,
  WhatsappShareButton,
  EmailIcon,
} from "react-share";
import Loader from "../common/loader";
import DialogModal from "../common/dialogModal";
import Modal from "react-bootstrap/Modal";

import { callSendReceiptWebService } from "../../actions/vendor/sendReceiptAction";

class Runreport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayDialog: false,
      dialogTitle: null,
      dialogBody: null,
      dialogFooter: null,
      showPrinter: false,
      printReceipt: false,
      guestemail: "",
      emailaddresserror: "",
      emailonly: true,
      isOpenEmailPrompt: false,
      errorMessage: {
        guestemail: "",
      },
    };
  }
  handleFromChange = (value) => {
    this.setState({
      fromDate: value,
    });
    const dateString = `${value.getFullYear()}/${value.getMonth() < 9 ? "0" + (value.getMonth() + 1) : value.getMonth() + 1
      }/${value.getDate() < 10 ? "0" + value.getDate() : value.getDate()}`;
    this.setState({
      from: dateString,
    });
  };

  emailReceipt = () => {
    const orderid = this.props.location.state.orderid;

    let filename = this.props.location.state.reportFilename;
    this.setState({ printReceipt: false });
    this.props.callSendReceiptWebService({
      orderid: orderid,
      filename: filename,
    });
  };

  handleToChange = (value) => {
    this.setState({
      toDate: value,
    });
    const dateString = `${value.getFullYear()}/${value.getMonth() < 9 ? "0" + (value.getMonth() + 1) : value.getMonth() + 1
      }/${value.getDate() < 10 ? "0" + value.getDate() : value.getDate()}`;
    this.setState({
      to: dateString,
    });
  };
  componentDidMount = () => {
    window.scrollTo(0, 0);

  };

  closeDialog = () => {
    this.setState({
      displayDialog: false,
    });
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.status === "error") {
      if (nextProps.status !== this.props.status && nextProps.status !== null) {
        this.setState({
          displayDialog: true,
          dialogTitle: "Oops!",
          dialogBody: nextProps.errorMessage,
          dialogFooter: (
            <button
              className="btn"
              style={styles.dialogCancelButtonStyle}
              onClick={this.closeDialog}
            >
              Close
            </button>
          ),
        });
      }
    }

    if (nextProps.status === "success") {
      if (nextProps.status !== this.props.status && nextProps.status !== null) {
        this.setState({
          displayDialog: true,
          dialogTitle: "Success!",
          dialogBody: "Email sent successfully",
          dialogFooter: (
            <button
              className="btn"
              style={styles.dialogCancelButtonStyle}
              onClick={this.closeDialog}
            >
              Close
            </button>
          ),
        });
      }
    }
  };

  showReport = (e) => {
    e.preventDefault();
    let toDate = this.state.toDate;
    let fromDate = this.state.fromDate;

    const startDate = `${fromDate.getFullYear()}${fromDate.getMonth() < 9
        ? "0" + (fromDate.getMonth() + 1)
        : fromDate.getMonth() + 1
      }${fromDate.getDate() < 10 ? "0" + fromDate.getDate() : fromDate.getDate()
      }`;
    const endDate = `${toDate.getFullYear()}${toDate.getMonth() < 9
        ? "0" + (toDate.getMonth() + 1)
        : toDate.getMonth() + 1
      }${toDate.getDate() < 10 ? "0" + toDate.getDate() : toDate.getDate()}`;

    const data = {
      username: this.props.logindetails.username,
      sessionid: this.props.logindetails.sessionid,
      request: {
        extractid: 1,
        startdate: startDate,
        enddate: endDate,
      },
    };
    this.props.getReportService(data);
    this.setState((prevState) => ({
      toggle: !prevState.toggle,
    }));
  };

  showEmailPromptDialog = () => {
    let guestemail = false;

    if (this.props.location.state.receiptdata) {
      guestemail = this.props.location.state.receiptdata.guestemail;
    } else if (this.props.order) {
      guestemail = this.props.order.guest.guestemail;
    }

    if (this.props.location.state.email) {
      guestemail = this.props.location.state.email;
    }


    if (
      guestemail === "" ||
      guestemail === "NA" ||
      this.isValidEmail(guestemail) === false
    ) {
      this.setState({
        isOpenEmailPrompt: true,
        guestemail: "",
        errorMessage: { guestemail: "" },
      });
    } else {
      const orderid = this.props.location.state.orderid;
      let filename = this.props.location.state.reportFilename;
      this.props.callSendReceiptWebService({
        orderid: orderid,
        filename: filename,
      });
    }
  };

  handleSubmit = () => {
    const guestemail = this.state.guestemail;
    let errorMessage = {
      guestemail: "",
    };

    if (!guestemail.trim()) {
      errorMessage.guestemail = "Email is required.";
    } else {
      if (!this.isValidEmail(guestemail)) {
        errorMessage.guestemail = "Please enter a valid email address.";
      }
    }

    this.setState({ errorMessage: errorMessage });
    if (errorMessage.guestemail.trim() === "") {
      const orderid = this.props.location.state.orderid;
      let filename = this.props.location.state.reportFilename;
      this.setState({ printReceipt: false });
      this.props.callSendReceiptWebService({
        orderid: orderid,
        filename: filename,
        email: this.state.guestemail,
      });
      this.setState({
        isOpenEmailPrompt: false,
        guestemail: "",
        errorMessage: { guestemail: "" },
      });
    }
  };

  emailChange = (e) => {
    this.setState({
      guestemail: e.target.value.trim(),
    });
  };

  isValidEmail = (email) => {
    var expr = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    return expr.test(email);
  };

  goToBack = () => {
    const actiontype = this.props.location.state.actionype;

    if (actiontype === "RECEIPTREPRINT") {
      this.props.history.push("/orders", {
        actiontype: "REPRINTABLERECEIPTS",
        subaction: "",
      });
    } else {
      this.props.history.push("/");
    }
  };

  render() {
    let reportFileName = this.props.location.state.reportFilename;
    const source = APPLICATION_CONST.reportFilePath + reportFileName;

    return (
      <>
        <NavBar />
        <Loader message={this.props.location.state?.walkin == "Y" ? "Sending Receipt..." : "Sending Delivery Slip..."} />

        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />

        <div
          style={{
            backgroundColor: "#F1F1F1",
            borderTop: "1px solid #DDD",
            borderBottom: "1px solid #DDD",
            marginBottom: 20,
          }}
        >
          <div style={{ padding: 20, textAlign: "center" }}>
            <div style={styles.pageHeadingStyle}>{
              this.props.location.state.actiontype === "REFUNDORDER" ? "Refund Slip" : (this.props.location.state?.walkin === "Y" ? "Receipt" : "Delivery Slip")}</div>
          </div>
        </div>

        <div
          className="container-fluid"
          style={{ paddingBottom: 100, margin: 0 }}
        >

          <center>
            <iframe
              id="receipt"
              src={source}
              style={{ display: "block", height: 600, width: 500 }}
              title={ this.props.location.state.actiontype === "REFUNDORDER" ? "Refund Slip" : (this.props.location.state?.walkin === "Y" ? "Receipt" : "Delivery Slip")}
            />
          </center>

          <div className="col-md-12">
            <center>
              <button
                className="btn btn-success"
                style={{ marginLeft: 10 }}
                onClick={() => this.showEmailPromptDialog()}
              >
                Email
              </button>
              <button
                className="btn btn-success"
                style={{ marginLeft: 10 }}
                onClick={() => this.goToBack()}
              >
                Back
              </button>
            </center>
          </div>
        </div>

        <Footer />

        {/* ******** Payment Modal */}
        <Modal
          show={this.state.isOpenEmailPrompt}
          centered
          onHide={this.hidePaymentModal}
        >
          <Modal.Header style={{ backgroundColor: "#F2F2F2" }}>
            <Modal.Title style={styles.dialogHeaderStyle}>
              Enter Email Address
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={styles.dialogBodyTextStyle}>
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="email">Email:</label>
                  <input
                    type="email"
                    className="form-control"
                    value={this.state.guestemail}
                    onChange={this.emailChange}
                    onFocus={this.resetError}
                  />
                  {this.state.errorMessage.guestemail && (
                    <small className="text-danger">
                      {this.state.errorMessage.guestemail}
                    </small>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <>
              <button
                style={styles.dialogConfirmButtonStyle}
                className="btn"
                onClick={() => {
                  this.handleSubmit();
                }}
              >
                Ok
              </button>
              <button
                style={styles.dialogCancelButtonStyle}
                className="btn"
                onClick={() => {
                  this.setState({
                    isOpenEmailPrompt: false,
                    guestemail: "",
                    errorMessage: { guestemail: "" },
                  });
                }}
              >
                Close
              </button>
            </>
          </Modal.Footer>
        </Modal>
        {/* ******** Payment Modal End*/}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  logindetails: state.SignInReducer.logindetails,
  status: state.SendReceiptReducer.status,
  errorMessage: state.SendReceiptReducer.errorMessage,
  order: state.OrderProcessReducer.order,
});

export default connect(mapStateToProps, {
  getReportService,
  callSendReceiptWebService,
})(Runreport);
