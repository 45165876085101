// © 2015 Club Mobay All rights reserved

import React, {Component} from "react";
import {Navbar, Container, Row, Col, Image} from "react-bootstrap";
import {Link} from "react-router-dom";
import {APPLICATION_CONST} from "../../Config/CustomConfig";
import {styles} from "../../styles/commontStyles";

export default class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayDialog: false,
            dialogTitle: null,
            dialogBody: null,
            dialogFooter: null,
        };
    }

    render() {
        return (
            <Navbar style={styles.footer_nav} sticky="bottom">
                <Container fluid style={styles.footer_nav_Container}>
                    <Row style={{width: "100%", padding: 0}}>
                        <Col className="col-md-9">
                            <div style={styles.footer_title}>
                                DUTY FREE STORE
                            </div>
                            <div>
                                <Link to="/Aboutus" style={styles.footer_menu}>
                                    About Us
                                </Link>
                            </div>
                            <div>
                                <Link to="/Servicework" style={styles.footer_menu}>
                                    How does the service work?
                                </Link>
                            </div>
                            <div>
                                <Link to="/Contactus" style={styles.footer_menu}>
                                    Contact Us
                                </Link>
                            </div>
                            <div>
                                <Link to="/Pickuplocations" style={styles.footer_menu}>
                                    Pick Up Locations
                                </Link>
                            </div>
                            <div>
                                <Link to="/CustomsAllowances" style={styles.footer_menu}>
                                    Customs Allowances
                                </Link>
                            </div>
                        </Col>

                        <Col>
                            <div style={styles.footer_title}>
                                HOW CAN WE HELP YOU?
                            </div>
                            <div>
                                <Link to="/AboutPayment" style={styles.footer_menu}>
                                    About Payment
                                </Link>
                            </div>
                            <div>
                                <Link to="/CancellationPolicy" style={styles.footer_menu}>
                                    Cancellation Policy
                                </Link>
                            </div>
                            <div>
                                <Link to="/faq" style={styles.footer_menu}>
                                    FAQs
                                </Link>
                            </div>
                            <div>
                                <Link to="/TermsofUse" style={styles.footer_menu}>
                                    Terms of Use
                                </Link>
                            </div>
                            <div>
                                <Link to="/privacy-policy" style={styles.footer_menu}>
                                    Privacy Policy
                                </Link>
                            </div>
                        </Col>

                        {/*<Col>*/}
                        {/*    <div*/}
                        {/*        style={{fontWeight: "bold", fontSize: 12, marginBottom: 10}}*/}
                        {/*    >*/}
                        {/*        CATEGORIES*/}
                        {/*    </div>*/}

                        {/*    <div style={{fontSize: 11}}>Liquor/Drink</div>*/}
                        {/*    <div style={{fontSize: 11}}>Food</div>*/}
                        {/*    <div style={{fontSize: 11}}>Souvenirs</div>*/}
                        {/*    <div style={{fontSize: 11}}>Clothing Apparel</div>*/}
                        {/*</Col>*/}

                        {/*<Col>*/}
                        {/*    <div*/}
                        {/*        style={{fontWeight: "bold", fontSize: 12, marginBottom: 10}}*/}
                        {/*    >*/}
                        {/*        NOT SURE WHAT SHOULD GO HERE*/}
                        {/*    </div>*/}

                        {/*    <div style={{fontSize: 11}}>Lorem Ipsum 1</div>*/}
                        {/*    <div style={{fontSize: 11}}>Lorem Ipsum 2</div>*/}
                        {/*    <div style={{fontSize: 11}}>Lorem Ipsum 3</div>*/}
                        {/*</Col>*/}
                    </Row>

                    <Col>
                        <div
                            style={styles.footer_logo_div}
                        >
                            <Image
                                style={styles.footer_logo}
                                resizemode="contain"
                                src={APPLICATION_CONST.applicationImages + "logo.png"}
                            />
                        </div>
                    </Col>

                    <Col>
                        <div style={styles.footer_border}></div>
                    </Col>

                    <Row>
                        <Col>
              <span style={styles.footer_txt}>© {new Date().getFullYear()} <a href="https://www.reliablesoftjm.com/" target="_blank" rel="noopener noreferrer" style={{color: "#212529"}}> Reliable Software Solutions</a>. All Rights Reserved
              </span>
                            {/*<span style={styles.footer_txt_pip}>| </span>*/}
                            {/*<span style={styles.footer_txt}>*/}
                            {/*    <Link to="/TermsofUse" style={{color: "#212529"}}>*/}
                            {/*    Terms & Conditions*/}
                            {/*    </Link>*/}
                            {/*</span>*/}
                            {/*<span style={styles.footer_txt_pip}>|</span>*/}
                            {/*<span style={styles.footer_txt}>*/}
                            {/*    <Link to="/privacy-policy" style={{color: "#212529"}}>*/}
                            {/*    Privacy Policy*/}
                            {/*    </Link>*/}
                            {/*</span>*/}
                        </Col>
                    </Row>
                </Container>
            </Navbar>
        );
    }
}
