import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  EMPTY_CART,
  CHANGE_PRODUCT_QUANTITY,
  IS_CART_ITEM_EDIT,
  UPDATE_CART_ITEM,
} from "../../actions/public/actionTypes";

const initialStates = {
  cart: [],
  cartTotal: 0,
  cartTaxTotal: 0,
  cartServiceFee: 0,
  cartServiceFeeTax: 0,
  productTracker: [],
  isEdit: false,
  carttype: "",
};

const _calcCartTotal = (cartitems) => {
  let total = 0;
  cartitems.forEach((item) => {
    total += parseFloat((item.unitcost * item.quantityincart).toFixed(2));
  });

  total = parseFloat(total.toFixed(2));

  return total;
};

const _calcCartTaxTotal = (cartitems) => {
  let charge = 0;
  let taxTotal = 0;
  cartitems.forEach((item) => {
    charge = (item.unitcost * item.quantityincart).toFixed(2);
    taxTotal += parseFloat(charge) * parseFloat(item.taxrate);
  });

  taxTotal = parseFloat(taxTotal.toFixed(2));

  return taxTotal;
};

const _calcCartServiceFee = (cartitems) => {
  let serviceFeeRate = 0;
  let serviceFee = 0;
  let rowTotal = 0;
 
  serviceFeeRate = applicableRates?.servicefeerate;

  if(userdefault?.loungeoperator === "Y" || userdefault?.checkout === "Y") {
    // Do Nothing
  } else {
    cartitems.forEach((item) => {
      if(item.servicefeeapplicable === "Y") {
        //rowTotal = (item.unitcost * item.quantityincart).toFixed(2);
        //serviceFee += parseFloat((rowTotal * serviceFeeRate).toFixed(2));
        rowTotal = (item.unitcost * item.quantityincart);
        serviceFee += parseFloat(rowTotal * serviceFeeRate);
      }
    });

    serviceFee = parseFloat(serviceFee.toFixed(2));
  }

  return serviceFee;
};

const _calcCartServiceFeeTax = (serviceFee) => {
  let serviceFeeTaxRate = 0;
  let serviceFeeTax = 0;
  
  serviceFeeTaxRate = applicableRates?.servicefeetaxrate;
  serviceFeeTax = parseFloat((serviceFee * serviceFeeTaxRate).toFixed(2));

  return serviceFeeTax;
};

let applicableRates;
let userdefault;

const CartReducer = (state = initialStates, action) => {
  let tempCart;
  let tempProductTracker;
  let tmpCartTotal = 0;
  let tmpServiceFee = 0;
  let tmpServiceFeeTax = 0;
  
  const loginDetail = JSON.parse(localStorage.getItem("loginDetail"));
  const flightFormData = JSON.parse(sessionStorage.getItem("state"))?.FlightInfoReducer?.formData;
  userdefault = JSON.parse(sessionStorage.getItem("state"))?.SignInReducer?.logindetails?.userdefault;

  if(loginDetail?.rates) {
    applicableRates = loginDetail?.rates.find(    
      (item) => item?.airportid === flightFormData?.airportid
    );
  }
  
  switch (action.type) {
    case ADD_TO_CART:
      const productExistInCart = state.cart.find(
        (p) =>
          p.productid === action.payload.productid &&
          p.storeid === action.payload.storeid
      );
      if (productExistInCart) {
        productExistInCart.quantityincart = action.payload.quantityincart || 1;
        return {
          cart: [...state.cart, productExistInCart],
          ...state,
        };
      } else {
        const product = {
          vendorid: action.payload.vendorid,
          vendorname: action.payload.vendorname,
          productid: action.payload.productid,
          unitcost: action.payload.unitcost,
          // quantity: action.payload.quantity || 1,
          quantityincart: action.payload.quantityincart || 1,
          total: parseFloat((action.payload.unitcost * action.payload.quantityincart).toFixed(2)),
          taxrate: action.payload.taxrate,
          categoryid: action.payload.categoryid,
          servicefeeapplicable:action.payload.servicefeeapplicable
        };

        tempCart = [product, ...state.cart];

        const productracker = {
          vendorid: action.payload.vendorid,
          productid: action.payload.productid,
          rootproductid: action.payload.rootproductid,
        };

        tempProductTracker = [productracker, ...state.productTracker];
        tmpCartTotal = _calcCartTotal(tempCart);
        tmpServiceFee = _calcCartServiceFee(tempCart);
        tmpServiceFeeTax = _calcCartServiceFeeTax(tmpServiceFee);

        return {
          ...state,
          cart: tempCart,
          cartTotal: tmpCartTotal,
          cartTaxTotal: _calcCartTaxTotal(tempCart),
          cartServiceFee: tmpServiceFee,
          cartServiceFeeTax: tmpServiceFeeTax,
          productTracker: tempProductTracker,
        };
      }

    case REMOVE_FROM_CART:
      tempCart = state.cart.filter(
        (item) => item.productid !== action.payload.item.productid
      );

      tempProductTracker = state.productTracker.filter(
        (item) => item.productid !== action.payload.item.productid
      );

      tmpCartTotal = _calcCartTotal(tempCart);
      tmpServiceFee = _calcCartServiceFee(tempCart);
      tmpServiceFeeTax = _calcCartServiceFeeTax(tmpServiceFee);

      return {
        ...state,
        cart: tempCart,
        cartTotal: tmpCartTotal,
        cartTaxTotal: _calcCartTaxTotal(tempCart),
        cartServiceFee: tmpServiceFee,
        cartServiceFeeTax: tmpServiceFeeTax,
        productTracker: tempProductTracker,
      };

    case EMPTY_CART:
      return {
        ...state,
        cart: [],
        cartTotal: 0,
        cartTaxTotal: 0,
        cartServiceFee: 0,
        cartServiceFeeTax: 0,
        isEdit: false,
      };
    case CHANGE_PRODUCT_QUANTITY:
      tempCart = [...state.cart];
      tempProductTracker = [...state.productTracker];

      tempCart.forEach((product) => {
        if (product.productid === action.payload.productId) {
          product.quantityincart = action.payload.quantityincart;
          product.total = parseFloat((product.quantityincart * product.unitcost).toFixed(2));
          return;
        }
      });

      tmpCartTotal = _calcCartTotal(state.cart);
      
      tmpServiceFee = _calcCartServiceFee(tempCart);
      tmpServiceFeeTax = _calcCartServiceFeeTax(tmpServiceFee);

      return {
        ...state,
        cart: tempCart,
        cartTotal: tmpCartTotal,
        cartTaxTotal: _calcCartTaxTotal(state.cart),
        cartServiceFee: tmpServiceFee,
        cartServiceFeeTax: tmpServiceFeeTax,
      };
    case IS_CART_ITEM_EDIT:
      return {
        ...state,
        isEdit: action.payload,
      };

    case UPDATE_CART_ITEM:
      tempCart = [...state.cart];      
      tempProductTracker = [...state.productTracker];

      const product = {
        vendorid: action.payload.vendorid,
        vendorname: action.payload.item.vendorname,

        productid: action.payload.item.productid,
        unitcost: action.payload.item.unitcost,
        quantityincart: action.payload.item.quantityincart,
        total: parseFloat((action.payload.item.unitcost * action.payload.item.quantityincart).toFixed(2)),
        taxrate: action.payload.item.taxrate,
      };

      const productracker = {
        vendorid: action.payload.vendorid,
        productid: action.payload.item.productid,
        rootproductid: tempProductTracker[action.payload.index].rootproductid,
      };

      tempCart[action.payload.index] = product;

      tempProductTracker[action.payload.index] = productracker;
      tmpCartTotal = _calcCartTotal(tempCart);
      tmpServiceFee = _calcCartServiceFee(tempCart);
      tmpServiceFeeTax = _calcCartServiceFeeTax(tmpServiceFee);
      
      return {
        ...state,
        cart: tempCart,
        cartTotal: tmpCartTotal,
        cartTaxTotal: _calcCartTaxTotal(tempCart),
        cartServiceFee: tmpServiceFee,
        cartServiceFeeTax: tmpServiceFeeTax,        
        cartGrandTotal: tmpCartTotal + _calcCartTaxTotal(tempCart) + tmpServiceFee + tmpServiceFeeTax,
        productTracker: tempProductTracker,
      };

    default:
      return state;
  }
};

export default CartReducer;
