import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "../common/navbar";
import Loader from "../common/loader";
import DialogModal from "../common/dialogModal";
import { editUserService } from "../../actions/vendor/userAction";
import Footer from "../common/footer";
import { styles } from "../../styles/commontStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Col, Row } from "react-bootstrap";

class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        username: "",
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        status: "I",
        reportagent: "N",
        adminuser: "N",
      },
      edit: false,
      displayDialog: false,
      dialogTitle: null,
      dialogBody: null,
      dialogFooter: null,
      userErr: {
        username: "",
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
      },
    };
  }
  componentDidMount = () => {
    window.scrollTo(0,0);

    if (this.props.location.state) {
      const result = this.props.users.find(
        (user) => user.username === this.props.location.state.username
      );
      this.setState({
        user: result,
        edit: true,
      });
    } else if (this.props.location.pathname === "/users/edit") {
      this.props.history.push("/");
    }
  };
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.userServiceError !== this.props.userServiceError &&
      nextProps.userServiceError !== null
    ) {
      this.setState({
        displayDialog: true,
        dialogTitle: "Oops!",
        dialogBody: nextProps.userServiceError,
        dialogFooter: (
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={this.closeDialog}
          >
            Close
          </button>
        ),
      });
    }

    if (
      nextProps.userServiceSuccess &&
      nextProps.userServiceSuccess !== this.props.userServiceSuccess
    ) {
      this.setState({
        displayDialog: true,
        dialogTitle: "Success!",
        dialogBody: `User ${
          this.state.edit ? "updated" : "added"
        } successfully!`,
        dialogFooter: (
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={() => {
              this.closeDialog();
              this.props.history.push("/users");
            }}
          >
            Close
          </button>
        ),
      });
    }
  }
  closeDialog = () => {
    this.setState({
      displayDialog: false,
    });
  };

  ValidateEmail = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }

    return false;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const history = this.props.history;
    const username = this.state.user.username;
    const firstname = this.state.user.firstname;
    const lastname = this.state.user.lastname;
    const email = this.state.user.email;
    const phone = this.state.user.phone;

    let userErr = {
      username: "",
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
    };
    if (username === "") {
      userErr.username = "Username is required.";
    }
    if (firstname === "") {
      userErr.firstname = "First name is required.";
    }
    if (lastname === "") {
      userErr.lastname = "Last name is required.";
    }
    if (email === "" || email === null) {
      userErr.email = "Email is required.";
    }

    if (email !== "" && email !== null) {
      if (!this.ValidateEmail(email)) {
        userErr.email = "Please enter a valid email address.";
        // return false;
      }
    }

    if (phone === "") {
      userErr.phone = "Phone number is required.";
    }
    this.setState({ userErr: userErr });

    if (
      username !== "" &&
      firstname !== "" &&
      lastname !== "" &&
      email !== "" &&
      phone !== "" &&
      userErr.username === "" &&
      userErr.firstname === "" &&
      userErr.lastname === "" &&
      userErr.email === "" &&
      userErr.phone === ""
    ) {
      const data = {
        username: this.props.logindetails.username,
        sessionid: this.props.logindetails.sessionid,
        user: this.state.user,
        edit: this.state.edit,
      };
      this.props.editUserService(data, history);
    }
  };

  resetError = () => {
    let userErr = {
      username: "",
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
    };
    this.setState({ userErr: userErr });
  };

  autoFocusControl(fieldName) {
    var url = window.location.href;
    var lastSegment = url.substring(url.lastIndexOf("/") + 1);
    if (lastSegment === "edit") {
      return fieldName === "UserName" ? false : true;
    } else {
      return fieldName === "UserName" ? true : false;
    }
  }

  render() {
    const logindetails = this.props.logindetails;
    return (
      <>
        <NavBar />
        <Loader message="Processing" />
        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />

        <Container className={"back-bar-container"} fluid>
          <Row className={"categoryBar back-bar"}>
            <Col lg="12" md="12">
              <a
                href="javascript:void(0);"
                className={"back-link"}
                onClick={() => this.props.history.push("/users")}
              >
                <FontAwesomeIcon
                  icon="chevron-left"
                  color="#777"
                  className={"back-icon "}
                />
                Back
              </a>
              {/* <ul>
                <li>{this.state.edit ? "Edit User" : "Add User"}</li>
              </ul> */}
            </Col>
          </Row>
        </Container>
        <div className="container-fluid stores-container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <form onSubmit={this.handleSubmit}>
                <div className="card">
                  <div className="card-header custom-card-header">
                    <h4>{this.state.edit ? "Edit User" : "Add User"}</h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group row">
                          <div className="control-label col-md-2">
                            <label
                              htmlFor="username"
                              style={styles.inputLabelStyle}
                              className="required"
                            >
                              Username
                            </label>
                          </div>
                          <div className="col-md-10 ">
                            <input
                              id="username"
                              onChange={(e) =>
                                this.setState({
                                  user: {
                                    ...this.state.user,
                                    username: e.target.value.replace(
                                      /[^a-z0-9]/g,
                                      ""
                                    ),
                                  },
                                })
                              }
                              onFocus={this.resetError}
                              type="text"
                              value={this.state.user.username}
                              className="form-control"
                              autoFocus={this.autoFocusControl("UserName")}
                              placeholder="Enter Username"
                              disabled={this.state.edit}
                            />
                            {this.state.userErr.username && (
                              <small className="text-danger">
                                {this.state.userErr.username}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label
                              htmlFor="fisrtname"
                              style={styles.inputLabelStyle}
                              className="required"
                            >
                              First Name
                            </label>
                          </div>
                          <div className="col-md-8">
                            <input
                              id="fisrtname"
                              onChange={(e) =>
                                this.setState({
                                  user: {
                                    ...this.state.user,
                                    firstname: e.target.value.replace(
                                      /[^a-zA-Z]/g,
                                      ""
                                    ),
                                  },
                                })
                              }
                              onFocus={this.resetError}
                              type="text"
                              value={this.state.user.firstname}
                              className="form-control"
                              autoFocus={this.autoFocusControl("FirstName")}
                              placeholder="Enter First Name"
                            />
                            {this.state.userErr.firstname && (
                              <small className="text-danger">
                                {this.state.userErr.firstname}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label
                              htmlFor="lastname"
                              style={styles.inputLabelStyle}
                              className="required"
                            >
                              Last Name
                            </label>
                          </div>
                          <div className="col-md-8">
                            <input
                              id="lastname"
                              onChange={(e) =>
                                this.setState({
                                  user: {
                                    ...this.state.user,
                                    lastname: e.target.value.replace(
                                      /[^a-zA-Z]/g,
                                      ""
                                    ),
                                  },
                                })
                              }
                              onFocus={this.resetError}
                              type="text"
                              value={this.state.user.lastname}
                              className="form-control"
                              placeholder="Enter Last Name"
                            />
                            {this.state.userErr.lastname && (
                              <small className="text-danger">
                                {this.state.userErr.lastname}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label
                              htmlFor="email"
                              style={styles.inputLabelStyle}
                              className="required"
                            >
                              Email
                            </label>
                          </div>
                          <div className="col-md-8">
                            <input
                              id="email"
                              onChange={(e) =>
                                this.setState({
                                  user: {
                                    ...this.state.user,
                                    email: e.target.value,
                                  },
                                })
                              }
                              onFocus={this.resetError}
                              type="email"
                              value={this.state.user.email}
                              className="form-control"
                              placeholder="Enter Email"
                            />
                            {this.state.userErr.email && (
                              <small className="text-danger">
                                {this.state.userErr.email}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label
                              htmlFor="phone"
                              style={styles.inputLabelStyle}
                              className="required"
                            >
                              Phone
                            </label>
                          </div>
                          <div className="col-md-8">
                            <input
                              id="phone"
                              onChange={(e) =>
                                this.setState({
                                  user: {
                                    ...this.state.user,
                                    phone: e.target.value.replace(
                                      /[^0-9]/g,
                                      ""
                                    ),
                                  },
                                })
                              }
                              onFocus={this.resetError}
                              maxLength={10}
                              type="text"
                              value={this.state.user.phone}
                              className="form-control"
                              placeholder="Enter Phone Number"
                            />
                            {this.state.userErr.phone && (
                              <small className="text-danger">
                                {this.state.userErr.phone}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label
                              htmlFor="status"
                              style={styles.inputLabelStyle}
                              className="required"
                            >
                              Status
                            </label>
                          </div>
                          <div className="form-group col-md-8">
                            <label className="control-label">
                              <input
                                type="radio"
                                onChange={(e) =>
                                  this.setState({
                                    user: {
                                      ...this.state.user,
                                      status:
                                        e.target.value === "A" ? "I" : "A",
                                    },
                                  })
                                }
                                checked={this.state.user.status === "A"}
                                value={this.state.user.status}
                              />{" "}
                              Active
                            </label>
                            &nbsp;&nbsp;&nbsp;
                            <label className="control-label">
                              <input
                                type="radio"
                                onChange={(e) =>
                                  this.setState({
                                    user: {
                                      ...this.state.user,
                                      status:
                                        e.target.value === "I" ? "A" : "I",
                                    },
                                  })
                                }
                                checked={this.state.user.status === "I"}
                                value={this.state.user.status}
                              />{" "}
                              Inactive
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label
                              htmlFor="status"
                              style={styles.inputLabelStyle}
                            >
                              Role
                            </label>
                          </div>
                          <div className="control-label col-md-8">
                            <label>
                              <input
                                type="checkbox"
                                id="reportAgent"
                                onChange={(e) =>
                                  this.setState({
                                    user: {
                                      ...this.state.user,
                                      reportagent:
                                        e.target.value === "Y" ? "N" : "Y",
                                    },
                                  })
                                }
                                value={this.state.user.reportagent}
                                checked={this.state.user.reportagent === "Y"}
                              />{" "}
                              Report Agent
                            </label>
                            &nbsp;&nbsp;&nbsp;
                            <label className="control-label">
                              <input
                                type="checkbox"
                                id="adminUser"
                                label=""
                                onChange={(e) =>
                                  this.setState({
                                    user: {
                                      ...this.state.user,
                                      adminuser:
                                        e.target.value === "Y" ? "N" : "Y",
                                    },
                                  })
                                }
                                value={this.state.user.adminuser}
                                checked={this.state.user.adminuser === "Y"}
                              />{" "}
                              Admin User
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group row">
                          <div className="control-label col-md-2">
                            <label
                              htmlFor="status"
                              style={styles.inputLabelStyle}
                            >
                              User Permissions
                            </label>
                          </div>
                          <div className="control-label col-md-8">
                            {logindetails.userdefault.vendor === "Y" ? (
                              <>
                                <label className="control-label">
                                  <input
                                    type="checkbox"
                                    id="fulfillment"
                                    label=""
                                    onChange={(e) =>
                                      this.setState({
                                        user: {
                                          ...this.state.user,
                                          fulfillmentallowed:
                                            e.target.value === "Y" ? "N" : "Y",
                                        },
                                      })
                                    }
                                    value={this.state.user.fulfillmentallowed}
                                    checked={
                                      this.state.user.fulfillmentallowed === "Y"
                                    }
                                  />{" "}
                                  Fulfillment
                                </label>
                                &nbsp;&nbsp;&nbsp;
                                <label className="control-label">
                                  <input
                                    type="checkbox"
                                    id="assignment"
                                    label=""
                                    onChange={(e) =>
                                      this.setState({
                                        user: {
                                          ...this.state.user,
                                          assignmentallowed:
                                            e.target.value === "Y" ? "N" : "Y",
                                        },
                                      })
                                    }
                                    value={this.state.user.assignmentallowed}
                                    checked={
                                      this.state.user.assignmentallowed === "Y"
                                    }
                                  />{" "}
                                  Assignment
                                </label>
                                {logindetails.userdefault.checkout === "Y" ? null : (
                                <>
                                &nbsp;&nbsp;&nbsp;
                                <label className="control-label">
                                  <input
                                    type="checkbox"
                                    id="checkInReturns"
                                    label=""
                                    onChange={(e) =>
                                      this.setState({
                                        user: {
                                          ...this.state.user,
                                          checkinallowed:
                                            e.target.value === "Y" ? "N" : "Y",
                                        },
                                      })
                                    }
                                    value={this.state.user.checkinallowed}
                                    checked={
                                      this.state.user.checkinallowed === "Y"
                                    }
                                  />{" "}
                                  Check In Returns
                                </label>
                                </>
                                )
                                }
                                &nbsp;&nbsp;&nbsp;
                                <label className="control-label">
                                  <input
                                    type="checkbox"
                                    id="supervisor"
                                    label=""
                                    onChange={(e) =>
                                      this.setState({
                                        user: {
                                          ...this.state.user,
                                          supervisor:
                                            e.target.value === "Y" ? "N" : "Y",
                                        },
                                      })
                                    }
                                    value={this.state.user.supervisor}
                                    checked={this.state.user.supervisor === "Y"}
                                  />{" "}
                                  Supervisor
                                </label>
                              </>
                            ) : null}

                            {logindetails.userdefault.loungeoperator === "Y" ? (
                              <>
                                <label className="control-label">
                                  {" "}
                                  <input
                                    onChange={(e) =>
                                      this.setState({
                                        user: {
                                          ...this.state.user,
                                          fulfillmentallowed:
                                            e.target.value === "Y" ? "N" : "Y",
                                        },
                                      })
                                    }
                                    type="checkbox"
                                    value={this.state.user.fulfillmentallowed}
                                    checked={
                                      this.state.user.fulfillmentallowed === "Y"
                                    }
                                  />
                                  &nbsp; Fulfillment
                                </label>
                                &nbsp;&nbsp;&nbsp;
                                <label className="control-label">
                                  {" "}
                                  <input
                                    onChange={(e) =>
                                      this.setState({
                                        user: {
                                          ...this.state.user,
                                          checkinallowed:
                                            e.target.value === "Y" ? "N" : "Y",
                                        },
                                      })
                                    }
                                    type="checkbox"
                                    value={this.state.user.checkinallowed}
                                    checked={
                                      this.state.user.checkinallowed === "Y"
                                    }
                                  />
                                  &nbsp;Check In
                                </label>
                                &nbsp;&nbsp;&nbsp;
                                <label className="control-label">
                                  <input
                                    onChange={(e) =>
                                      this.setState({
                                        user: {
                                          ...this.state.user,
                                          returnsallowed:
                                            e.target.value === "Y" ? "N" : "Y",
                                        },
                                      })
                                    }
                                    type="checkbox"
                                    value={this.state.user.returnsallowed}
                                    checked={
                                      this.state.user.returnsallowed === "Y"
                                    }
                                  />{" "}
                                  Returns
                                </label>
                                &nbsp;&nbsp;&nbsp;
                                <label className="control-label">
                                  {" "}
                                  <input
                                    onChange={(e) =>
                                      this.setState({
                                        user: {
                                          ...this.state.user,
                                          refundsallowed:
                                            e.target.value === "Y" ? "N" : "Y",
                                        },
                                      })
                                    }
                                    type="checkbox"
                                    value={this.state.user.refundsallowed}
                                    checked={
                                      this.state.user.refundsallowed === "Y"
                                    }
                                  />{" "}
                                  Refunds
                                </label>
                                &nbsp;&nbsp;&nbsp;
                                <label className="control-label">
                                  <input
                                    onChange={(e) =>
                                      this.setState({
                                        user: {
                                          ...this.state.user,
                                          supervisor:
                                            e.target.value === "Y" ? "N" : "Y",
                                        },
                                      })
                                    }
                                    type="checkbox"
                                    value={this.state.user.supervisor}
                                    checked={this.state.user.supervisor === "Y"}
                                  />
                                  &nbsp; Supervisor
                                </label>
                              </>
                            ) : null}

                            {logindetails.userdefault.checkout === "Y" ? (
                              <>
                                &nbsp;&nbsp;&nbsp;
                                <label className="control-label">
                                  {" "}
                                  <input
                                    onChange={(e) =>
                                      this.setState({
                                        user: {
                                          ...this.state.user,
                                          refundsallowed:
                                            e.target.value === "Y" ? "N" : "Y",
                                        },
                                      })
                                    }
                                    type="checkbox"
                                    value={this.state.user.refundsallowed}
                                    checked={
                                      this.state.user.refundsallowed === "Y"
                                    }
                                  />{" "}
                                  Refunds
                                </label>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      {/* </div> */}
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="col-md-12" style={{ padding: 0 }}>
                      <button
                        type="submit"
                        className="btn pull-right btn-sm submit-btn "
                        style={styles.confirmButtonStyle}
                      >
                        {this.state.edit ? "Update" : "Save"}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="spacer"> </div>
        </div>
        <Footer />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  logindetails: state.SignInReducer.logindetails,
  users: state.UserReducer.users,
  userServiceError: state.UserReducer.userServiceError,
  userServiceSuccess: state.UserReducer.userServiceSuccess,
});
export default connect(mapStateToProps, { editUserService })(AddUser);
