import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "../common/navbar";
import Loader from "../common/loader";
import DialogModal from "../common/dialogModal";
import {
  callUpdateVendorProductCommission,
} from "../../actions/vendor/vendorProductCommissionAction";
import { AIRPORTS } from "../../Config/CustomConfig";
import Footer from "../common/footer";
import { styles } from "../../styles/commontStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Col, Row } from "react-bootstrap";

class EditVendorProductCommission extends Component {
  constructor(props) {
    super(props);
  
    if(this.props.logindetails.userdefault.supervisor === 'N'){
      this.props.history.push('/');
    }
    
    this.state = {
      vendorproduct: {
        vendorid: "",
        vendorname: "",
        productid: "",
        productname: "",
        airportid: "",
        direction: "",
        status: "",
        usedefaultrate: "Y",
        defaultcommissionrate: 0,
        commissionrate: -1
      },
      // productErr: {
      //   productname: "",
      //   categoryid: "",
      //   unitcost: "",
      //   quantity: "",
      //   description: "",
      // },
    };
  }

  componentDidMount = () => {
    window.scrollTo(0,0);

    if (this.props.location.state) {       
      const vendorproduct = this.props.location.state.vendorproduct;

      if (vendorproduct) {
        this.setState({
          vendorproduct: vendorproduct,
        });
      }
    } else {
      this.props.history.push("/");
    }
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.updateProductError !== this.props.updateProductError &&
      nextProps.updateProductError !== null
    ) {
      this.setState({
        displayDialog: true,
        dialogTitle: "Oops!",
        dialogBody: nextProps.updateProductError,
        dialogFooter: (
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={this.closeDialog}
          >
            Close
          </button>
        ),
      });
    }

    if (
      nextProps.updateProductSuccess &&
      nextProps.updateProductSuccess !== this.props.updateProductSuccess
    ) {
      this.setState({
        displayDialog: true,
        dialogTitle: "Success!",
        dialogBody: `Product ${this.state.actiontype === "CREATE" ? "added" : "updated"
          } successfully!`,
        dialogFooter: (
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={() => {
              this.closeDialog();
              this.props.history.push("/products");
            }}
          >
            Close
          </button>
        ),
      });
    }
  }

  closeDialog = () => {
    this.setState({
      displayDialog: false,
    });
  };

  resetError = () => {
    let productErr = {
      productname: null,
      categoryid: "",
      unitcost: "",
      quantity: "",
    };
    this.setState({ productErr: productErr });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const history = this.props.history;
    // const productname = this.state.product.productname;
    // const categoryid = this.state.product.categoryid;
    // const unitcost = this.state.product.unitcost;
    // const quantity = this.state.product.quantity;
    // const description = this.state.product.description;

    const regex = /^\d+(\.\d{1,2})?$/;
    let productErr = {
      productname: "",
      categoryid: "",
      unitcost: "",
      quantity: "",
      description: "",
    };

    // if (!productname.trim()) {
    //   productErr.productname = "Use Default Rate is required.";
    // }
    // if (!categoryid) {
    //   productErr.categoryid = "Commission Rate is required.";
    // }
    
    // this.setState({ productErr: productErr });

    // if (
    //   productname.trim() &&
    //   categoryid &&
    //   regex.test(unitcost) &&
    //   regex.test(quantity) &&
    //   description.trim()
    // ) {
    if(true){
      const data = {
        productid:this.state.vendorproduct.productid,
        usedefaultrate:this.state.vendorproduct.usedefaultrate,
        commissionrate:this.state.vendorproduct.usedefaultrate === "Y" ? -1 : this.state.vendorproduct.commissionrate
      };

      this.props.callUpdateVendorProductCommission(data, history);
    }
  };
  
  render() {

    return (
      <>
        <NavBar />
        <Loader message="Processing" />
        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />

        <Container className={"back-bar-container"} fluid>
          <Row className={"categoryBar back-bar"}>
            <Col lg="12" md="12">
              <a
                className={"back-link"}
                onClick={() => this.props.history.push("/vendorproductcommission", {vendorid:this.props.location.state.vendorid})}
              >
                <FontAwesomeIcon
                  icon="chevron-left"
                  color="#777"
                  className={"back-icon "}
                />
                Back
              </a>
            </Col>
          </Row>
        </Container>

        <div className="container-fluid stores-container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <form onSubmit={this.handleSubmit}>
                <div className="card">
                  <div className="card-header custom-card-header">
                    <h4>Edit Product Commission</h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label
                              style={styles.inputLabelStyle}
                              className="required"
                            >
                              Product Name
                            </label>
                          </div>
                          <div className="col-md-8">
                          <input
                              id="productname"
                              disabled                               
                              type="text"
                              value={this.state.vendorproduct.productname}
                              className="form-control"
                              placeholder=""
                            />                      
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label
                              style={styles.inputLabelStyle}
                              className="required"
                            >
                              Vendor Name
                            </label>
                          </div>
                          <div className="col-md-8">
                          <input
                              id="vendorname"
                              disabled                               
                              type="text"
                              value={this.state.vendorproduct.vendorname}
                              className="form-control"
                              placeholder=""
                            />                      
                          </div>
                        </div>
                      </div>
                      
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label
                              style={styles.inputLabelStyle}
                              className="required"
                            >
                              Airport
                            </label>
                          </div>
                          <div className="col-md-8">
                          <input
                              id="airport"
                              disabled                               
                              type="text"
                              value={this.state.vendorproduct.airportid === "NMIA" ? AIRPORTS.NMIA : AIRPORTS.SIA}
                              className="form-control"
                              placeholder=""
                            />                      
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label
                              style={styles.inputLabelStyle}
                              className="required"
                            >
                              Direction
                            </label>
                          </div>
                          <div className="col-md-8">
                          <input
                              id="direction"
                              disabled                               
                              type="text"
                              value={this.state.vendorproduct.direction === "A" ? "Arrival":"Departure"}
                              className="form-control"
                              placeholder=""
                            />                      
                          </div>
                        </div>
                      </div>                      
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label
                              style={styles.inputLabelStyle}
                              className="required"
                            >
                              Status
                            </label>
                          </div>
                          <div className="col-md-8">
                          <input
                              id="status"
                              disabled                               
                              type="text"
                              value={this.state.vendorproduct.status === "A" ? "Active" : "Inactive"}
                              className="form-control"
                              placeholder=""
                            />                      
                          </div>
                        </div>
                      </div>                   
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="from-group row">
                          <div className="control-label col-md-4">
                            <label
                              htmlFor="usedefaultrate"
                              style={styles.inputLabelStyle}
                              className={"required"}
                            >
                              Use Default Rate ({this.state.vendorproduct.defaultcommissionrate})
                            </label>
                          </div>
                          <div className="col-md-8">
                            <label className="control-label">
                              <input
                                type="radio"
                                onChange={(e) =>
                                  this.setState({
                                    vendorproduct: {
                                      ...this.state.vendorproduct,
                                      usedefaultrate: "Y",
                                    },
                                  })
                                }
                                value="Y"
                                checked={this.state.vendorproduct.usedefaultrate === "Y"}
                              />{" "}
                              Yes
                            </label>
                            &nbsp;&nbsp;&nbsp;
                            <label className="control-label">
                              <input
                                type="radio"
                                onChange={(e) =>
                                  this.setState({
                                    vendorproduct: {
                                      ...this.state.vendorproduct,
                                      usedefaultrate: "N",
                                    },
                                  })
                                }
                                value="N"
                                checked={this.state.vendorproduct.usedefaultrate === "N"}
                              />{" "}
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    
                      <div className="col-md-6">
                        <div className="from-group row">
                          <div className="control-label col-md-4">
                            <label
                              htmlFor="commissionrate"
                              style={styles.inputLabelStyle}
                              className={"required"}
                            >
                              Commission Rate
                            </label>
                          </div>
                          <div className="col-md-8">                             
                            <input
                              type="number"
                              min="0.01"
                              max="1"
                              step="0.01"
                              id="commissionrate"
                              onChange={(e) =>
                                this.setState({
                                  vendorproduct: {
                                    ...this.state.vendorproduct,
                                    commissionrate: e.target.value,
                                  },
                                })
                              }
                              value={this.state.vendorproduct.usedefaultrate === "Y" ? "" : (this.state.vendorproduct.commissionrate == -1 ? "" : this.state.vendorproduct.commissionrate)}
                              className="form-control"
                              placeholder="Enter Commission Rate"
                              disabled={this.state.vendorproduct.usedefaultrate === "Y"}
                            />                          
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="col-md-12" style={{ padding: 0 }}>
                      <button
                        type="submit"
                        className="btn pull-right submit-btn"
                        style={styles.confirmButtonStyle}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="spacer"></div>
        </div>
        <Footer />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  logindetails: state.SignInReducer.logindetails,
  updateVendorProductError: state.VendorProductReducer.updateProductError,
  updateVendorProductSuccess: state.VendorProductReducer.updateProductSuccess,
});
export default connect(mapStateToProps, {
  callUpdateVendorProductCommission
})(EditVendorProductCommission);
