import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "../common/navbar";
import Loader from "../common/loader";
import DialogModal from "../common/dialogModal";
import {
  callUpdateVendor,
  getVendorMasterService,
} from "../../actions/vendor/vendorAction";
import Footer from "../common/footer";
import { styles } from "../../styles/commontStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AIRPORTS } from "../../Config/CustomConfig";
import { Container, Col, Row } from "react-bootstrap";

class AddVendor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vendor: {
        vendorid: "",
        vendorname: "",
        description: "",
        airportid: "SIA",
        direction: "A",
        contactname: "",
        contactemail: "",
        contactphone: "",
        loungeoperator: "N",
        express: "N",
        checkout: "N",
        status: "A",
        taxrate: "0",
        vendorreferenceid: "",
      },
      vendorError: {
        vendorid: "",
        vendorname: "",
        description: "",
        airportid: "",
        direction: "",
        contactname: "",
        contactemail: "",
        contactphone: "",
        loungeoperator: "",
        express: "",
        checkout: "",
        status: "",
        taxrate: "",
        vendorreferenceid: "",
      },
      actiontype: "CREATE",
      displayDialog: false,
      dialogTitle: null,
      dialogBody: null,
      dialogFooter: null,
      validate: true,
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.props.getVendorMasterService();
    
    if (this.props.location.state) {
      const vendor = this.props.location.state.vendor;
      if (vendor) {
        this.setState({
          vendor: vendor,
          actiontype: "UPDATE",
        });
      }
    } else if (this.props.location.pathname === "/vendors/edit") {
      this.props.history.push("/");
    }

    if(!this.props?.location?.state?.vendor) {
      const loginDetail = JSON.parse(localStorage.getItem("loginDetail"));

      const applicableRates = loginDetail?.rates.find(    
        (item) => item?.defaultrate === "Y"
      );

      this.setState({
        vendor: {
          ...this.state.vendor,
          usedefaultrate:applicableRates?.defaultrate,
          defaultcommissionrate:applicableRates?.commissionrate,
        }
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.vendorerror !== this.props.vendorerror) {
      if (this.props.vendorerror === true) {
        this.setState({
          displayDialog: true,
          dialogTitle: "Oops!",
          dialogBody: this.props.vendorerrormessage,
          dialogFooter: (
            <button
              className="btn"
              style={styles.dialogCancelButtonStyle}
              onClick={this.closeDialog}
            >
              Close
            </button>
          ),
        });
      }
    }
  }

  closeDialog = () => {
    this.setState({
      displayDialog: false,
    });
  };

  ValidateEmail = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }

    return false;
  };

  onParentMasterChange = (event) => {
    if (event.target.value) {
      const result = this.props.vendorslist.find(
        (vendor) => vendor.vendorid === event.target.value
      );

      this.setState({
        vendor: {
          ...this.state.vendor,
          vendorreferenceid: event.target.value,
          description: result.description,
          usedefaultrate:result?.usedefaultrate,
          defaultcommissionrate:(result?.commissionrate < 0 ? result?.defaultcommissionrate : result?.commissionrate),
        },
      });
    } else {
      this.setState({
        vendor: {
          ...this.state.vendor,
          vendorreferenceid: "",
          description: ""
        },
      });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();

    let vendorError = {
      vendorid: "",
      vendorname: "",
      description: "",
      airportid: "",
      direction: "",
      contactname: "",
      contactemail: "",
      contactphone: "",
      loungeoperator: "",
      express: "",
      checkout: "",
      status: "",
      taxrate: "",
      vendorreferenceid: "",
    };

    let validate = this.state.validate;
    if (this.state.vendor.vendorid !== "UPDATE") {
      if (!this.state.vendor.vendorid.trim()) {
        vendorError.vendorid = "Vendor Id Is required.";
        validate = false;
      }
    }

    if (!this.state.vendor.vendorreferenceid.trim()) {
      vendorError.vendorreferenceid = "Parent Vendor Is required.";
      validate = false;
    }

    if (!this.state.vendor.description.trim()) {
      vendorError.description = "Description Is required.";
      validate = false;
    }

    if (this.state.vendor.contactemail.trim() !== "") {
      if (!this.ValidateEmail(this.state.vendor.contactemail)) {
        vendorError.contactemail = "Please enter a valid email address.";
        // return false;'
        validate = false;
      }
    }

    this.setState({ vendorError: vendorError });

    if (validate) {
      const actiontype = this.state.actiontype;

      if (actiontype === "UPDATE") {
        this.props.callUpdateVendor(
          {
            actiontype: "UPDATE",
            vendorid: this.state.vendor.vendorid,
            // vendorname: this.state.vendor.vendorname,
            description: this.state.vendor.description,
            airportid: this.state.vendor.airportid,
            direction: this.state.vendor.direction,
            contactname: this.state.vendor.contactname,
            contactemail: this.state.vendor.contactemail,
            contactphone: this.state.vendor.contactphone,
            loungeoperator: this.state.vendor.loungeoperator,
            express: this.state.vendor.express,
            checkout: this.state.vendor.checkout,
            status: this.state.vendor.status,
            taxrate: this.state.vendor.taxrate,
            vendorreferenceid: this.state.vendor.vendorreferenceid,
            usedefaultrate:this.state.vendor.usedefaultrate,
            commissionrate:this.state.vendor.usedefaultrate === "Y" ? -1 : this.state.vendor.commissionrate
          },
          this.props.history
        );
      } else {
        this.props.callUpdateVendor(
          {
            actiontype: "CREATE",
            vendorid: this.state.vendor.vendorid,
            // vendorname: this.state.vendor.vendorname,
            description: this.state.vendor.description,
            airportid: this.state.vendor.airportid,
            direction: this.state.vendor.direction,
            contactname: this.state.vendor.contactname,
            contactemail: this.state.vendor.contactemail,
            contactphone: this.state.vendor.contactphone,
            loungeoperator: this.state.vendor.loungeoperator,
            express: this.state.vendor.express,
            checkout: this.state.vendor.checkout,
            status: this.state.vendor.status,
            taxrate: this.state.vendor.taxrate,
            vendorreferenceid: this.state.vendor.vendorreferenceid,
            usedefaultrate:this.state.vendor.usedefaultrate,
            commissionrate:this.state.vendor.usedefaultrate === "Y" ? -1 : this.state.vendor.commissionrate
          },
          this.props.history
        );
      }
    }
  };

  render() {
    return (
      <>
        <NavBar />
        <Loader message="Processing" />
        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />

        <Container className={"back-bar-container"} fluid>
          <Row className={"categoryBar back-bar"}>
            <Col lg="12" md="12">
              <a
                className={"back-link"}
                onClick={() => this.props.history.push("/vendors")}
              >
                <FontAwesomeIcon
                  icon="chevron-left"
                  color="#777"
                  className={"back-icon "}
                />
                Back
              </a>
            </Col>
          </Row>
        </Container>

        <div className="container-fluid stores-container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <form onSubmit={this.handleSubmit}>
                <div className="card">
                  <div className={"card-header custom-card-header"}>
                    <h4>
                      {" "}
                      {this.state.actiontype === "CREATE"
                        ? "Add Vendor"
                        : "Edit Vendor"}
                    </h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label
                              htmlFor="vendorid"
                              style={styles.inputLabelStyle}
                              className="required"
                            >
                              Vendor Id
                            </label>
                          </div>
                          <div className="col-md-8">
                            <input
                              placeholder={"Enter Vendor Id"}
                              type="text"
                              id="vendorid"
                              onChange={(e) =>
                                this.setState({
                                  vendor: {
                                    ...this.state.vendor,
                                    vendorid:e.target.value.toUpperCase().replace(
                                      /[^A-Z0-9]/g,
                                      ""
                                    ),
                                  },
                                })
                              }
                              autoFocus={true}
                              value={this.state.vendor.vendorid}
                              className="form-control"
                              disabled={this.state.actiontype === "UPDATE"}
                            />{" "}
                            {this.state.vendorError.vendorid && (
                              <small className="text-danger">
                                {this.state.vendorError.vendorid}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label
                              htmlFor="parentvendor"
                              style={styles.inputLabelStyle}
                              className="required"
                            >
                              Parent Vendor
                            </label>
                          </div>
                          <div className="col-md-8">
                            <select
                              id="parentvendor"
                              className="form-control"
                              onChange={this.onParentMasterChange}
                              value={this.state.vendor.vendorreferenceid}
                            >
                              <option value="">--Select Parent Vendor--</option>
                              {this.props.vendorslist.map((vendor) => (
                                <option
                                  key={vendor.vendorid}
                                  value={vendor.vendorid}
                                >
                                  {vendor.vendorname}
                                </option>
                              ))}
                            </select>

                            {this.state.vendorError.vendorreferenceid && (
                              <small className="text-danger">
                                {this.state.vendorError.vendorreferenceid}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label
                              htmlFor="contactname"
                              style={styles.inputLabelStyle}
                              // className="required"
                            >
                              Contact Name
                            </label>
                          </div>
                          <div className="col-md-8">
                            <input
                              placeholder={"Enter Contact Name"}
                              id="contactname"
                              type="text"
                              onChange={(e) =>
                                this.setState({
                                  vendor: {
                                    ...this.state.vendor,
                                    contactname: e.target.value,
                                  },
                                })
                              }
                              value={this.state.vendor.contactname}
                              className="form-control"
                            />{" "}
                            {this.state.vendorError.contactname && (
                              <small className="text-danger">
                                {this.state.vendorError.contactname}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label
                              htmlFor="contactemail"
                              style={styles.inputLabelStyle}
                              // className="required"
                            >
                              Contact Email
                            </label>
                          </div>
                          <div className="col-md-8">
                            <input
                              placeholder={"Enter Contact Email"}
                              id="contactemail"
                              type="text"
                              onChange={(e) =>
                                this.setState({
                                  vendor: {
                                    ...this.state.vendor,
                                    contactemail: e.target.value,
                                  },
                                })
                              }
                              value={this.state.vendor.contactemail}
                              className="form-control"
                            />{" "}
                            {this.state.vendorError.contactemail && (
                              <small className="text-danger">
                                {this.state.vendorError.contactemail}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label
                              htmlFor="contactphone"
                              style={styles.inputLabelStyle}
                              // className="required"
                            >
                              Contact Phone
                            </label>
                          </div>
                          <div className="col-md-8">
                            <input
                              placeholder={"Enter Contact Phone"}
                              id="contactphone"
                              type="text"
                              onChange={(e) =>
                                this.setState({
                                  vendor: {
                                    ...this.state.vendor,
                                    contactphone: e.target.value.replace(
                                      /[^0-9]/g,
                                      ""
                                    ),
                                  },
                                })
                              }
                              maxLength={10}
                              value={this.state.vendor.contactphone}
                              className="form-control"
                            />{" "}
                            {this.state.vendorError.contactphone && (
                              <small className="text-danger">
                                {this.state.vendorError.contactphone}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label
                              htmlFor="taxrate"
                              style={styles.inputLabelStyle}
                              // className="required"
                            >
                              Tax Rate
                            </label>
                          </div>
                          <div className="col-md-8">
                            <input
                              placeholder={"Enter Tax Rate"}
                              id="taxrate"
                              type="text"
                              min="0"
                              max="1"
                              onChange={(e) => {
                                e.target.value >= 0 && e.target.value <= 1
                                  ? this.setState({
                                      vendor: {
                                        ...this.state.vendor,
                                        taxrate: e.target.value,
                                      },
                                    })
                                  : this.setState({
                                      vendor: {
                                        ...this.state.vendor,
                                        taxrate: this.state.vendor.taxrate,
                                      },
                                    });
                              }}
                              value={this.state.vendor.taxrate}
                              className="form-control"
                            />{" "}
                            {this.state.vendorError.taxrate && (
                              <small className="text-danger">
                                {this.state.vendorError.taxrate}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label
                              style={styles.inputLabelStyle}
                              // className="required"
                            >
                              Role
                            </label>
                          </div>
                          <div className="col-md-8">
                            <label className={"control-label"}>
                              <input
                                placeholder={"Role"}
                                type="checkbox"
                                onChange={(e) =>
                                  this.setState({
                                    vendor: {
                                      ...this.state.vendor,
                                      loungeoperator:
                                        e.target.value === "Y" ? "N" : "Y",
                                      express: "Y" ? "N" : "Y",
                                      checkout: "Y" ? "N" : "Y",
                                    },
                                  })
                                }
                                value={this.state.vendor.loungeoperator}
                                checked={
                                  this.state.vendor.loungeoperator === "Y"
                                }
                              />
                              &nbsp;Lounge Operator
                            </label>
                            &nbsp;&nbsp;&nbsp;
                            <label className={"control-label"}>
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  this.setState({
                                    vendor: {
                                      ...this.state.vendor,
                                      express:
                                        e.target.value === "Y" ? "N" : "Y",
                                      checkout: "Y" ? "N" : "Y",
                                    },
                                  })
                                }
                                value={this.state.vendor.express}
                                checked={this.state.vendor.express === "Y"}
                                disabled={
                                  this.state.vendor.loungeoperator === "Y"
                                }
                              />
                              &nbsp;Express
                            </label>
                            &nbsp;&nbsp;&nbsp;
                            <label className={"control-label"}>
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  this.setState({
                                    vendor: {
                                      ...this.state.vendor,
                                      checkout: e.target.value === "Y" ? "N" : "Y",
                                    },
                                  })
                                }
                                value={this.state.vendor.checkout}
                                checked={this.state.vendor.checkout === "Y"}
                                disabled={
                                  this.state.vendor.loungeoperator === "Y" || this.state.vendor.express !== "Y"
                                }
                              />
                              &nbsp;Checkout
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label
                              style={styles.inputLabelStyle}
                              className="required"
                            >
                              Status
                            </label>
                          </div>
                          <div className="col-md-8">
                            <label className={"control-label"}>
                              <input
                                type="radio"
                                onChange={(e) =>
                                  this.setState({
                                    vendor: {
                                      ...this.state.vendor,
                                      status:
                                        e.target.value === "A" ? "I" : "A",
                                    },
                                  })
                                }
                                value={this.state.vendor.status}
                                checked={this.state.vendor.status === "A"}
                              />
                              &nbsp;Active
                            </label>
                            &nbsp;&nbsp;&nbsp;
                            <label className={"control-label"}>
                              <input
                                type="radio"
                                onChange={(e) =>
                                  this.setState({
                                    vendor: {
                                      ...this.state.vendor,
                                      status:
                                        e.target.value === "I" ? "A" : "I",
                                    },
                                  })
                                }
                                value={this.state.vendor.status}
                                checked={this.state.vendor.status === "I"}
                              />
                              &nbsp;Inactive
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label
                              style={styles.inputLabelStyle}
                              className="required"
                            >
                              Airport
                            </label>
                          </div>
                          <div className="col-md-8">
                            <label className={"control-label"}>
                              <input
                                type="radio"
                                onChange={(e) =>
                                  this.setState({
                                    vendor: {
                                      ...this.state.vendor,
                                      airportid:
                                        e.target.value === "NMIA"
                                          ? "SIA"
                                          : "NMIA",
                                    },
                                  })
                                }
                                value={this.state.vendor.airportid}
                                checked={this.state.vendor.airportid === "SIA"}
                              />
                              &nbsp;{AIRPORTS.SIA}
                            </label>
                            &nbsp;&nbsp;&nbsp;
                            <label className={"control-label"}>
                              <input
                                type="radio"
                                onChange={(e) =>
                                  this.setState({
                                    vendor: {
                                      ...this.state.vendor,
                                      airportid:
                                        e.target.value === "SIA"
                                          ? "NMIA"
                                          : "SIA",
                                    },
                                  })
                                }
                                value={this.state.vendor.airportid}
                                checked={this.state.vendor.airportid === "NMIA"}
                              />
                              &nbsp;{AIRPORTS.NMIA}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label
                              style={styles.inputLabelStyle}
                              className="required"
                            >
                              Direction
                            </label>
                          </div>
                          <div className="col-md-8">
                            <label className={"control-label"}>
                              <input
                                type="radio"
                                onChange={(e) =>
                                  this.setState({
                                    vendor: {
                                      ...this.state.vendor,
                                      direction:
                                        e.target.value === "D" ? "A" : "D",
                                    },
                                  })
                                }
                                value={this.state.vendor.direction}
                                checked={this.state.vendor.direction === "A"}
                              />
                              &nbsp;Arrival
                            </label>
                            &nbsp;&nbsp;&nbsp;
                            <label className={"control-label"}>
                              <input
                                type="radio"
                                onChange={(e) =>
                                  this.setState({
                                    vendor: {
                                      ...this.state.vendor,
                                      direction:
                                        e.target.value === "A" ? "D" : "A",
                                    },
                                  })
                                }
                                value={this.state.vendor.direction}
                                checked={this.state.vendor.direction === "D"}
                              />
                              &nbsp;Departure
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group row">
                          <div className="control-label col-md-2">
                            <label
                              htmlFor="description"
                              style={styles.inputLabelStyle}
                              className="required"
                            >
                              Description
                            </label>
                          </div>
                          <div className="col-md-10">
                            <textarea
                              placeholder={"Enter Description"}
                              id="description"
                              onChange={(e) =>
                                this.setState({
                                  vendor: {
                                    ...this.state.vendor,
                                    description: e.target.value,
                                  },
                                })
                              }
                              value={this.state.vendor.description}
                              className="form-control"
                            ></textarea>
                            {this.state.vendorError.description && (
                              <small className="text-danger">
                                {this.state.vendorError.description}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                              
                    <div className="row">
                      <div className="col-md-6">
                        <div className="from-group row">
                          <div className="control-label col-md-4">
                            <label
                              htmlFor="usedefaultrate"
                              style={styles.inputLabelStyle}
                              className={"required"}
                            >
                              Use Default Rate ({this.state.vendor.defaultcommissionrate})
                            </label>
                          </div>
                          <div className="col-md-8">
                            <label className="control-label">
                              <input
                                type="radio"
                                onChange={(e) =>
                                  this.setState({
                                    vendor: {
                                      ...this.state.vendor,
                                      usedefaultrate: "Y",
                                    },
                                  })
                                }
                                value="Y"
                                checked={this.state.vendor.usedefaultrate === "Y"}
                              />{" "}
                              Yes
                            </label>
                            &nbsp;&nbsp;&nbsp;
                            <label className="control-label">
                              <input
                                type="radio"
                                onChange={(e) =>
                                  this.setState({
                                    vendor: {
                                      ...this.state.vendor,
                                      usedefaultrate: "N",
                                    },
                                  })
                                }
                                value="N"
                                checked={this.state.vendor.usedefaultrate === "N"}
                              />{" "}
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    
                      <div className="col-md-6">
                        <div className="from-group row">
                          <div className="control-label col-md-4">
                            <label
                              htmlFor="commissionrate"
                              style={styles.inputLabelStyle}
                              className={"required"}
                            >
                              Commission Rate
                            </label>
                          </div>
                          <div className="col-md-8">                             
                            <input
                              type="number"
                              min="0"
                              max="1"
                              step="0.01"
                              id="commissionrate"
                              onChange={(e) =>
                                this.setState({
                                  vendor: {
                                    ...this.state.vendor,
                                    commissionrate: e.target.value,
                                  },
                                })
                              }
                              value={this.state.vendor.usedefaultrate === "Y" ? "" : (this.state.vendor.commissionrate == -1 ? "" : this.state.vendor.commissionrate)}
                              className="form-control"
                              placeholder="Enter Commission Rate"
                              disabled={this.state.vendor.usedefaultrate === "Y"}
                            />                          
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-md-12"></div>
                     
                  </div>
                  <div className="card-footer">
                    <div className={"col-md-12"} style={{ padding: 0 }}>
                      <button
                        type="submit"
                        className="btn pull-right submit-btn"
                        style={styles.confirmButtonStyle}
                      >
                        {this.state.actiontype === "CREATE" ? "Save" : "Update"}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="spacer"></div>
        </div>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  logindetails: state.SignInReducer.logindetails,
  vendorslist: state.VendorReducer.vendorsmasterlist,
  vendorerror: state.VendorReducer.vendorerror,
  vendorerrormessage: state.VendorReducer.vendorerrormessage,
});
export default connect(mapStateToProps, {
  callUpdateVendor,
  getVendorMasterService,
})(AddVendor);
