import React, {Component} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "../common/navbar";
import Footer from "../common/footer";
import {
    Container,
} from "react-bootstrap";
import axios from 'axios';
import {APPLICATION_CONST} from '../../Config/CustomConfig';
import {connect} from "react-redux";
import {loaderStatus} from "../../actions/vendor/loaderAction";
import Loader from "../common/loader";
import {styles} from "../../styles/commontStyles";
import DialogModal from "./dialogModal";
class BannerPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            response: null,
        };
        this.loadhtml();
    }

    loadhtml = () => {
        const url = this.props.location.state.url;
        console.log('request',JSON.stringify({
        "username": this.props.logindetails.userdefault.username,
        "sessionid": this.props.logindetails.sessionid,
        "request": {
            "document": url
        }
    }));
        this.props.loaderStatus(true);
        axios({
            url: APPLICATION_CONST.url + 'getdocument',
            method: 'POST',
            data: {
                "username": this.props.logindetails.userdefault.username,
                "sessionid": this.props.logindetails.sessionid,
                "request": {
                    "document": url
                }
            }
        }).then(response => {
             if(response.data.status==0)
            {
                this.setState({'response': response.data});
                this.props.loaderStatus(false);
            }
            else if(response.data.status>0)
            {
                this.props.loaderStatus(false);
                this.setState({
                    displayDialog: true,
                    dialogTitle: "Oops!",
                    dialogBody: response.data.statusMessage,
                    dialogFooter: (
                        <button
                            className="btn"
                            style={styles.dialogCancelButtonStyle}
                            onClick={() => {
                                this.setState({ displayDialog: false });
                            }}
                        >
                            OK
                        </button>
                    ),
                });
                this.props.loaderStatus(false);

            }
            else{
                this.setState({'response': response.data});
                this.props.loaderStatus(false); 
            }
        }).catch(error => {
            console.log(error.message);
            this.props.loaderStatus(false);
        });
    }

    render() {
        return (
            <div className="wrapper">
                <NavBar/>
                <DialogModal
                    visible={this.state.displayDialog}
                    title={this.state.dialogTitle}
                    body={this.state.dialogBody}
                    footer={this.state.dialogFooter}
                />
                <Loader message="Processing..."/>
                <Container style={{padding: 25}}>

                    <div dangerouslySetInnerHTML={{__html: this.state.response, sanitize: true}}/>
                </Container>
                <Footer/>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    logindetails: state.SignInReducer.logindetails,
});
export default connect(mapStateToProps, {loaderStatus})(BannerPage);
