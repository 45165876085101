import {ORDER_SERVICE_ERROR, ORDER_SERVICE_PENDING, ORDER_SERVICE_SUCCESS} from '../../actions/public/actionTypes';

const initialState = {
    orderStatus: null,
    orderError: null,
    orders:[]
};

 const OrderReducer = (state = initialState,action) => {
    switch (action.type){
        case ORDER_SERVICE_SUCCESS:
            return {
                ...state,
                orders:action.payload,
               orderStatus: 'success'
            }
        case ORDER_SERVICE_PENDING:
            return {
                ...state,
                orderStatus: 'pending'
            }
        case ORDER_SERVICE_ERROR:
            return {
                ...state,
                orderStatus: 'failed',
                orderError : action.payload
            }
        default:
            return state;
    }
}

export default OrderReducer;