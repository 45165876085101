import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/font-awesome/css/font-awesome.min.css";
import NavBar from "../common/navbar";
import Footer from "../common/footer";
import { styles } from "../../styles/commontStyles";
import { Image } from "react-bootstrap";
import { APPLICATION_CONST } from "../../Config/CustomConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Col, Row } from "react-bootstrap";

class Admin extends Component {
  getOrders = (actiontype) => {

   
    if (actiontype === "ASSIGNEDRETURNS") {
      this.props.history.push("/orders", {
        actiontype: actiontype,
        subaction: "cancelrefund",
      });
    } else if (actiontype === "CANCELFULFILLED") {
      this.props.history.push("/orders", {
        actiontype: actiontype,
        subaction: "cancelfulfillment",
      });
    }
     else {
      this.props.history.push("/orders", {
        actiontype: actiontype,
      });
    }
  };
  render() {
    let userdetails = this.props.logindetails;

    return (
      <>
        <NavBar />

        <Container className={"back-bar-container"} fluid>
          <Row className={"categoryBar back-bar"}>
            <Col lg="12" md="12">
              <a
                className={"back-link"}
                onClick={() => this.props.history.push("/")}
              >
                <FontAwesomeIcon
                  icon="chevron-left"
                  color="#777"
                  className={"back-icon "}
                />
                Back
              </a>
              <ul>
                <li>
                  Admin
                </li>
              </ul>
            </Col>
          </Row>
        </Container>

        <div
          className="container-fluid stores-container"
        
        >
          <div className="row">
            {(userdetails.userdefault.refundsallowed === "Y" || userdetails.userdefault.checkout === "Y") ? (
              <div className="col-md-3">
                <div style={styles.vendorCardStyle}>
                  <Image
                    style={{
                      height: 100,
                      width: "auto",
                      margin: 3,
                      alignSelf: "center",
                    }}
                    resizemode="contain"
                    src={
                      APPLICATION_CONST.vendorButtonImageUrl + "refundguest.png"
                    }
                  />
                  <div className="card-body">
                    <button
                      onClick={() => this.getOrders("REFUNDABLEORDERS")}
                      className="btn btn-block"
                      style={styles.vendorCardButtonStyle}
                    >
                      Refund Guest
                    </button>
                  </div>
                </div>
              </div>
            ) : null}

            <div className="col-md-3">
              <div style={styles.vendorCardStyle}>
                <Image
                  style={{
                    height: 100,
                    width: "auto",
                    margin: 3,
                    alignSelf: "center",
                  }}
                  resizemode="contain"
                  src={
                    APPLICATION_CONST.vendorButtonImageUrl +
                    "reprintreceipt.png"
                  }
                />
                <div className="card-body">
                  <button
                    onClick={() => this.getOrders("REPRINTABLERECEIPTS")}
                    className="btn btn-block"
                    style={styles.vendorCardButtonStyle}
                  >
                    Reprint Receipt / Delivery Slip
                  </button>
                </div>
              </div>
            </div>
            {userdetails.userdefault.returnsallowed === "Y" ? (
              <div className="col-md-3">
                <div style={styles.vendorCardStyle}>
                  <Image
                    style={{
                      height: 100,
                      width: "auto",
                      margin: 3,
                      alignSelf: "center",
                    }}
                    resizemode="contain"
                    src={
                      APPLICATION_CONST.vendorButtonImageUrl +
                      "cancelreturn.png"
                    }
                  />
                  <div className="card-body">
                    <button
                      onClick={() => this.getOrders("CANCELRETURNS")}
                      className="btn btn-block"
                      style={styles.vendorCardButtonStyle}
                    >
                      Cancel Return
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
            {userdetails.userdefault.fulfillmentallowed === "Y" ? (
              <div className="col-md-3">
                <div style={styles.vendorCardStyle}>
                  <Image
                    style={{
                      height: 100,
                      width: "auto",
                      margin: 3,
                      alignSelf: "center",
                    }}
                    resizemode="contain"
                    src={
                      APPLICATION_CONST.vendorButtonImageUrl +
                      "cancelfulfillment.png"
                    }
                  />
                  <div className="card-body">
                    <button
                      onClick={() => this.getOrders("CANCELFULFILLED")}
                      className="btn btn-block"
                      style={styles.vendorCardButtonStyle}
                    >
                      Cancel Fulfillment
                    </button>
                  </div>
                </div>
              </div>
            ) : null}

            {userdetails.userdefault.checkinallowed === "Y" ? (
              <div className="col-md-3">
                <div style={styles.vendorCardStyle}>
                  <Image
                    style={{
                      height: 100,
                      width: "auto",
                      margin: 3,
                      alignSelf: "center",
                    }}
                    resizemode="contain"
                    src={
                      APPLICATION_CONST.vendorButtonImageUrl +
                      "cancelfulfillment.png"
                    }
                  />
                  <div className="card-body">
                    <button
                      onClick={() => this.getOrders("CANCELCHECKIN")}
                      className="btn btn-block"
                      style={styles.vendorCardButtonStyle}
                    >
                      Cancel Check In
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className="spacer"></div>
        </div>
        <Footer />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  logindetails: state.SignInReducer.logindetails,
});
export default connect(mapStateToProps, {})(Admin);
