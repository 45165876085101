import React, {Component} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "../common/navbar";
import Footer from "../common/footer";
import {
    Container,
} from "react-bootstrap";

import {APPLICATION_CONST} from "../../Config/CustomConfig";
import axios from "axios";
import {connect} from "react-redux";
import {loaderStatus} from "../../actions/vendor/loaderAction";
import Loader from "../common/loader";

class Faqs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            response: null,
        };
        this.loadhtml();
    }

    loadhtml = () => {

        this.props.loaderStatus(true);
        axios({
            url: APPLICATION_CONST.url + 'getdocument',
            method: 'POST',
            data: {
                "username": this.props.logindetails.userdefault.username,
                "sessionid": this.props.logindetails.sessionid,
                "request": {
                    "document": "faqs"
                }
            }
        }).then(response => {
            this.setState({'response': response.data});
            this.handleClick();
            this.props.loaderStatus(false);
        }).catch(error => {
            console.log(error.message);
            this.props.loaderStatus(false);
        });
    }

    renderAccordian = () => {
        var acc = document.getElementsByClassName("accordion");
        var i;

        for (i = 0; i < acc.length; i++) {
            acc[i].addEventListener("click", function () {



                this.classList.toggle("active");
                var panel = this.nextElementSibling;
                if (panel.style.maxHeight) {
                    panel.style.maxHeight = null;
                } else {
                    panel.style.maxHeight = panel.scrollHeight + "px";
                }
            });
        }
    }
    handleClick = (e) => {
        this.inputElement.click();
      }

    render() {
        // this.renderAccordian();
        return (

            <div className="wrapper">
                <NavBar/>
                <Container style={{padding: 25}} >
                    <button style={{display:"none"}} ref={input => this.inputElement = input} onClick={() => this.renderAccordian()}>Hello</button>
                <Loader message="Processing..."/>
             
                    <div dangerouslySetInnerHTML={{__html: this.state.response, sanitize: true}}/>
                </Container>
                <Footer/>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    logindetails: state.SignInReducer.logindetails,
});
export default connect(mapStateToProps, {loaderStatus})(Faqs);
