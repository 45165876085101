import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/font-awesome/css/font-awesome.min.css";
import NavBar from "../common/navbar";
import Footer from "../common/footer";
import { styles } from "../../styles/commontStyles";

import { callStoresWebService } from "../../actions/public/storesAction";
import DialogModal from "../common/dialogModal";
import Loader from "../common/loader";
import DashboardButton from "./DashboardButton";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayDialog: false,
      dialogTitle: null,
      dialogBody: null,
      dialogFooter: null,
      loaderMessage: null,
      dashboardButtons: [],
    };

  }

  getOrders = (actiontype) => {
    this.props.history.push("/orders", { actiontype: actiontype });
  };

  componentWillReceiveProps(nextProps) {

    // walkin page redirect after store success
    if (
      nextProps.storeStatus !== this.props.storeStatus &&
      nextProps.storeStatus !== null
    ) {
      if (nextProps.storeStatus === "success") {
        this.props.history.push("/categories");
      }
    }
    // walkin page redirect after store success

    //Fetch Store Error Modal Show
    if (
      nextProps.storeError !== this.props.storeError &&
      nextProps.storeError !== null
    ) {
      this.setState({
        displayDialog: true,
        dialogTitle: "Oops!",
        dialogBody: nextProps.storeError,
        dialogFooter: (
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={this.closeDialog}
          >
            Close
          </button>
        ),
      });
    }
    //Fetch Store Error Modal Show
  }

  closeDialog = () => {
    this.setState({
      displayDialog: false,
    });
  };

  filterDashboardButtons = () => {
    let dashboardButtons = [];
    let userdetails = this.props.logindetails;

    /***********************FOR VENDORS ******************************** */
    if (userdetails.userdefault.vendor === "Y") {
      if (userdetails.userdefault.fulfillmentallowed === "Y") {
        dashboardButtons = [
          {
            avtar: "fulfillorder.png",
            text: "Fulfill",
            action: "UNPROCESSED",
            instance: this,
            type: "orders",
            sequenceid: 1,
          },
          ...dashboardButtons,
        ];
      }

      if (
        userdetails.userdefault.express === "N" &&
        userdetails.userdefault.assignmentallowed === "Y"
      ) {
        dashboardButtons = [
          {
            avtar: "assignorder.png",
            text: "Assign Orders",
            action: "FULFILLED",
            instance: this,
            type: "orders",
            sequenceid: 2,
          },
          ...dashboardButtons,
        ];
      }

      if (userdetails.userdefault.express === "N") {
        dashboardButtons = [
          {
            avtar: "confirmorderassignment.png",
            text: "Confirm Order Assignment",
            action: "MYASSIGNEDFULFILLMENT",
            instance: this,
            type: "orders",
            sequenceid: 3,
          },
          ...dashboardButtons,
        ];
      }

      if (userdetails.userdefault.express === "N") {
        dashboardButtons = [
          {
            avtar: "confirmreturn.png",
            text: "Confirm Return",
            action: "ASSIGNEDRETURNS",
            instance: this,
            type: "orders",
            sequenceid: 4,
          },
          ...dashboardButtons,
        ];
      } else {
        if (userdetails.userdefault.checkout !== "Y") {
          dashboardButtons = [
            {
              avtar: "checkinreturn.png",
              text: "Check In Return",
              action: "ASSIGNEDRETURNS",
              instance: this,
              type: "orders",
              sequenceid: 5,
            },
            ...dashboardButtons,
          ];
        }
      }

      if (userdetails.userdefault.express === "N") {
        if (userdetails.userdefault.checkout !== "Y") {
          dashboardButtons = [
            {
              avtar: "checkinreturn.png",
              text: "Check In Return",
              action: "CONFIRMEDRETURNS",
              instance: this,
              type: "orders",
              sequenceid: 5,
            },
            ...dashboardButtons,
          ];
        }
      }
      
      if (userdetails.userdefault.checkout !== "Y") {
        dashboardButtons = [
          {
            avtar: "cancelrequest.png",
            text: "Cancel Request",
            action: "",
            instance: this,
            type: "cancelrequest",
            sequenceid: 8,
          },
          ...dashboardButtons,
        ];
      }

      if (userdetails.userdefault.checkout === "Y") {
        dashboardButtons = [
          {
            avtar: "checkout.png",
            text: "Check Out",
            action: "CHECKEDINORDERS",
            instance: this,
            type: "orders",
            sequenceid: 5,
          },
          ...dashboardButtons,
        ];

        dashboardButtons = [
          {
            avtar: "walkin.png",
            text: "Walk In",
            action: "",
            instance: this,
            type: "walkin",
            sequenceid: 5,
          },
          ...dashboardButtons,
        ];

        if(userdetails.userdefault.supervisor === "Y") {
          dashboardButtons = [
            {
              avtar: "cancelrequest.png",
              text: "Admin",
              action: "",
              instance: this,
              type: "admin",
              sequenceid: 8,
            },
            ...dashboardButtons,
          ];
        }
      }

    }
    /***********************FOR VENDORS ******************************** */

    /***********************FOR LOUNGEOPERATOR ******************************** */
    if (userdetails.userdefault.loungeoperator === "Y") {
      if (userdetails.userdefault.fulfillmentallowed === "Y") {
        dashboardButtons = [
          {
            avtar: "fulfillorder.png",
            text: "Fulfill Orders",
            action: "UNPROCESSED",
            instance: this,
            type: "orders",
            sequenceid: 4,
          },
          ...dashboardButtons,
        ];
      }

      if (userdetails.userdefault.checkinallowed === "Y") {
        dashboardButtons = [
          {
            avtar: "checkin.png",
            text: "Check In",
            action: "CHECKIN",
            instance: this,
            type: "orders",
            sequenceid: 1,
          },
          ...dashboardButtons,
        ];
      }

      if (userdetails.userdefault.returnsallowed === "Y") {
        dashboardButtons = [
          {
            avtar: "checkinreturn.png",
            text: "Return",
            action: "CHECKEDOUTORDERS",
            instance: this,
            type: "orders",
            sequenceid: 3,
          },
          ...dashboardButtons,
        ];
      }

      dashboardButtons = [
        {
          avtar: "checkout.png",
          text: "Check Out",
          action: "CHECKEDINORDERS",
          instance: this,
          type: "orders",
          sequenceid: 2,
        },
        ...dashboardButtons,
      ];

      dashboardButtons = [
        {
          avtar: "cancelrequest.png",
          text: "Admin",
          action: "",
          instance: this,
          type: "admin",
          sequenceid: 8,
        },
        ...dashboardButtons,
      ];

      dashboardButtons = [
        {
          avtar: "walkin.png",
          text: "Walk In",
          action: "",
          instance: this,
          type: "walkin",
          sequenceid: 5,
        },
        ...dashboardButtons,
      ];
    }
    /***********************FOR LOUNGEOPERATOR ******************************** */

    if (userdetails.userdefault.loungeoperator === "Y" || userdetails.userdefault.checkout === "Y") {
        dashboardButtons = [
          {
            avtar: "myconfirmedorder.png",
            text: "Confirmed Orders",
            action: "OPERATORCONFIRMEDFULFILLMENT",
            instance: this,
            type: "orders",
            sequenceid: 6,
          },
          ...dashboardButtons,
        ];
    } else {
	dashboardButtons = [
	      {
		avtar: "myconfirmedorder.png",
		text: "My Confirmed Orders",
		action: "MYCONFIRMEDFULFILLMENT",
		instance: this,
		type: "orders",
		sequenceid: 6,
	      },
	      ...dashboardButtons,
	];
    }

    if (userdetails.userdefault.reportagent === "Y") {
      dashboardButtons = [
        {
          avtar: "reports.png",
          text: "Reports",
          action: "",
          instance: this,
          type: "reports",
          sequenceid: 7,
        },
        ...dashboardButtons,
      ];
    }

    dashboardButtons.sort(function (a, b) {
      if (a.sequenceid < b.sequenceid) {
        return -1;
      }
      else if (a.sequenceid > b.sequenceid) {
        return 1;
      }
      else {
        return -1;
      }
    });

    return dashboardButtons;
  };
  render() {
    let userdetails = this.props.logindetails;
    return (
      <>
        <Loader message="Processing..." />

        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />

        <NavBar />
        <div className="container-fluid stores-container">
          <div className={"row"}>
            {this.filterDashboardButtons().map((dashboardButton, index) => (
              <DashboardButton {...dashboardButton} />
            ))}
          </div>
          <div className="spacer"></div>
        </div>
        <Footer />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  logindetails: state.SignInReducer.logindetails,
  storeError: state.StoreReducer.error,
  storeStatus: state.StoreReducer.status,
});
export default connect(mapStateToProps, { callStoresWebService })(Home);
