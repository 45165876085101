export const FETCH_STORES = "fetch_stores";
export const ORDER_SERVICE_SUCCESS = "order_service_success";
export const ORDER_SERVICE_PENDING = "order_service_pending";
export const ORDER_SERVICE_ERROR = "order_service_error";
export const CONFIRM_CART_SERVICE_SUCCESS = "confirm_cart_service_success";
export const CONFIRM_CART_SERVICE_SUCCESS_WITH_ERROR = "confirm_cart_service_success_with_error";
export const CONFIRM_CART_SERVICE_PENDING = "confirm_cart_service_pending";
export const CONFIRM_CART_SERVICE_ERROR = "confirm_cart_service_error";
export const FLIGHT_SERVICE_SUCCESS = "flight_service_success";
export const FLIGHT_SERVICE_PENDING = "flight_service_pending";
export const FLIGHT_SERVICE_ERROR = "flight_service_error";
export const ADD_TO_CART = "add_to_cart";
export const REMOVE_FROM_CART = "remove_from_cart";
export const EMPTY_CART = "empty_cart";
export const CHANGE_PRODUCT_QUANTITY = "change_product_quantity";
export const RESET_CREDIT_CARD_INFO = "reset_credit_card_info";
export const UPDATE_CREDIT_CARD_INFO = "update_credit_card_info";
export const UPDATE_GUEST_FROM_INFO = "update_guest_form_info";
export const UPDATE_FLIGHT_FORM_INFO = "update_flight_form_info";
export const EMPTY_FLIGHT_FROM_INFO = "empty_flight_form_info";
export const HIDE_DATE_PICKER = "hide_datepicker";
export const SHOW_DATE_PICKER = "show_datepicker";
export const CHANGE_PAYMENT_METHOD = "change_payment_method";
export const UPDATE_ORDER_SERVICE_SUCCESS = "update_order_service_success";
export const UPDATE_ORDER_SERVICE_PENDING = "update_order_service_pending";
export const UPDATE_ORDER_SERVICE_ERROR = "update_order_service_error";
export const CONFIRM_CART_SIGNATURE_ADD = "confirm_cart_signature_add";
export const EMPTY_FLIGHT_FORM_DATA = "empty_flight_form_data";

export const STORES_PENDING = "stores_pending";
export const STORES_SUCCESS = "stores_success";
export const STORES_ERROR = "stores_error";
export const CLEAR_STORES_ERROR = "clear_stores_error";

export const IS_CART_ITEM_EDIT = 'is_cart_item_edit';

export const UPDATE_CART_ITEM = 'update_cart_item';


