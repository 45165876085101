import {
  CHANGE_PAYMENT_METHOD,
  RESET_CREDIT_CARD_INFO,
  UPDATE_CREDIT_CARD_INFO,
  CONFIRM_CART_SERVICE_ERROR,
  CONFIRM_CART_SERVICE_PENDING,
  CONFIRM_CART_SERVICE_SUCCESS,
  EMPTY_CART,
  UPDATE_GUEST_FROM_INFO,
  CONFIRM_CART_SIGNATURE_ADD,
  EMPTY_FLIGHT_FORM_DATA,
  CONFIRM_CART_SERVICE_SUCCESS_WITH_ERROR,
} from "./actionTypes";

import { RESET_CARD_DETAILS } from "../../actions/vendor/actionTypes";

import axios from "axios";
import {
  APPLICATION_CONST,
  PNP_HELD_PAYMENT_FAIL,
  PNP_PAYMENT_FAIL,
  STOCK_UNAVAILABLE,
  STATUS_CODE_PROCESSORDER_REPORT_ERROR,
  ERROR_MESSAGE_SOMETHING_WENT_WRONG
} from "../../Config/CustomConfig";
import { loaderStatus } from "../vendor/loaderAction";
import { callLoginService } from "../../actions/authentication/signInAction";
import NiceModal from '@ebay/nice-modal-react';
import alertModal from "../../components/common/alertModal";

export const updateCardInfo = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_CREDIT_CARD_INFO,
    payload: data,
  });
};

export const emptyGuestInfo = () => (dispatch) => {
  const data = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    useDefaultGuestInfo: false,
  };
  dispatch(updateGuestFormInfo(data));
};

export const updateGuestFormInfo = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_GUEST_FROM_INFO,
    payload: data,
  });
};

export const changePaymentMethod = (paymentMethod) => (dispatch) => {
  dispatch({
    type: CHANGE_PAYMENT_METHOD,
    payload: paymentMethod,
  });
};
export const confirmCart = (data, history) => (dispatch) => {
  const loginDetail = JSON.parse(localStorage.getItem("loginDetail"));

  const body = {
    username: loginDetail.username,
    sessionid: loginDetail.sessionid,
    failstatus: 0,
    request: {
      direction: data.direction,
      scheduleid: data.scheduleId,
      guest: {
        firstname: data.user?.firstname,
        lastname: data.user?.lastname,
        email: data.user?.email,
        phone: data.user?.phone,
      },
      cart: data.cart.map((item) => ({
        productid: item.productid,
        quantity: item.quantityincart ? item.quantityincart : item.quantity,
        taxrate: item.taxrate,
        total: item.total,
        unitcost: item.unitcost,
        vendorid: item.vendorid,
        vendorname: item.vendorname,
      })),
      payment: {},
      servicefee:data?.servicefee ? data?.servicefee : 0,
      servicefeetax:data?.servicefeetax ? data?.servicefeetax : 0,
    },
  };

  if (data.walkin) {
    body.request = { ...body.request, walkin: "Y" };
  }

  if (data.signature) {
    body.request.signature = data.signature;
  }
  
  let tmp_cardnumber = "";
  // if (data.paymentMethod === "creditcard" || data.paymentMethod === "card") {
    if(data.cardInfo?.cardnumber) {
      tmp_cardnumber = data.cardInfo.cardnumber.substr(-4);
    }
    if(data.paymentResponse?.cardnumber) {
      tmp_cardnumber = data.paymentResponse.cardnumber.substr(-4);
    }
  // }  
  console.log("paymentMethod " +data.paymentMethod);
  console.log("Gajendra body",body);
  console.log("card number " +tmp_cardnumber);
  if (data.transactionCode === 0) {
    let cardtype = data.cardInfo.cardtype.toUpperCase();
    data.cardInfo.cardtype = cardtype;
    // body.request.payment.paymenttype = "CREDITCARD";
    body.request.payment.paymenttype = "CREDITCARD";
    body.request.payment.charged = "Y";

    body.request.payment.creditcard = {
      cardtype: data.cardInfo.cardtype,
      cardholder: data.cardInfo.cardholder,
      cardnumber: tmp_cardnumber,
      amount: data.cartTotal,
      authorizationnumber: data.paymentResponse.authorizationnumber,
    };
  } else if (data.transactionCode === 64) {
    let cardtype = data.cardInfo.cardtype.toUpperCase();
    data.cardInfo.cardtype = cardtype;
    // body.request.payment.paymenttype = "CREDITCARD";
    body.request.payment.paymenttype = "CREDITCARD";
    body.request.payment.charged = "Y";

    body.request.payment.creditcard = {
      cardtype: data.cardInfo.cardtype,
      cardholder: data.cardInfo.cardholder,
      cardnumber: tmp_cardnumber,
      amount: data.cartTotal,
      authorizationnumber: data.paymentResponse.authorizationnumber,
    };
  } else if (data.transactionCode === 8) {
    let cardtype = data.cardInfo.cardtype.toUpperCase();
    data.cardInfo.cardtype = cardtype;
    // body.request.payment.paymenttype = "CREDITCARD";
    body.request.payment.paymenttype = "CREDITCARD";
    body.request.payment.charged = "Y";

    body.request.payment.creditcard = {
      cardtype: data.cardInfo.cardtype,
      cardholder: data.cardInfo.cardholder,
      cardnumber: tmp_cardnumber,
      amount: data.cartTotal,
      authorizationnumber: data.paymentResponse.authorizationnumber,
    };
  } else {
    if (data.paymentMethod === "cash") {
      body.request.payment.paymenttype = "CASH";
      body.request.payment.charged = "N";

      body.request.payment.cash = {
        currencycode: data.currencycode,
        exchangerate: data.exchangerate,
        amount: data.cartTotal * data.exchangerate,
      };
    } else {
      let cardtype = data.cardInfo.cardtype.toUpperCase();
      data.cardInfo.cardtype = cardtype;
      body.request.payment.paymenttype = "CREDITCARD";
      body.request.payment.creditcard = {
        email: data.user.email,
        amount: data.cartTotal,
        ...data.cardInfo,
      };
    }
  }

  console.log("REQUEST", `${APPLICATION_CONST.url}confirmcart`);
  console.log("BODY", JSON.stringify(body));

  dispatch(serviceActionPending());
  dispatch(loaderStatus(true));
  axios({
    method: "post",
    url: `${APPLICATION_CONST.url}confirmcart`,
    data: body,
  })
    .then((response) => {
      dispatch(loaderStatus(false));
      if (response.data.status === 0) {
        dispatch(
          callLoginService(
            {
              failstatus: 0,
              username: loginDetail.username,
              password: loginDetail.password,
              terms: loginDetail.terms,
              silentLogin: true,
              internal: loginDetail.internal,
            },
            history
          )
        );
        dispatch(serviceActionSuccess());
        dispatch({ type: EMPTY_CART });
        dispatch({ type: RESET_CREDIT_CARD_INFO });
        dispatch({ type: EMPTY_FLIGHT_FORM_DATA });
        dispatch({ type: RESET_CARD_DETAILS });
        if (data.walkin === "Y") {

          history.push("/order/receipt", {
            actionType: "walkin",
            reportFilename: response.data.data.filename,
            orderid: response.data.data.confirmationnumber,
            receiptdata: { guestemail: data.user.email },
            walkin:"Y"
          });
        } else {
          history.push("/payment/success", {
            orderid: response.data.data.confirmationnumber,
            email: data.user?.email,
          });
        }
      } else if (response.data.status > 0) {
        dispatch({ type: RESET_CARD_DETAILS });

        dispatch(serviceActionError(response.data.statusMessage));
        dispatch({ type: RESET_CREDIT_CARD_INFO });
        if (
          response.data.status in PNP_PAYMENT_FAIL ||
          response.data.status in PNP_HELD_PAYMENT_FAIL
        ) {
          history.push("/payment/failed", {
            errortype: "PNP_PAYMENT_FAIL",
            walkin: data.walkin,
            errorMessage: response.data.statusMessage,
            resubmitData: data,
          });
          dispatch({ type: RESET_CREDIT_CARD_INFO });
        } else if (response.data.status in STOCK_UNAVAILABLE) {
          history.push("/payment/failed", {
            errortype: "STOCK_UNAVAILABLE",
            walkin: data.walkin,
            errorMessage: response.data.statusMessage,
            resubmitData: data,
          });
          dispatch({ type: RESET_CREDIT_CARD_INFO });
        } else if (response.data.status === STATUS_CODE_PROCESSORDER_REPORT_ERROR) {
          dispatch(
            callLoginService(
              {
                failstatus: 0,
                username: loginDetail.username,
                password: loginDetail.password,
                terms: loginDetail.terms,
                silentLogin: true,
                internal: loginDetail.internal,
              },
              history
            )
          );
          dispatch(serviceActionSuccessWithError());
          dispatch({ type: EMPTY_CART });
          dispatch({ type: RESET_CREDIT_CARD_INFO });
          dispatch({ type: EMPTY_FLIGHT_FORM_DATA });
          dispatch({ type: RESET_CARD_DETAILS });

          history.push("/payment/failed", {
            errortype: "",
            walkin: data.walkin,
            statusCode: response.data.status,
            errorMessage: response.data.statusMessage,
            resubmitData: data,
          });

          dispatch(serviceActionError(response.data.statusMessage));
        } else {
          dispatch({ type: RESET_CARD_DETAILS });

          history.push("/payment/failed", {
            errortype: "",
            walkin: data.walkin,
            statusCode: response.data.status,
            errorMessage: response.data.statusMessage,
            resubmitData: data,
          });

          dispatch(serviceActionError(response.data.statusMessage));
        }
      } else {
        // dispatch({ type: RESET_CARD_DETAILS });

        dispatch(
          serviceActionError("Something went wrong.Please try agin later!")
        );
        NiceModal.show(alertModal, { title: "Warning", message: ERROR_MESSAGE_SOMETHING_WENT_WRONG})
      }
    })
    .catch((error) => {
      // dispatch({ type: RESET_CARD_DETAILS });

      dispatch(loaderStatus(false));
      dispatch(serviceActionError(error.message));
      NiceModal.show(alertModal, { title: "Warning", message: error.message})
    });
};

export const serviceActionPending = () => ({
  type: CONFIRM_CART_SERVICE_PENDING,
});

export const serviceActionError = (error) => ({
  type: CONFIRM_CART_SERVICE_ERROR,
  payload: error,
});

export const serviceActionSuccess = () => ({
  type: CONFIRM_CART_SERVICE_SUCCESS,
});

export const serviceActionSuccessWithError = () => ({
  type: CONFIRM_CART_SERVICE_SUCCESS_WITH_ERROR,
});

export const addSignature = (data) => ({
  type: CONFIRM_CART_SIGNATURE_ADD,
  payload: data,
});

export const emptyCart = () => ({
  type: EMPTY_CART,
});
