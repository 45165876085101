import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "../common/navbar";
import Loader from "../common/loader";
import DialogModal from "../common/dialogModal";
import { callUpdateVendorMaster } from "../../actions/vendor/vendorAction";
import Footer from "../common/footer";
import { styles } from "../../styles/commontStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Col, Row } from "react-bootstrap";

class AddVendor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vendor: {
        vendorid: "",
        vendorname: "",
        description: "",
        airportid: "SIA",
        direction: "A",
        contactname: "",
        contactemail: "",
        contactphone: "",
        loungeoperator: "N",
        express: "N",
        status: "A",
        taxrate: "0",
        usedefaultrate:"Y",
        commissionrate:-1
      },
      vendorError: {
        vendorid: "",
        vendorname: "",
        description: "",
        airportid: "",
        direction: "",
        contactname: "",
        contactemail: "",
        contactphone: "",
        loungeoperator: "",
        express: "",
        status: "",
        taxrate: "",
      },
      actiontype: "CREATE",
      displayDialog: false,
      dialogTitle: null,
      dialogBody: null,
      dialogFooter: null,
      validate: true,
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);

    if (this.props.location.state) {
      const vendor = this.props.location.state.vendor;

      if (vendor) {
        this.setState({
          vendor: vendor,
          actiontype: "UPDATE",
        });
      }
    } else if (this.props.location.pathname === "/vendormaster/edit") {
      this.props.history.push("/");
    }

    if(!this.props?.location?.state?.vendor) {
      const loginDetail = JSON.parse(localStorage.getItem("loginDetail"));

      const applicableRates = loginDetail?.rates.find(    
        (item) => item?.defaultrate === "Y"
      );

      this.setState({
        vendor: {
          ...this.state.vendor,
          usedefaultrate:applicableRates?.defaultrate,
          defaultcommissionrate:applicableRates?.commissionrate,
        }
      });
    }
  };

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevProps.vendorerror !== this.props.vendorerror) {
  //     if (this.props.vendorerror === true) {
  //       this.setState({
  //         displayDialog: true,
  //         dialogTitle: "Oops!",
  //         dialogBody: this.props.vendorerrormessage,
  //         dialogFooter: (
  //           <button
  //             className="btn"
  //             style={styles.dialogCancelButtonStyle}
  //             onClick={this.closeDialog}
  //           >
  //             Close
  //           </button>
  //         ),
  //       });
  //     }
  //   }
  // }

  componentWillReceiveProps(nextProps) {
    if (this.props.vendormastererror !== nextProps.vendormastererror) {
      if (nextProps.vendormastererror === true) {
        this.setState({
          displayDialog: true,
          dialogTitle: "Oops!",
          dialogBody: nextProps.vendormastererrormessage,
          dialogFooter: (
            <button
              className="btn"
              style={styles.dialogCancelButtonStyle}
              onClick={this.closeDialog}
            >
              Close
            </button>
          ),
        });
      }
    }
  }

  closeDialog = () => {
    this.setState({
      displayDialog: false,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const vendorid = this.state.vendor.vendorid.trim();
    const vendorname = this.state.vendor.vendorname.trim();
    const description = this.state.vendor.description.trim();
    let vendorError = {
      vendorid: "",
      vendorname: "",
      description: "",
      airportid: "",
      direction: "",
      contactname: "",
      contactemail: "",
      contactphone: "",
      loungeoperator: "",
      express: "",
      status: "",
      taxrate: "",
    };

    let validate = this.state.validate;
    if (this.state.vendor.vendorid !== "UPDATE") {
      if (!vendorid) {
        vendorError.vendorid = "Vendor Id Is required.";
        validate = false;
      }
    }

    if (!vendorname) {
      vendorError.vendorname = "Vendor Name Is required.";
      validate = false;
    }

    if (!description) {
      vendorError.description = "Description Is required.";
      validate = false;
    }

    this.setState({ vendorError: vendorError });

    if (validate) {
      const actiontype = this.state.actiontype;

      if (actiontype === "UPDATE") {
        this.props.callUpdateVendorMaster(
          {
            actiontype: "UPDATE",
            vendorid: vendorid,
            vendorname: vendorname,
            description: description,
            status: this.state.vendor.status,
            usedefaultrate:this.state.vendor.usedefaultrate,
            commissionrate:this.state.vendor.usedefaultrate === "Y" ? -1 : this.state.vendor.commissionrate
          },
          this.props.history
        );
      } else {
        this.props.callUpdateVendorMaster(
          {
            actiontype: "CREATE",
            vendorid: vendorid,
            vendorname: vendorname,
            description: description,
            status: this.state.vendor.status,
            usedefaultrate:this.state.vendor.usedefaultrate,
            commissionrate:this.state.vendor.usedefaultrate === "Y" ? -1 : this.state.vendor.commissionrate
          },
          this.props.history
        );
      }
    }
  };

  render() {
    return (
      <>
        <NavBar />
        <Loader message="Processing" />
        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />

        <Container className={"back-bar-container"} fluid>
          <Row className={"categoryBar back-bar"}>
            <Col lg="12" md="12">
              <a
                className={"back-link"}
                onClick={() => this.props.history.push("/vendormaster")}
              >
                <FontAwesomeIcon
                  icon="chevron-left"
                  color="#777"
                  className={"back-icon "}
                />
                Back
              </a>
              <ul>
                <li>
                  {/* {this.state.actiontype === "CREATE"
                    ? "Add Master Vendor"
                    : "Edit Master Vendor"} */}
                </li>
              </ul>
            </Col>
          </Row>
        </Container>

        <div className="container-fluid stores-container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <form onSubmit={this.handleSubmit}>
                <div className="card">
                  <div className="card-header custom-card-header">
                    <h4>
                      {this.state.actiontype === "CREATE"
                        ? "Add Master Vendor"
                        : "Edit Master Vendor"}
                    </h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label
                              htmlFor="vendorid"
                              style={styles.inputLabelStyle}
                              className={"required"}
                            >
                              Vendor Id
                            </label>
                          </div>
                          <div className="col-md-8">
                            <input
                              type="text"
                              id="vendorid"
                              onChange={(e) =>
                                this.setState({
                                  vendor: {
                                    ...this.state.vendor,
                                    vendorid: e.target.value
                                      .toUpperCase()
                                      .replace(/[^A-Z0-9]/g, ""),
                                  },
                                })
                              }
                              autoFocus={true}
                              value={this.state.vendor.vendorid}
                              className="form-control"
                              placeholder="Enter Vendor Id"
                              disabled={this.state.actiontype === "UPDATE"}
                            />
                            {this.state.vendorError.vendorid && (
                              <small className="text-danger">
                                {this.state.vendorError.vendorid}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label
                              htmlFor="vendorname"
                              style={styles.inputLabelStyle}
                              className={"required"}
                            >
                              Vendor Name
                            </label>
                          </div>
                          <div className="col-md-8">
                            <input
                              type="text"
                              id="vendorname"
                              onChange={(e) =>
                                this.setState({
                                  vendor: {
                                    ...this.state.vendor,
                                    vendorname: e.target.value,
                                  },
                                })
                              }
                              value={this.state.vendor.vendorname}
                              className="form-control"
                              placeholder="Enter Vendor Name"
                            />{" "}
                            {this.state.vendorError.vendorname && (
                              <small className="text-danger">
                                {this.state.vendorError.vendorname}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="row">
                      <div className="col-md-6">
                        <div className="from-group row">
                          <div className="control-label col-md-4">
                            <label
                              htmlFor="vendorname"
                              style={styles.inputLabelStyle}
                              className={"required"}
                            >
                              Status
                            </label>
                          </div>
                          <div className="col-md-8">
                            <label className="control-label">
                              <input
                                type="radio"
                                onChange={(e) =>
                                  this.setState({
                                    vendor: {
                                      ...this.state.vendor,
                                      status:
                                        e.target.value === "A" ? "I" : "A",
                                    },
                                  })
                                }
                                value={this.state.vendor.status}
                                checked={this.state.vendor.status === "A"}
                              />{" "}
                              Active
                            </label>
                            &nbsp;&nbsp;&nbsp;
                            <label className="control-label">
                              <input
                                type="radio"
                                onChange={(e) =>
                                  this.setState({
                                    vendor: {
                                      ...this.state.vendor,
                                      status:
                                        e.target.value === "I" ? "A" : "I",
                                    },
                                  })
                                }
                                value={this.state.vendor.status}
                                checked={this.state.vendor.status === "I"}
                              />{" "}
                              Inactive
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="from-group row">
                          <div className="control-label col-md-4">
                            <label
                              htmlFor="description"
                              style={styles.inputLabelStyle}
                              className={"required"}
                            >
                              Description
                            </label>
                          </div>
                          <div className="col-md-8">
                            <textarea
                              id="description"
                              onChange={(e) =>
                                this.setState({
                                  vendor: {
                                    ...this.state.vendor,
                                    description: e.target.value,
                                  },
                                })
                              }
                              value={this.state.vendor.description}
                              className="form-control"
                              placeholder="Enter Description"
                            ></textarea>
                            {this.state.vendorError.description && (
                              <small className="text-danger">
                                {this.state.vendorError.description}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="row">
                      &nbsp;
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="from-group row">
                          <div className="control-label col-md-4">
                            <label
                              htmlFor="usedefaultrate"
                              style={styles.inputLabelStyle}
                              className={"required"}
                            >
                              Use Default Rate ({this.state.vendor.defaultcommissionrate})
                            </label>
                          </div>
                          <div className="col-md-8">
                            <label className="control-label">
                              <input
                                type="radio"
                                onChange={(e) =>
                                  this.setState({
                                    vendor: {
                                      ...this.state.vendor,
                                      usedefaultrate: "Y",
                                    },
                                  })
                                }
                                value="Y"
                                checked={this.state.vendor.usedefaultrate === "Y"}
                              />{" "}
                              Yes
                            </label>
                            &nbsp;&nbsp;&nbsp;
                            <label className="control-label">
                              <input
                                type="radio"
                                onChange={(e) =>
                                  this.setState({
                                    vendor: {
                                      ...this.state.vendor,
                                      usedefaultrate: "N",
                                    },
                                  })
                                }
                                value="N"
                                checked={this.state.vendor.usedefaultrate === "N"}
                              />{" "}
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    
                      <div className="col-md-6">
                        <div className="from-group row">
                          <div className="control-label col-md-4">
                            <label
                              htmlFor="commissionrate"
                              style={styles.inputLabelStyle}
                              className={"required"}
                            >
                              Commission Rate
                            </label>
                          </div>
                          <div className="col-md-8">                             
                            <input
                              type="number"
                              min="0.01"
                              max="1"
                              step="0.01"
                              id="commissionrate"
                              onChange={(e) =>
                                this.setState({
                                  vendor: {
                                    ...this.state.vendor,
                                    commissionrate: e.target.value,
                                  },
                                })
                              }
                              value={this.state.vendor.usedefaultrate === "Y" ? "" : (this.state.vendor.commissionrate == -1 ? "" : this.state.vendor.commissionrate)}
                              className="form-control"
                              placeholder="Enter Commission Rate"
                              disabled={this.state.vendor.usedefaultrate === "Y"}
                            />                          
                          </div>
                        </div>
                      </div>
                    </div>

                      {/* <div className="col-md-6">
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                            <InputGroup.Radio
                              aria-label="Radio for following text input"
                              onChange={(e) =>
                                this.setState({
                                  vendor: {
                                    ...this.state.vendor,
                                    status: e.target.value === "A" ? "I" : "A",
                                  },
                                })
                              }
                              value={this.state.vendor.status}
                              checked={this.state.vendor.status === "A"}
                            />
                          </InputGroup.Prepend>
                          <FormControl
                            aria-label="Text input with checkbox"
                            value="Active"
                            disabled
                          />
                        </InputGroup>
                      </div>
                      <div className="col-md-6">
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                            <InputGroup.Radio
                              aria-label="Radio for following text input"
                              onChange={(e) =>
                                this.setState({
                                  vendor: {
                                    ...this.state.vendor,
                                    status: e.target.value === "I" ? "A" : "I",
                                  },
                                })
                              }
                              value={this.state.vendor.status}
                              checked={this.state.vendor.status === "I"}
                            />
                          </InputGroup.Prepend>
                          <FormControl
                            aria-label="Text input with checkbox"
                            value="Inactive"
                            disabled
                          />
                        </InputGroup>
                      </div> */}
                    
                  </div>
                  <div className="card-footer">
                    <div className="col-md-12" style={{ padding: 0 }}>
                      <button
                        type="submit"
                        className="btn pull-right submit-btn"
                        style={styles.confirmButtonStyle}
                      >
                        {this.state.actiontype === "CREATE" ? "Save" : "Update"}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="spacer"></div>
        </div>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  logindetails: state.SignInReducer.logindetails,
  vendormastererror: state.VendorReducer.vendormastererror,
  vendormastererrormessage: state.VendorReducer.vendormastererrormessage,
});
export default connect(mapStateToProps, {
  callUpdateVendorMaster,
})(AddVendor);
