import {
  UPDATE_ORDER_SERVICE_ERROR,
  UPDATE_ORDER_SERVICE_SUCCESS,
  UPDATE_ORDER_SERVICE_PENDING,
} from "../../actions/public/actionTypes";

const initialState = {
  status: null,
  error: null,
};

const UpdateOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ORDER_SERVICE_SUCCESS:
      return {
        ...state,
        status: "success",
      };
    case UPDATE_ORDER_SERVICE_PENDING:
      return {
        ...state,
        status: "pending",
      };
    case UPDATE_ORDER_SERVICE_ERROR:
      return {
        ...state,
        status: "error",
        error: action.payload,
      };
    default:
      return state;
  }
};

export default UpdateOrderReducer;
