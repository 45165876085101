import axios from "axios";
import {
  UPDATE_ORDER_SERVICE_PENDING,
  UPDATE_ORDER_SERVICE_ERROR,
  UPDATE_ORDER_SERVICE_SUCCESS,
} from "./actionTypes";
import { APPLICATION_CONST, ERROR_MESSAGE_SOMETHING_WENT_WRONG } from "../../Config/CustomConfig";
import { loaderStatus } from "../vendor/loaderAction";
import NiceModal from '@ebay/nice-modal-react';
import alertModal from "../../components/common/alertModal";

export const callUpdateOrderWebService = (data, history) => (dispatch) => {
  const loginDetail = JSON.parse(localStorage.getItem("loginDetail"));
  const body = {
    username: loginDetail.username,
    sessionid: loginDetail.sessionid,
    failstatus: 0,
    request: data,
  };

  dispatch(serviceActionPending());
  dispatch(loaderStatus(true));
  axios({
    method: "post",
    url: `${APPLICATION_CONST.url}updateorder`,
    data: body,
  })
    .then((response) => {
      dispatch(loaderStatus(false));
      if (response.data.status === 0) {
        dispatch(serviceActionSuccess(response.data.data));
        const message =
          data.actiontype === "CANCELORDER"
            ? "Order Canceled"
            : "Flight Updated";
        history.push("/order/success", { actiontype: data.actiontype });
      } else if (response.data.status > 0) {
        dispatch(serviceActionError(response.data.statusMessage));
      } else {
        dispatch(
          serviceActionError("Something went wrong! Please try again later")
        );
        NiceModal.show(alertModal, { title: "Warning", message: ERROR_MESSAGE_SOMETHING_WENT_WRONG})
      }
    })
    .catch((error) => {
      dispatch(serviceActionError(error.message));
      dispatch(loaderStatus(false));
      NiceModal.show(alertModal, { title: "Warning", message: error.message})
    });
};

export const serviceActionPending = () => ({
  type: UPDATE_ORDER_SERVICE_PENDING,
});

export const serviceActionError = (error) => ({
  type: UPDATE_ORDER_SERVICE_ERROR,
  payload: error,
});

export const serviceActionSuccess = (data) => ({
  type: UPDATE_ORDER_SERVICE_SUCCESS,
  payload: data,
});
