import * as Actions from "../../actions/authentication/actionTypes";

const initialState = {
  authed: true,
  logindetails: {},
  error: null,
};

const SignInReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.LOGIN_SERVICE_SUCCESS:
      return {
        ...state,
        authed: true,
        error: null,
        logindetails: action.payload,
      };
    case Actions.LOGIN_SERVICE_PENDING:
      return {
        ...state,
        error: null,
      };
    case Actions.LOGIN_SERVICE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case Actions.SET_IS_AUTHENTICATED:
      return {
        ...state,
        authed: action.payload,
        // logindetails: {},
      };
    default:
      return state;
  }
};

export default SignInReducer;
