import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "./navbar";
import DialogModal from "./dialogModal";
import { styles } from "../../styles/commontStyles";
import {
  changeProductQuantity,
  removeItem,
  setIsEdit,
} from "../../actions/public/cartAction";
import Footer from "./footer";
import { Container, Image, Toast, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { APPLICATION_CONST } from "../../Config/CustomConfig";
// import Tooltip from "@material-ui/core/Tooltip";
import "../../App.css";
import ServiceFee from "./ServiceFee";

class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayDialog: false,
      dialogTitle: null,
      dialogBody: null,
      dialogFooter: null,
      displayToast: false,
      toastMessage: "",
    };
  }
  _changeProductQuantity = (productid, quantity, maxquantity) => {
    if (quantity > maxquantity) {
      this.setState({
        displayDialog: true,
        dialogTitle: "Oops!",
        dialogBody: `Only ${maxquantity} item left in stock`,
        dialogFooter: (
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={() => {
              this.setState({ displayDialog: false });
            }}
          >
            OK
          </button>
        ),
      });
    } else {
      if (quantity >= 1) {
        this.props.changeProductQuantity(productid, quantity, maxquantity);
      }
    }
  };
  closeDialog = () => {
    this.setState({
      displayDialog: false,
    });
  };

  _removeItmeConfirm = (product) => {
    this.setState({
      displayToast: true,
      toastMessage: `${product.productname} deleted from cart.`,
    });
    this.closeDialog();
    this.props.removeItem(product);
  };
  removeItemFromCart = (product) => {
    this.setState({
      displayDialog: true,
      dialogTitle: "Delete!",
      dialogBody: "Are you sure you want to delete this item?",
      dialogFooter: (
        <>
          <button
            className="btn"
            style={styles.dialogConfirmButtonStyle}
            onClick={() => {
              this._removeItmeConfirm(product);
            }}
          >
            Ok
          </button>
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={this.closeDialog}
          >
            Cancel
          </button>
        </>
      ),
    });
  };
  filterCartItems = (cartProducts) => {
    let filtercart = [];
    cartProducts.forEach((cartproduct) => {
      this.props.stores.forEach((store) => {
        if (store.storeid === cartproduct.vendorid) {
          store.categories.forEach((category) => {
            category.products.forEach((product) => {
              if (product.siblings) {
                product.siblings.forEach((sibling) => {
                  if (sibling.productid === cartproduct.productid) {
                    filtercart.push({
                      ...sibling,
                      quantityincart: cartproduct.quantityincart,
                      total: cartproduct.total,
                      categoryid: category.categoryid,
                      vendorid: cartproduct.vendorid,
                      vendorname: cartproduct.vendorname,
                    });
                  }
                });
              } else {
                if (product.productid === cartproduct.productid) {
                  filtercart.push({
                    ...product,
                    quantityincart: cartproduct.quantityincart,
                    total: cartproduct.total,
                    categoryid: category.categoryid,
                    vendorid: cartproduct.vendorid,
                    vendorname: cartproduct.vendorname,
                  });
                }
              }
            });
          });
        }
      });
    });

    return filtercart;
  };

  returnProductFromProductTracker = (item) => {
    let rootproduct = this.props.productTracker.find(
      (product) => product.productid === item.productid
    );
    return rootproduct;
  };

  editProduct = (item, itemindex) => {
    this.props.setIsEdit(true);

    let storename = this.props.stores.find(
      (store) => store.storeid === item.vendorid
    );

    const category = storename.categories.find(
      (category) => category.categoryid === item.categoryid
    );

    this.props.history.push("/product/detail", {
      product: item,
      storename: storename.name,
      cartindex: itemindex,
      rootproductid: this.returnProductFromProductTracker(item).rootproductid,
      storeid: this.returnProductFromProductTracker(item).vendorid,
      category: category,
    });
  };

  getProductOutOfStockStatusText = (item) => {
    let foundItem = this.props.location?.state?.outofstockitems?.find(x=> x.productid === item.productid)
    
    var tmpProductOutOfStockStatusText = "";

    if(foundItem) {
      if(foundItem.quantity == 0) {
        tmpProductOutOfStockStatusText = "Out of Stock";
      } else {
        tmpProductOutOfStockStatusText = "Only " + foundItem.quantity  + " left"; 
      }
    }
  
    return tmpProductOutOfStockStatusText;
};


  componentDidMount() {}

  render() {
    return (
      <>
        <NavBar />

        <Toast
          onClose={() => this.setState({ displayToast: false })}
          show={this.state.displayToast}
          delay={2000}
          autohide
          style={styles.toast}
        >
          <Toast.Body>
            <span style={{ color: "#FFF" }}> {this.state.toastMessage}</span>
          </Toast.Body>
        </Toast>

        <Container className={"back-bar-container"} fluid>
          <Row className={"back-bar categoryBar"}>
            <Col lg="12" md="12">
              <a
              
                className={"back-link"}
                onClick={() => this.props.history.goBack()}
              >
                <FontAwesomeIcon
                  icon="chevron-left"
                  color="#777"
                  className={"back-icon"}
                />
                Back
              </a>
            </Col>
          </Row>
        </Container>

        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />

        <div
          className={"stores-container"}
          style={{ paddingTop: 30, paddingBottom: 100 }}
        >
          <div className="row justify-content-center">
            {this.props.cartItems.length > 0 ? (
              <>
                <div className="col-md-8">
                  <div className="card">
                    <div
                      style={{
                        padding: 15,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "bold",
                          fontSize: 18,
                          color: "#555",
                        }}
                      >
                        <h3
                          className="summary-title"
                          style={{ fontFamily: "" }}
                        >
                          My Shopping Cart
                        </h3>
                      </div>
                    </div>
                    <div className="card-body">
                      <table className={"cart-items-table"}>
                        <thead>
                          <tr>
                            <th>
                              <label>Item</label>
                            </th>
                            <th>
                              <label>Price</label>
                            </th>
                            <th></th>
                            <th style={{ textAlign: "center" }}>
                              <label>Qty</label>
                            </th>
                            <th>
                              <label>Total</label>
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.filterCartItems(this.props.cartItems).map(
                            (item, index) => (
                              <tr>
                                <td style={{ paddingTop: 12 }}>
                                  <div
                                    style={{
                                      height: 90,
                                      width: 90,
                                      display: "inline-block",
                                    }}
                                  >
                                    <Image
                                      onClick={() =>
                                        this.editProduct(item, index)
                                      }
                                      style={{
                                        height: 90,
                                        width: 90,
                                        borderRadius: 5,
                                        cursor: "pointer",
                                      }}
                                      resizemode="contain"
                                      src={
                                        APPLICATION_CONST.productImageUrl +
                                        item.productreferenceid +
                                        ".png"+"?" +
                                        new Date()
                                      }
                                      alt={item.productname}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      marginLeft: 10,
                                      display: "inline-block",
                                      verticalAlign: "top",
                                    }}
                                  >
                                    <span
                                      onClick={() =>
                                        this.editProduct(item, index)
                                      }
                                      style={{
                                        display: "block",
                                        cursor: "pointer",
                                        fontSize: 13,
                                        fontFamily: "Poppins-Light",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {item.productname}
                                    </span>

                                    <span
                                      style={{
                                        display: "block",
                                        fontSize: 11,
                                        fontFamily: "Poppins-Light",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {item.vendorname}
                                    </span>
                                  </div>
                                </td>

                                <td
                                  style={{
                                    paddingTop: 12,
                                    verticalAlign: "top",
                                  }}
                                >
                                  <span
                                    style={{
                                      textAlign: "left",
                                      fontSize: 13,
                                      fontFamily: "Poppins-Light",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    ${parseFloat(item.unitcost).toFixed(2)}
                                  </span>
                                </td>

                                <td style={{textAlign: "center", paddingTop: 12, verticalAlign: "top"}}>
                                    {/* {
                                      this.props.location?.state?.outofstockitems?.find(x=> x.productid === item.productid) ? 
                                      <>
                                      <span style={{color:"white", padding:"5px"}} class="badge bg bg-danger ">Only {" "}
                                      { this.props.location?.state?.outofstockitems?.find(x=> x.productid === item.productid).quantity  }
                                      {" "} left</span> &nbsp;
                                      </>
                                      : null
                                    } */}
                                    <span style={{color:"white", padding:"5px", width:"85px"}} className="badge bg bg-danger ">{ this.getProductOutOfStockStatusText(item) }</span>&nbsp;
                                </td>

                                <td
                                  style={{
                                    textAlign: "center",
                                    paddingTop: 12,
                                    verticalAlign: "top",
                                  }}
                                >
                                  <span
                                    style={{
                                      display: "block",

                                      fontSize: 13,
                                      fontFamily: "Poppins-Light",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <button
                                      style={{
                                        color: "red",
                                        fontWeight: "bold",
                                        border: "1px solid #BBB",
                                        paddingRight: 20,
                                        paddingLeft: 20,
                                        fontSize: 16,
                                      }}
                                      disabled={item.quantityincart === 1}
                                      onClick={() =>
                                        this._changeProductQuantity(
                                          item.productid,
                                          item.quantityincart - 1,
                                          item.quantity
                                        )
                                      }
                                      className="btn btn-sm"
                                    >
                                      -
                                    </button>
                                    <span
                                      style={{ marginLeft: 5, marginRight: 5 }}
                                    >
                                      {" "}
                                      {item.quantityincart}{" "}
                                    </span>
                                    <button
                                      style={{
                                        color: "green",
                                        fontWeight: "bold",
                                        border: "1px solid #BBB",
                                        paddingRight: 20,
                                        paddingLeft: 20,
                                      }}
                                      onClick={() =>
                                        this._changeProductQuantity(
                                          item.productid,
                                          item.quantityincart + 1,
                                          item.quantity
                                        )
                                      }
                                      className="btn btn-sm"
                                    >
                                      +
                                    </button>
                                  </span>
                                </td>
                                <td
                                  style={{
                                    paddingTop: 12,
                                    verticalAlign: "top",
                                  }}
                                >
                                  <span
                                    style={{
                                      display: "block",

                                      fontSize: 13,
                                      fontFamily: "Poppins-Light",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    $
                                    {parseFloat(
                                      item.unitcost * item.quantityincart
                                    ).toFixed(2)}
                                  </span>
                                </td>
                                {/* <td
                                  style={{
                                    paddingTop: 12,
                                    verticalAlign: "top",
                                  }}
                                >
                                  {" "}
                                  <Tooltip title="Edit" placement="top">
                                    <button className="btn btn-success"
                                      style={{ marginRight: 10 }}
                                      onClick={() =>
                                        this.editProduct(item, index)
                                      }
                                    >
                                      <FontAwesomeIcon icon={["fas", "edit"]} />
                                    </button>
                                  </Tooltip>
                                  <Tooltip title="Delete" placement="top">
                                    <button className="btn btn-danger"
                                      style={{ }}
                                      onClick={() =>
                                        this.removeItemFromCart(item)
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={["fas", "trash"]}
                                      />
                                    </button>
                                  </Tooltip>
                                </td>  */}
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <div
                      style={{
                        padding: 15,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "bold",
                          fontSize: 18,
                          color: "#555",
                        }}
                      >
                        <h3 className="summary-title">Order Summary</h3>
                      </div>
                    </div>
                    <div className="card-body">
                      <div
                        className="d-flex justify-content-between"
                        style={{ marginBottom: 10 }}
                      >
                        <div style={{ fontSize: 14, fontWeight: "bold" }}>
                          Sub Total
                        </div>
                        <div style={{ fontSize: 14 }}>
                          ${this.props.cartTotal.toFixed(2)}
                        </div>
                      </div>
                      <div
                        className="d-flex justify-content-between"
                        style={{ marginBottom: 10 }}
                      >
                        <div style={{ fontSize: 14, fontWeight: "bold" }}>
                          Tax
                        </div>
                        <div style={{ fontSize: 14 }}>
                          ${this.props.cartTaxTotal.toFixed(2)}
                        </div>
                      </div>
                      <div className={"seperator"}></div>
                      
                      <ServiceFee {...this.props} />
                       
                      <div
                        className="d-flex justify-content-between"
                        style={{ marginBottom: 10 }}
                      >
                        <div style={{ fontSize: 16, fontWeight: "bold" }}>
                          Grand Total
                        </div>
                        <div style={{ fontSize: 16, fontWeight: "bold" }}>
                          $
                          {(
                            this.props.cartTotal + this.props.cartTaxTotal + this.props.cartServiceFee + this.props.cartServiceFeeTax
                          ).toFixed(2)}
                        </div>
                      </div>

                      <button
                        style={{
                          backgroundColor: "#FED23D",
                          marginTop: 20,
                          fontSize: 14,
                          fontWeight: "bold",
                          color: "#333",
                        }}
                        className="btn btn-block btn-lg"
                        onClick={() => this.props.history.push("/cart/guest")}
                      >
                        Proceed To Checkout
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="text-center">
                <img
                  src={APPLICATION_CONST.emptyCartImage}
                  height="200"
                  alt="empty-cart"
                />
                <h3>Your cart is empty!</h3>
                <p>Looks like you haven't made your choice yet</p>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  cartItems: state.CartReducer.cart,
  cartTotal: state.CartReducer.cartTotal,
  cartTaxTotal: state.CartReducer.cartTaxTotal,
  cartServiceFee: state.CartReducer.cartServiceFee,
  cartServiceFeeTax: state.CartReducer.cartServiceFeeTax,
  stores: state.StoreReducer.stores,
  userDetail: state.SignInReducer.logindetails,
  productTracker: state.CartReducer.productTracker,
});

export default connect(mapStateToProps, {
  changeProductQuantity,
  removeItem,
  setIsEdit,
})(Cart);
