import axios from 'axios';
import { ORDER_SERVICE_ERROR, ORDER_SERVICE_PENDING, ORDER_SERVICE_SUCCESS } from './actionTypes';
import { APPLICATION_CONST, ERROR_MESSAGE_SOMETHING_WENT_WRONG } from '../../Config/CustomConfig';
import { loaderStatus } from '../vendor/loaderAction';
import NiceModal from '@ebay/nice-modal-react';
import alertModal from "../../components/common/alertModal";

export const getMyOrders = () => dispatch => {
    const loginDetail = JSON.parse(localStorage.getItem('loginDetail'));
    const body = {
        username: loginDetail.username,
        sessionid: loginDetail.sessionid,
        failstatus: 0,
        request: {
            actiontype: "MYORDERS"
        }
    }

    console.log('request',body);
    dispatch(serviceActionPending());
    dispatch(loaderStatus(true));

    axios({
        method: 'post',
        url: `${APPLICATION_CONST.url}getorders`,
        data: body
    }).then(response => {
    console.log('response',response);

        dispatch(loaderStatus(false))
        if (response.data.status === 0) {
            dispatch(serviceActionSuccess(response.data.data.orders));
        } else if (response.data.status > 0) {
            dispatch(serviceActionError(response.data.statusMessage));
            NiceModal.show(alertModal, { title: "Warning", message: response.data.statusMessage})
        } else {
            dispatch(serviceActionError('Something went wrong!'));
            NiceModal.show(alertModal, { title: "Warning", message: ERROR_MESSAGE_SOMETHING_WENT_WRONG})
        }

    }).catch(error => {
        dispatch(loaderStatus(false));
        dispatch(serviceActionError(error.message));
        NiceModal.show(alertModal, { title: "Warning", message: error.message})
    });

}

export const serviceActionPending = () => ({
    type: ORDER_SERVICE_PENDING
})

export const serviceActionError = (error) => ({
    type: ORDER_SERVICE_ERROR,
    payload: error
})

export const serviceActionSuccess = (data) => ({
    type: ORDER_SERVICE_SUCCESS,
    payload: data
})

