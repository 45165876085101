import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";

import { styles } from "../../../styles/commontStyles";
import DialogModal from "../../common/dialogModal";
import { updateFlightForm } from "../../../actions/public/flightInfoAction";
import { emptyCart } from "../../../actions/public/cartAction";
import { Container, Card, Button, Col, Row } from "react-bootstrap";
import { callStoresWebService } from "../../../actions/public/storesAction";

import { APPLICATION_CONST } from "../../../Config/CustomConfig";

import "./AirportsComponent.css";

class AirportsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayDialog: false,
      dialogTitle: null,
      dialogBody: null,
      dialogFooter: null,
    };
  }

  confirmationDialog = (data) => {
    this.setState({
      displayDialog: true,
      dialogTitle: "Oops!",
      dialogBody:
        "Changing the airport will remove all the items from your cart?",
      dialogFooter: (
        <>
          <button
            className="btn"
            style={styles.dialogConfirmButtonStyle}
            onClick={() => this.onConfirm(data)}
          >
            Ok
          </button>
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={this.closeDialog}
          >
            Cancel
          </button>
        </>
      ),
    });
  };

  onConfirm = (param) => {
    this.closeDialog();
    this.props.emptyCart();
    this.props.updateFlightForm(param);
    this.props.history.push("/stores");
  };

  closeDialog = () => {
    this.setState({
      displayDialog: false,
    });
  };

  onVSBtnClick = (data) => {
    const param = [
      { key: "airportid", value: data.airportid },
      { key: "direction", value: data.direction },
    ];
    if (this.props.cartItems.length > 0) {
      const airportid = this.props.airportInfo.airportid;
      const direction = this.props.airportInfo.direction;
      if (data.direction === direction && data.airportid === airportid) {
        this.props.callStoresWebService();
        // this.props.history.push("/stores");
      } else {
        this.confirmationDialog(param);
      }
    } else {
      this.props.updateFlightForm(param);
      this.props.callStoresWebService();

      // this.props.history.push("/stores");
    }
  };

  render() {
    return (
      <Container fluid className={"airport-container"}>
        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />

        <Row noGutters={false}>
          <Col>
            <Card>
              <Card.Img
                variant="top"
                src={
                  APPLICATION_CONST.applicationImages + "airport1-500x500.jpg"
                }
              />
              <div className="cardBodyContainer">
                <div className="cardBody">
                  <div className="directionType">Arrival</div>
                  <div className="airportName">Sangster International</div>
                </div>
                <Button
                  // disabled //TODO: Temporary disabled (No products configred).  button text changed.
                  className={"view-store-button"}
                  onClick={() =>
                    this.onVSBtnClick({ airportid: "SIA", direction: "A" })
                  }
                >
                  Shop Now
                </Button>
              </div>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Img
                variant="top"
                src={
                  APPLICATION_CONST.applicationImages + "airport2-500x500.jpg"
                }
              />
              <div className="cardBodyContainer">
                <div className="cardBody">
                  <div className="directionType">Departure</div>
                  <div className="airportName">Sangster International</div>
                </div>
                <Button
                  className={"view-store-button"}
                  onClick={() =>
                    this.onVSBtnClick({ airportid: "SIA", direction: "D" })
                  }
                >
                  Shop Now
                </Button>
              </div>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Img
                variant="top"
                src={
                  APPLICATION_CONST.applicationImages + "airport3-500x500.jpg"
                }
              />
              <div className="cardBodyContainer">
                <div className="cardBody">
                  <div className="directionType">Arrival</div>
                  <div className="airportName">Norman Manley International</div>
                </div>
                <Button
               //  disabled //TODO: Temporary disabled (No products configred).  button text changed.
                  className={"view-store-button"}
                  onClick={() =>
                    this.onVSBtnClick({ airportid: "NMIA", direction: "A" })
                  }
                >
                  Shop Now
                </Button>
              </div>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Img
                variant="top"
                src={
                  APPLICATION_CONST.applicationImages + "airport4-500x500.jpg"
                }
              />
              <div className="cardBodyContainer">
                <div className="cardBody">
                  <div className="directionType">Departure</div>
                  <div className="airportName">Norman Manley International</div>
                </div>
                <Button 
                  // disabled //TODO: Temporary disabled (No products configred).  button text changed.
                  className={"view-store-button"}
                  onClick={() =>
                    this.onVSBtnClick({ airportid: "NMIA", direction: "D" })
                  }
                >
                  Shop Now
                </Button>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  cartItems: state.CartReducer.cart,
  airportInfo: state.FlightInfoReducer.formData,
  stores: state.StoreReducer.stores,
});
export default connect(mapStateToProps, {
  updateFlightForm,
  emptyCart,
  callStoresWebService,
})(AirportsComponent);
