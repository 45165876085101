import axios from "axios";
import { RECEIPT_SUCCESS, RECEIPT_ERROR, RECEIPT_PENDING } from "./actionTypes";
import { APPLICATION_CONST, ERROR_MESSAGE_SOMETHING_WENT_WRONG } from "../../Config/CustomConfig";
import { loaderStatus } from "./loaderAction";
import NiceModal from '@ebay/nice-modal-react';
import alertModal from "../../components/common/alertModal";

export const callReceiptWebService = (request, history, guestemail, walkin) => (
  dispatch
) => {
  const loginDetail = JSON.parse(localStorage.getItem("loginDetail"));
  const body = {
    username: loginDetail.username,
    sessionid: loginDetail.sessionid,
    failstatus: 0,
    request: request,
  };

   dispatch(serviceActionPending());
  dispatch(loaderStatus(true));

  axios({
    method: "post",
    url: APPLICATION_CONST.url + "printreceipt",
    timeout: 10000,
    data: JSON.stringify(body),
  })
    .then((response) => {
      dispatch(loaderStatus(false));
      if (response.data.status === 0) {
        history.push("/order/receipt", {
          reportFilename: response.data.data.filename,
          orderid: request.orderid,
          actionype: "RECEIPTREPRINT",
          receiptdata: { guestemail: guestemail },
          walkin:walkin
        });
      } else if (response.data.status > 0) {
        dispatch(serviceActionError(response.data.statusMessage));
        NiceModal.show(alertModal, { title: "Warning", message: response.data.statusMessage})
      } else {
        dispatch(serviceActionError("Something went wrong!"));
        NiceModal.show(alertModal, { title: "Warning", message: ERROR_MESSAGE_SOMETHING_WENT_WRONG})
      }
    })
    .catch((error) => {
      dispatch(loaderStatus(false));
      dispatch(serviceActionError(error.message));
      NiceModal.show(alertModal, { title: "Warning", message: error.message})
    });
};

export const serviceActionPending = () => ({
  type: RECEIPT_PENDING,
});

export const serviceActionError = (error) => ({
  type: RECEIPT_ERROR,
  payload: error,
});

export const serviceActionSuccess = (data) => ({
  type: RECEIPT_SUCCESS,
  payload: data,
});
