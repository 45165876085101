import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import routes from "./Config/routeConfig";

import { callLoginService } from "./actions/authentication/signInAction";

import Login from "./components/authentication/login";
import ResetPassword from "./components/authentication/resetPassword";
import Register from "./components/authentication/register";

import NoMatch from "./components/common/noMatch";
import NetworkError from "./components/common/networkError";
import { SUBSCRIBER_LOGIN } from "./Config/CustomConfig";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";

import ScrollToTop from "./Config/ScrollToTop";
import { loaderStatus } from "./actions/vendor/loaderAction";
import ChangePassword from "./components/authentication/changePassword";

import axios from "axios";

import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

//TODO: Please set appropriate  timeout for axios
//axios.defaults.timeout = 3000;

// Sentry.init({
//   dsn:
//     "https://31308c5d73f348e9857c490362a4e840@o467041.ingest.sentry.io/5489549",
//   integrations: [new Integrations.BrowserTracing()],

//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0,
// });

class App extends Component {
  constructor(props) {
    super(props);
    const loginDetail = JSON.parse(localStorage.getItem("loginDetail"));
    this.state = {
      networkError: false,
      backRoute: undefined
    };
  }

  componentDidMount() {
    const loginDetail = JSON.parse(localStorage.getItem("loginDetail"));
    
    axios.interceptors.response.use(undefined, error => {
      this.setState({
        networkError: true,
        backRoute: history.location.pathname
      });
      console.log("Backroute", history.location.pathname);
      return Promise.reject(error);
    });


    let i = 0;

    setInterval(() => {
      i++;
      const signInDetail = JSON.parse(localStorage.getItem("loginDetail"));
      if (signInDetail) {
        const loginTime = signInDetail.time;
        const now = new Date();
        const currenttime = now.getTime();
        if ((currenttime - loginTime) / 1000 / 60 > 50) {
          const data = {
            failstatus: 0,
            username: signInDetail.username,
            password: signInDetail.password,
            terms: signInDetail.terms,
            silentLogin: true,
            internal: signInDetail.internal,
          };
          this.props.callLoginService(data, this.props.history);
        }
      }
    }, 300000);

    // if (
    //   !typeof loginDetail === "object" ||
    //   Object.keys(this.props.logindetails).length === 0
    // ) {
    //   // console.log('checkloginDetail',!loginDetail);
    //   // console.log('checkloginDetaillength',Object.keys(loginDetail).length);
    //   const data = {
    //     failstatus: 0,
    //     username: SUBSCRIBER_LOGIN.username,
    //     password: SUBSCRIBER_LOGIN.password,
    //     terms: SUBSCRIBER_LOGIN.terms,
    //     silentLogin: true,
    //     internal: "Y",
    //   };
    //   this.props.callLoginService(data, this.props.history);
    // }

    console.log("loginDetail", typeof loginDetail === "object");
    if (!typeof loginDetail === "object" || loginDetail === null) {
      // console.log('checkloginDetail',!loginDetail);
      // console.log('checkloginDetaillength',Object.keys(loginDetail).length);
      const data = {
        failstatus: 0,
        username: SUBSCRIBER_LOGIN.username,
        password: SUBSCRIBER_LOGIN.password,
        terms: SUBSCRIBER_LOGIN.terms,
        silentLogin: true,
        internal: "Y",
      };
      this.props.callLoginService(data, this.props.history);
    } else {
      // console.log('checkloginDetaillength',Object.keys(loginDetail).length);
      const signInDetail = JSON.parse(localStorage.getItem("loginDetail"));
      const loginTime = signInDetail.time;
      const now = new Date();
      const currenttime = now.getTime();
      if ((currenttime - loginTime) / 1000 / 60 > 50) {
        const data = {
          failstatus: 0,
          username: loginDetail.username,
          password: loginDetail.password,
          terms: loginDetail.terms,
          silentLogin: true,
          internal: loginDetail.internal,
        };
        this.props.callLoginService(data, this.props.history);
      }
    }






  }

  render() {


    if (this.state.networkError)
      return (
        <div style={{
          textAlign: "center"
        }}>
          <div style={{
            marginTop: 100
          }}>
            <h1><b>502 </b>Server Error</h1>
            <p>Failed to connect to server. Please try again later.</p>
          </div>
          <div>
            <button onClick={() => {
              window.location.reload();
            }} className="btn btn-success btn-outline m-2">
              Retry
            </button>

            <button onClick={() => {
              window.location = "/";
            }} className="btn btn-warning btn-outline m-2">
              Back to Home
            </button>
          </div>
        </div>
      );


    if ((!this.props.logindetails.userdefault) && (!this.state.networkError))
      return (
        <Modal show={true} centered className={"loader-model"}>
          <Modal.Body>
            <div className="text-center">
              <img
                alt="Loading..."
                src={require("../src/images/loader.gif")}
                className="loader-img"
              />
              <p className={"loader-msg"}>Loading</p>
            </div>
          </Modal.Body>
        </Modal>
      );
    return (
      <Router history={history} basename="/">
        <ScrollToTop />
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/change-password" component={ChangePassword} />

          {!this.props.authed && <Redirect to="/login" />}

          {this.props.authed &&
            this.props.forceReset ? (
            <Redirect to="/change-password" />
          ) : null}

          {routes.map((route, index) => {
            if (!this.props.logindetails.userdefault) return null;
            if (
              !this.props.forceReset &&
              this.props.logindetails.userdefault.subscriber === "Y" &&
              route.role.includes("subscriber")
            ) {
              return (
                <Route
                  key={index}
                  path={route.path}
                  component={route.component}
                  exact={route.exact}
                />
              );
            } else if (
              !this.props.forceReset &&
              this.props.logindetails.userdefault.vendor === "Y" &&
              route.role.includes("vendor")
            ) {
              return (
                <Route
                  key={index}
                  path={route.path}
                  component={route.component}
                  exact={route.exact}
                />
              );
            } else if (
              !this.props.forceReset &&
              this.props.logindetails.userdefault.loungeoperator === "Y" &&
              route.role.includes("loungeoperator")
            ) {
              return (
                <Route
                  key={index}
                  path={route.path}
                  component={route.component}
                  exact={route.exact}
                />
              );
            }
          })}
          <Route path="/networkerror" component={NetworkError} />
          <Route path="*" component={NoMatch} />

        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  authed: state.SignInReducer.authed,
  logindetails: state.SignInReducer.logindetails,
  forceReset: state.ChangePasswordReducer.forceReset,
});
export default connect(mapStateToProps, { callLoginService, loaderStatus })(
  App
);
