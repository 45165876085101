let base_url = "https://jemdev.reliablesoftjm.com/";

export const APPLICATION_CONST = {
  username: "airportality",
  sessionid: "00000000013429482816624516",
  url:"https://jemdev.reliablesoftjm.com/AIRPORTALITY/",
  fakeurl: "https://www.abc.com/",
  message: "Something went wrong, Please try again later",
  storeImageUrl: base_url + "images/vendor/",
  productImageUrl: base_url + "images/product/",
  categoryImageUrl: base_url + "images/category/",
  // vendorButtonImageUrl:
  //   "https://www.reliablesoftjm.com/images/airportality/buttons/vendor/",
  vendorButtonImageUrl: base_url + "images/button/",
  reportFilePath: base_url + "reports/",
  applicationImages: base_url + "images/",
  bannerImages: base_url + "images/banner/",
  stickerImages: base_url + "images/sticker/",
  bannerDocsUrl: base_url + "docs/",
  PagesUrl: base_url + "docs/",
  emptyCartImage: base_url + "images/emptycart.png",
  reportImages: base_url + "images/",
  affiliatedId: "3250"
};

export const VIPER_CONST = {
  username: "rlb@viponline",
  sessionid: "00018431844272635233228728",
  url: "https://nigeriadev.reliablesoftjm.com/VIPERWS/",
  alwaysOnUsername: "esite3@viponline",
  alwaysOnSessionid: "00009223581026309436128527"
};

export const PASSWORD_CONST = {
  pass_valid: "Password is valid",
  pass_length_error:"1. 8 or more charaters.",
  pass_sym_error:"2. Must have 1 of these (! @ # $ % ^ & * ()) characters.",
  pass_lowercase_error:"3. Must have at least 3 lower case characters.",
  pass_uppercase_error:"4. Must have at least 2 upper case characters.",
  pass_digit_error:"5. Must have at least 2 digits.",
  old_pass_required: "Old Password is required",
  new_pass_required: "New Password is required",
  conform_pass_notMatch: "Confirm Password not matched",
};

export const AIRPORTS = {
  SIA: " Sangster International",
  NMIA: " Norman Manley International",
};

export const CREATE_LOG = "Y";

export const PNP_PAYMENT_FAIL = {
  113: 113,
  114: 114,
  115: 115,
};

export const PNP_HELD_PAYMENT_FAIL = {
  116: 116,
  117: 117,
};

export const STOCK_UNAVAILABLE = {
  16: 16,
};

export const SUBSCRIBER_LOGIN = {
  username: "airportality",
  password: "5f4dcc3b5aa765d61d8327deb882cf99",
  terms: true,
};

export const MAX_BRAND_SHOW = 15;

export const PRODUCT_SHOW = [
  { count: 10 },
  { count: 20 },
  { count: 50 },
  { count: 100 },
  { count: 500 },
];

export const FAQ_DATA_SET1 = [
  {
    title: "1. Who can use Jamaica Experiences Retail?",
    content: "International passengers arriving or departing",
  },
  {
    title: "2. How does the service work?",
    content:
      "Simply browse the Jamaica Experiences Retail app, select the airport you are arriving to or departing from and your items to be purchased, add them to your shopping cart; when checking out, tell us the date of your departure / arrival, select the most convenient pick-up point and complete your transaction by credit card. One of our sales associates will be expecting you to pick up your order.",
  },
  {
    title: "3. Can I cancel my order?",
    content:
      "Yes, you can cancel your order; log in to your account and change the status of your order any time before your flight departure / arrival. A refund will be issued back to your credit card if you choose to cancel. If you decide to proceed with the purchase, the transaction is only concluded after picking up the goods in the selected pick-up point, located in the airport chosen.",
  },
  {
    title: "4. Do I need to register?",
    content:
      "You do not need to register to browse our website and consult our product information. However, you will need to register to place a pre-order. Duty free shopping is only available for travelers; due to the duty free regulation, it is mandatory that we get your flight information.",
  },
  {
    title: "5. Why are my flight details necessary?",
    content:
      "We need to know when you will be departing / arriving to manage your order accordingly; making sure your goods are ready and available for you to purchase.",
  },
  {
    title: "6. Why is my date of birth necessary?",
    content:
      "Your age is mandatory for us to give our customers access to legally restricted categories such as alcohol and tobacco.",
  },
  {
    title: "7. Can I use the reservation service if I am of minor age?",
    content: "Yes, except for alcohol and tobacco products.",
  },
];

export const FAQ_DATA_SET2 = [
  {
    title: "1. How far in advance can I make a reservation?",
    content:
      "You can place your pre-order no earlier than 180 days and no later than 24hrs prior to your flight.",
  },
  {
    title: "2. How many reservations can I make?",
    content:
      "There is no limit to your pre-orders, you should however keep in mind the allowance limits as specified here. Note that you may have to pay the additional duty and tax differences if you exceed those limits.",
  },
  {
    title: "3. Can I cancel/modify my reservation?",
    content:
      "You can cancel an order, but you cannot modify it. To cancel, you just need to access your account by logging in. But you cannot modify a reservation; you would need to cancel it and place a new order.",
  },
  {
    title: "4. How can I be sure that I placed my order properly?",
    content: `Once you have placed your order, you will receive a confirmation email. If you do not receive a confirmation email, please contact our customer service. <a href="/Contactus">Privacy Policy</a>`,
  },
];

export const FAQ_DATA_SET3 = [
  {
    title: "1. When do I have to pay?",
    content: "Payment is collected upon the reservation of your products.",
  },
  {
    title: "2. Which are the payment methods allowed?",
    content: "All standard payment methods are accepted.",
  },
  {
    title:
      "3. Do I pay any additional taxes on the prices visible on the website?",
    content: "No extra fees shall be applied to online prices.",
  },
  {
    title:
      "4. Can prices change between the moment I place an order and the day I pick up my goods?",
    content:
      "Yes, prices are susceptible to change depending on our partners' price policies.",
  },
];

export const FAQ_DATA_SET4 = [
  {
    title: "1. Where do I pick-up my goods?",
    content:
      "Your pre-ordered goods will be packed and ready for collection at the respective airport lounge pick up point you selected when placing the order online. All details are available on our website.",
  },
  {
    title: "2. Can I modify my order at the airport?",
    content:
      "You are welcome to add or remove any goods from your basket before proceeding to payment. Once payment has been received no modifications can be made.",
  },
  {
    title: "3. What happens if I cannot pick up my order?",
    content:
      "If you do not pick-up your goods on the date of your flight (up to 24hrs after your flight departure) you will receive a refund.",
  },
  {
    title: "4. Can someone else collect the order on my behalf?",
    content:
      "No, this is not possible; The customer must prove its identify when picking up the goods to comply with duty free shopping regulations.",
  },
  {
    title: "5. Can I return some of the items I bought?",
    content:
      "The return policy applies equally to online or in-store purchases. Please refer to our customer service regulation.",
  },
  {
    title: "6. How long do I have to make an exchange or return?",
    content:
      "Returns or exchanges can be made up to 24 days after purchase at the airport; Please refer to our customer service regulation. The company reserves the right to reject returns sent or communicated beyond the return period limit, or items which are not in the same condition in which they were upon purchase.",
  },
];

export const CLIENT_SIDE_PAYMENT_CAPTURE = true;

export const TEST_MODE = false;
export const TEST_ORDER_ID = "AIR200008";
export const SIMULATE_FAC = false;

export const FAC_MERCHANT_URL = "https://mentem.in/fac-standalone/auth.php";

export const SIMULATE_TEST_CARD = true;

export const CART_AMOUNT_MISMATCH_STATUSCODE = 79;
export const SIGNATURE_REQUIRED = false;

export const STATUS_CODE_PROCESSTRANSACTION_INSUFFICIENT_STOCK = 16;
export const STATUS_CODE_PROCESSORDER_REPORT_ERROR = 81;

export const ERROR_MESSAGE_SOMETHING_WENT_WRONG = "Something went wrong! Please try again later";