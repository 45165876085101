import React, {Component} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "../common/navbar";
import Footer from "../common/footer";
import {
    Container,
} from "react-bootstrap";
import axios from "axios";
import {APPLICATION_CONST} from "../../Config/CustomConfig";
import {connect} from "react-redux";
import {loaderStatus} from "../../actions/vendor/loaderAction";
import Loader from "../common/loader";

class Servicework extends Component {
    constructor(props) {
        super(props);
        this.state = {
            response: null,
        };
        this.loadhtml();
    }

    loadhtml = () => {
        this.props.loaderStatus(true);
        axios({
            url: APPLICATION_CONST.url + 'getdocument',
            method: 'POST',
            data: {
                "username": this.props.logindetails.userdefault.username,
                "sessionid": this.props.logindetails.sessionid,
                "request": {
                    "document": "howitworks"
                }
            }
        }).then(response => {
            this.setState({'response': response.data});
            this.props.loaderStatus(false);
        }).catch(error => {
            console.log(error.message);
            this.props.loaderStatus(false);
        });
    }

    render() {
        return (
            <div className="wrapper">
                <NavBar/>
                <Loader message="Processing..."/>
                <Container style={{padding: 25}}>
                    <div dangerouslySetInnerHTML={{__html: this.state.response, sanitize: true}}/>
                </Container>
                <Footer/>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    logindetails: state.SignInReducer.logindetails,
});
export default connect(mapStateToProps, {loaderStatus})(Servicework);
