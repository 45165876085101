import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Container,
  Navbar,
  Nav,
  Button,
  Row,
  Col,
  NavDropdown,
  Toast,
  OverlayTrigger,
} from "react-bootstrap";

import { signOut } from "../../actions/authentication/signOutAction";
import DialogModal from "./dialogModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styles } from "../../styles/commontStyles";
import { Image, Dropdown } from "react-bootstrap";
import { callLoginService } from "../../actions/authentication/signInAction";
import { APPLICATION_CONST, VendorButtonImageUrl } from "../../Config/CustomConfig";
import { emptyCart } from "../../actions/public/cartAction";
import { emptyFlightForm } from "../../actions/public/flightInfoAction";
import { emptyGuestInfo } from "../../actions/public/paymentAction";
import { emptyCardDetails } from "../../actions/vendor/orderProcessAction";
import { createBrowserHistory } from "history";
import BookingPriceList from "./BookingPriceList";

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayDialog: false,
      dialogTitle: null,
      dialogBody: null,
      dialogFooter: null,
      displayToast: false,
      toastMessage: "",
    };
  }

  componentDidMount() { }

  _signOut = () => {
    this.setState({
      displayDialog: true,
      dialogTitle: "Sign Out",
      dialogBody: "Are you sure you want to sign out?",
      dialogFooter: (
        <>
          <Button
            style={styles.dialogConfirmButtonStyle}
            onClick={() => {
              this.setState({ displayDialog: false });
              this.props.signOut(this.props.history);
            }}
          >
            OK
          </Button>
          <Button
            style={styles.dialogCancelButtonStyle}
            onClick={() => this.setState({ displayDialog: false })}
          >
            Cancel
          </Button>
        </>
      ),
    });
  };

  _cartEmptyConfirm = () => {
    this.setState({ displayDialog: false });
    this.props.emptyCart();
    this.props.emptyFlightForm();
    this.props.emptyGuestInfo();
    this.props.emptyCardDetails();
    this.setState({
      displayToast: true,
      toastMessage: `Cart is empty.`,
    });
  };

  _cartEmpty = () => {
    this.setState({
      displayDialog: true,
      dialogTitle: "Reset!",
      dialogBody: "Are you sure you want to reset Cart?",
      dialogFooter: (
        <>
          <Button
            style={styles.dialogConfirmButtonStyle}
            onClick={() => this._cartEmptyConfirm()}
          >
            OK
          </Button>
          <Button
            style={styles.dialogCancelButtonStyle}
            onClick={() => this.setState({ displayDialog: false })}
          >
            Cancel
          </Button>
        </>
      ),
    });
  };

  fetchHeaderInfo = () => {
    const { subscriber, loungeoperator } = this.props.logindetails.userdefault;

    const history = createBrowserHistory();
    const path = history.location.pathname;
    const locationState = history.location.state;

    let category;
    let storename;

    if (locationState) {
      if ("category" in locationState) {
        if ("description" in locationState.category) {
          category = locationState.category.description;
        }
      }

      if ("storeid" in locationState) {
        let storeid = locationState.storeid;
        let findStore = this.props.stores.find(
          (store) => store.storeid === storeid
        );

        if (findStore) {
          storename = findStore.name;
        }
      }
    }

    const direction = this.props.flightData.direction;
    const airportid = this.props.flightData.airportid;

    let airport;
    let directionType;

    if (path !== "/" && path !== "//") {
      if (direction != null) {
        directionType =
          direction === "D"
            ? "Departure"
            : direction === "A"
              ? "Arrival"
              : null;
      }

      if (airportid != null) {
        airport =
          airportid === "SIA"
            ? "/ Sangster International"
            : airportid === "NMIA"
              ? "/ Norman Manley International"
              : null;
      }

      if (storename) {
        storename = "/ " + storename;
      }

      if (category) {
        category = "/ " + category;
      }

      if (subscriber === "Y" || loungeoperator === "Y") {
        return (
          <>
            <FontAwesomeIcon
              icon="globe"
              color="#FFFFFF"
              style={{ marginRight: 5 }}
              size="lg"
            />

            <div>
              &nbsp;
              {directionType}
            </div>

            <div>
              &nbsp;
              {airport}
            </div>

            <div>
              &nbsp;
              {storename}
            </div>
            <div>
              &nbsp;
              {category}
            </div>
          </>
        );
      }
    }
  };

  render() {
    return (
      <>
        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />

        <Toast
          onClose={() => this.setState({ displayToast: false })}
          show={this.state.displayToast}
          delay={2000}
          autohide
          style={styles.toast}
        >
          <Toast.Body>
            <span style={{ color: "#FFF" }}> {this.state.toastMessage}</span>
          </Toast.Body>
        </Toast>

        <Container
          style={{
            backgroundColor: "#000000",
          }}
          fluid
        >
          <Row
            style={{
              paddingRight: 10,
              paddingLeft: 10,
              paddingTop: 8,
              paddingBottom: 8,
            }}
          >
            <Col
              style={{
                color: "#FFFFFF",
                fontSize: 12,
                display: "flex",
                alignItems: "center",
                height: 25,
                // padding:12
              }}
              lg="12"
              md="12"
            >
              {this.fetchHeaderInfo()}
              <div
                style={{
                  fontSize: 12,
                  position: "absolute",

                  right: 0,
                }}
              >
                <div style={{ display: "inline-block", marginRight: 10 }}>
                  {this.props.logindetails.userdefault &&
                    this.props.logindetails.internal === "Y" ? (
                    <Nav.Link
                      as={Link}
                      to="/login"
                      style={{ color: "#FFF", fontSize: 12 }}
                    >
                      Login
                    </Nav.Link>
                  ) : null}

                  {(this.props.logindetails.userdefault &&
                    this.props.logindetails.internal === "N") ||
                    !this.props.logindetails.internal ? (
                    <>
                      <Nav.Item
                        style={{
                          color: "#FFF",
                          padding: 10,
                          display: "inline-block",
                          fontWeight: "bold",
                        }}
                      >
                        {this.props.logindetails.userdefault.username}
                      </Nav.Item>

                      <Nav.Link
                        onClick={() => this._signOut()}
                        style={{
                          color: "#FFF",
                          padding: 10,
                          display: "inline-block",
                        }}
                      >
                        Log Out
                      </Nav.Link>
                    </>
                  ) : null}
                </div>
                <div style={{ display: "inline-block" }}>
                  <Nav.Link
                    as={Link}
                    to="/faq"
                    style={{ color: "#FFF", padding: 10 }}
                  >
                    <FontAwesomeIcon
                      icon="question-circle"
                      color="#FFF"
                      size="lg"
                    ></FontAwesomeIcon>
                  </Nav.Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <Navbar
          expand="lg"
          collapseOnSelect
        
          style={{
            backgroundColor: "#FFF",
            zIndex: 25,
            position: "sticky",
          }}
          variant="dark"
          sticky="top"
          className={"headerShadow"}
        >
          <Navbar.Brand as={Link} to="/">
            {" "}
            <Image
              style={{
                height: 100,
                width: "auto",
                margin: 3,
                alignSelf: "center",
              }}
              resizemode="contain"
              src={APPLICATION_CONST.applicationImages + "logo.png"}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav ">
            <Nav className="ml-auto">
              {this.props.logindetails.userdefault ? (
                <>
                  {this.props.logindetails.userdefault.manageuser === "Y" ? (
                    <Nav.Link as={Link} to="/users" style={styles.navLinkStyle}>
                      Users
                    </Nav.Link>
                  ) : null}
                  {(this.props.logindetails.userdefault.subscriber === "Y" &&
                    this.props.logindetails.internal === "N") ||
                    !this.props.logindetails.internal ? (
                    <>
                      <Nav.Link
                        as={Link}
                        to="/orders"
                        style={styles.navLinkStyle}
                      >
                        My Orders
                      </Nav.Link>
                      <Nav.Link
                        as={Link}
                        to="/change-password"
                        style={styles.navLinkStyle}
                      >
                        Change Password
                      </Nav.Link>
                    </>
                  ) : null}
                  {this.props.logindetails.userdefault.vendor === "Y" &&
                    this.props.logindetails.userdefault.subscriber === "N" ? (
                    <>
                      {this.props.logindetails.userdefault.supervisor ===
                        "Y" ? (
                        <Nav.Link
                          as={Link}
                          to="/products"
                          style={styles.navLinkStyle}
                        >
                          Products
                        </Nav.Link>
                      ) : null}

                      <Nav.Link
                        as={Link}
                        to="/change-password"
                        style={styles.navLinkStyle}
                      >
                        Change Password
                      </Nav.Link>
                    </>
                  ) : null}

                  {this.props.logindetails.userdefault.loungeoperator === "Y" &&
                    this.props.logindetails.userdefault.supervisor === "Y" ? (
                    <>
                      <NavDropdown
                        title="Vendors"
                        id="basic-nav-dropdown"
                        style={styles.navLinkStyle}
                      >
                        <NavDropdown.Item
                          as={Link}
                          to="/vendormaster"
                          style={styles.navLinkStyle}
                        >
                          Vendor Master{" "}
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/vendors"
                          style={styles.navLinkStyle}
                        >
                          Vendors{" "}
                        </NavDropdown.Item>
                      </NavDropdown>

                      <NavDropdown
                        title="Products"
                        id="basic-nav-dropdown1"
                        style={styles.navLinkStyle}
                      >
                        <NavDropdown.Item
                          as={Link}
                          to="/classtypes"
                          style={styles.navLinkStyle}
                        >
                          Class Types{" "}
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/classifications"
                          style={styles.navLinkStyle}
                        >
                          Classification{" "}
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/categorys"
                          style={styles.navLinkStyle}
                        >
                          Categories{" "}
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/productmaster"
                          style={styles.navLinkStyle}
                        >
                          Product Master{" "}
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/products"
                          style={styles.navLinkStyle}
                        >
                          Products{" "}
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/vendorproductcommission"
                          style={styles.navLinkStyle}
                        >
                          Product Commission{" "}
                        </NavDropdown.Item>
                      </NavDropdown>
                      <Nav.Link
                        as={Link}
                        to="/change-password"
                        style={styles.navLinkStyle}
                      >
                        Change Password
                      </Nav.Link>
                    </>
                  ) : null}
                </>
              ) : null}
              {(this.props.logindetails.userdefault.vendor === "Y" &&
                this.props.logindetails.userdefault.subscriber === "N") ||
                this.props.logindetails.userdefault.loungeoperator ===
                "Y" ? null : (
                <BookingPriceList/>
              )}

              {this.props.logindetails.userdefault ? (
                <>
                  {this.props.logindetails.userdefault.loungeoperator === "Y" ||
                    this.props.logindetails.userdefault.vendor === "N" ||
                    this.props.logindetails.userdefault.subscriber === "Y" ? (
                    <>
                      <Nav.Link
                        as={Link}
                        to="/cart"
                        style={styles.navLinkStyle}
                      >
                        <div
                          style={{
                            backgroundColor: "#FED23D",
                            fontSize: 11,
                            color: "#673AB7",
                            borderRadius: 10,
                            position: "absolute",
                            paddingRight: 5,
                            paddingLeft: 5,
                            marginLeft: 15,
                            marginTop: -8,
                          }}
                        >
                          {this.props.cartItems.length}
                        </div>
                        <FontAwesomeIcon
                          icon="shopping-cart"
                          color="#4C3B4D"
                          size="lg"
                          style={{ fontSize: 30 }}
                        ></FontAwesomeIcon>
                      </Nav.Link>
                      {this.props.cartItems.length > 0 ? (
                        <Nav.Link
                          onClick={() => this._cartEmpty()}
                          title="Delete items from Cart"
                          style={styles.navLinkStyle}
                        >
                          <FontAwesomeIcon
                            icon="trash"
                            color="#4C3B4D"
                            size="lg"
                            style={{ fontSize: 30 }}
                          ></FontAwesomeIcon>
                        </Nav.Link>
                      ) : null}
                    </>
                  ) : null}{" "}
                </>
              ) : null}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  logindetails: state.SignInReducer.logindetails,
  cartItems: state.CartReducer.cart,
  flightData: state.FlightInfoReducer.formData,
  stores: state.StoreReducer.stores,
});
export default connect(mapStateToProps, {
  signOut,
  callLoginService,
  emptyCart,
  emptyFlightForm,
  emptyGuestInfo,
  emptyCardDetails,
})(NavBar);
