import * as Actions from '../../actions/authentication/actionTypes';

const initialState = {
    error : null,
    success : false
}

const SignUpReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.SIGNUP_SERVICE_PENDING:
            return {
                ...state,
                error : null,
                success : false
            }
        case Actions.SIGNUP_SERVICE_SUCCESS:
            return {
                ...state,
                error : null,
                success : true
            }
        case Actions.SIGNUP_SERVICE_ERROR:
            return {
                ...state,
                error : action.payload,
                success : false
            }
        default:
            return state;
    }
}

export default SignUpReducer;