import {
  VENDORPRODUCT_COMMISSION_SERVICE_PENDING,
  VENDORPRODUCT_COMMISSION_SERVICE_ERROR,
  VENDORPRODUCT_COMMISSION_SERVICE_SUCCESS,
  VENDORPRODUCT_COMMISSION_UPDATE_SERVICE_SUCCESS,
  VENDORPRODUCT_COMMISSION_UPDATE_SERVICE_ERROR
} from "../../actions/vendor/actionTypes";

const initialState = {
  vendorproductcommissionslist: [],
  vendorproductcommissionserror: false,
  vendorproductcommissionserrormessage: "",
  updatevendorproductcommissionerror:false,
  updatevendorproductcommissionerrormessage:""
};

const VendorProductCommissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case VENDORPRODUCT_COMMISSION_SERVICE_SUCCESS:
      return {
        ...state,
        vendorproductcommissionslist: action.payload.vendorcommissions,
        vendorproductcommissionserror: false,
        vendorproductcommissionserrormessage: "",
      };
    case VENDORPRODUCT_COMMISSION_SERVICE_PENDING:
      return {
        ...state,
        vendorproductcommissionserror: false,
        vendorproductcommissionserrormessage: "",
      };
    case VENDORPRODUCT_COMMISSION_SERVICE_ERROR:
      return {
        ...state,
        vendorproductcommissionserror: true,
        vendorproductcommissionserrormessage: action.payload,
        vendorproductcommissionslist: [],
      };
    case VENDORPRODUCT_COMMISSION_UPDATE_SERVICE_SUCCESS:
      return {
        ...state,
        updatevendorproductcommissionerror:false,
        updatevendorproductcommissionerrormessage:""
      };
    case VENDORPRODUCT_COMMISSION_UPDATE_SERVICE_ERROR:
      return {
        ...state,
        updatevendorproductcommissionerror:false,
        updatevendorproductcommissionerrormessage: action.payload,
      };
    default:
      return state;
  }
};

export default VendorProductCommissionReducer;
