import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "./navbar";
import Loader from "./loader";
import Footer from "./footer";
import DatePicker from "react-datepicker";
import {
  getFlightSchedule,
  updateFlightForm,
} from "../../actions/public/flightInfoAction";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styles } from "../../styles/commontStyles";
import moment from "moment";
import DialogModal from "./dialogModal";
import { Container, Col, Row } from "react-bootstrap";
import Select from "react-select";
import { dateFormat } from "../../actions/common/dateTime";
import { validateScheduleId } from "../../actions/public/validateScheduleId";
import ServiceFee from "./ServiceFee";

const formatOptionLabel = ({ value, label, scheduleId, targetDate }) => (
  <div style={{ display: "flex", borderRadius: 0, padding: 0, fontSize: 12 }}>
    <div style={{ width: "70%" }}>{label}</div>
    <div
      className={"flight-time-option"}
      style={{
        width: "30%",
        marginLeft: "10px",
        fontWeight: "bold",
        textAlign: "right",
      }}
    >
      {targetDate.substr(11, 8)}
    </div>
  </div>
);

class Flight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      traveldate: "",
      airline: { value: "", error: "" },
      flightdate: { value: "", error: "" },
      flighttime: "",
      flightId: { value: this.props.formData.flightId, error: "" },
      startDate: new Date(),
      flights: [],
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);

    if (this.props.cartItems.length === 0) {
      this.props.history.push("/");
    }

    this.setState({
      airline: { ...this.state.airline, value: this.props.formData.airline },
      flightId: {
        ...this.state.flightId,
        value: this.props.formData.flightId,
      },
      flightdate: {
        ...this.state.flightdate,
        value: this.props.formData.flightId,
      },
    });

    let formData = this.props.formData;
    if (formData.traveldate === null && formData.departureDate === null) {
      this.handleChange(this.state.startDate);
    } else {
      this.setState({ startDate: new Date(formData.departureDate) });
    }
  };

  resetError = () => {
    this.setState({
      airline: { ...this.state.airline, error: "" },
      flightdate: { ...this.state.flightdate, error: "" },
    });
  };

  handleChange = (value) => {
    this.setState({
      startDate: value,
    });

    const dateString = `${value.getFullYear()}${
      value.getMonth() < 9 ? "0" + (value.getMonth() + 1) : value.getMonth() + 1
    }${value.getDate() < 10 ? "0" + value.getDate() : value.getDate()}`;
    let flightData = {
      airportid: this.props.formData.airportid,
      direction: this.props.formData.direction,
      traveldate: dateString,
    };

    this.props.updateFlightForm([
      {
        key: "airline",
        value: null,
      },
      {
        key: "flightId",
        value: null,
      },
      {
        key: "targetDate",
        value: null,
      },
      {
        key: "traveldate",
        value: dateString,
      },
      {
        key: "departureDate",
        value: value.toString().substring(4, 16),
      },
      {
        key: "flighttime",
        value: "",
      },
    ]);
    this.setState({
      airline: { ...this.state.airline, value: null },
      flightId: { ...this.state.flightId, value: "" },
      flightdate: { ...this.state.flightdate, value: null },
      flighttime: "",
    });
    this.props.getFlightSchedule(flightData);
  };
  onChangeAirline = (event) => {
    this.setState({
      airline: { ...this.state.airline, value: event.value },
      airlineid: event.airlineid,
      flighttime: "",
      flightId: { ...this.state.flightId, value: null },
    });

    this.props.updateFlightForm([
      {
        key: "airline",
        value: event.value,
      },
      {
        key: "flighttime",
        value: "",
      },
    ]);
  };
  onChangeFlight = (event) => {
    let minimumarrivalhours =
      this.props.userDetail.userdefault.minimumarrivalhours;
    let minimumdeparturehours =
      this.props.userDetail.userdefault.minimumdeparturehours;

    let minimumhour = 0;

    if (this.props.formData.direction === "D") {
      minimumhour = minimumdeparturehours;
    } else {
      minimumhour = minimumarrivalhours;
    }

    if (event.value === "") {
      this.setState({
        flighttime: "",
      });
    } else {
      let now = moment();
      let flightdatetime = moment(event.targetDate, "YYYY-MM-DD hh:mm A");
      let timediff = moment(flightdatetime).diff(now, "hours");

      let isFlightBeforeNow = moment(flightdatetime).isBefore(now);

      if (this.props.userDetail.userdefault.subscriber === "Y") {
        validateScheduleId({
          direction: this.props.formData.direction,
          scheduleid: event.scheduleId,
        }).then(
          function (value) {
            console.log("value", value);

            if (value.valid === "Y") {
              this.setState({
                flightdate: { ...this.state.flightdate, value: event.value },
                flighttime: event.targetDate,
                flightId: { ...this.state.flightId, value: event.value },
              });
              this.props.updateFlightForm([
                {
                  key: "flightId",
                  value: event.value,
                },
                {
                  key: "flighttime",
                  value: event.targetDate,
                },
                {
                  key: "scheduleId",
                  value: event.scheduleId,
                },
              ]);
            } else {
              this.setState({
                displayDialog: true,
                dialogTitle: "Oops!",
                dialogBody: value.statusMessage,
                dialogFooter: (
                  <button
                    className="btn"
                    style={styles.dialogCancelButtonStyle}
                    onClick={() => {
                      this.setState({ displayDialog: false });
                    }}
                  >
                    OK
                  </button>
                ),
              });
            }
          }.bind(this)
        );
      } else {
        this.setState({
          flightdate: { ...this.state.flightdate, value: event.value },
          flighttime: event.targetDate,
          flightId: { ...this.state.flightId, value: event.value },
        });
        this.props.updateFlightForm([
          {
            key: "flightId",
            value: event.value,
          },
          {
            key: "flighttime",
            value: event.targetDate,
          },
          {
            key: "scheduleId",
            value: event.scheduleId,
          },
        ]);
      }
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (
      this.state.airline.value === null ||
      this.state.flightdate.value === ""
    ) {
      this.setState({
        airline: { ...this.state.airline, error: "Please Select Airline." },
      });
    }

    if (
      this.state.flightdate.value === "" ||
      this.state.flightdate.value === null
    ) {
      this.setState({
        flightdate: {
          ...this.state.flightdate,
          error: "Please Select Flight Date.",
        },
      });
    }

    if (
      this.state.flightId.value === "" ||
      this.state.flightdate.value === null
    ) {
      this.setState({
        flightId: {
          ...this.state.flightId,
          error: "Please Select Flight.",
        },
      });
    }

    if (
      this.state.airline.value &&
      this.state.flightdate.value &&
      this.state.flightId.value
    ) {
      this.props.history.push("/cart/payment", {
        skipguestvalidation: this.props.location.state.skipguestvalidation
          ? this.props.location.state.skipguestvalidation
          : false,
      });
    }
  };
  addDays = () => {
    let newDate = new Date();
    let date = "";
    if (this.props.userDetail.userdefault.subscriber === "Y") {
      if (this.props.formData.direction === "A") {
        let allowedarrivaldays =
          this.props.userDetail.userdefault.allowedarrivaldays;
        date = newDate.getDate() + (allowedarrivaldays - 1);
      } else {
        let alloweddeparturedays =
          this.props.userDetail.userdefault.alloweddeparturedays;
        date = newDate.getDate() + (alloweddeparturedays - 1);
      }
    } else {
      date = newDate.getDate();
    }

    let month = newDate.getMonth();
    let year = newDate.getFullYear();
    return new Date(year, month, date);
  };

  flightsFilter = () => {
    let flights = [];
    if ("flightschedule" in this.props.data) {
      this.props.data.flightschedule.map((flight, index) => {
        if (flight.airline === this.state.airline.value) {
          flights.push({
            value: flight.flightId,
            label: flight.flightId,

            scheduleId: flight.scheduleId,
            targetDate: flight.targetDate,
          });
        }
      });
    }
    return flights;
  };

  airlineFilter = () => {
    let airlines = [];
    if ("airlines" in this.props.data) {
      this.props.data.airlines.map((airline, index) => {
        airlines.push({
          value: airline.description,
          label: airline.description,
          airlineid: airline.airlineid,
        });
      });
    }
    return airlines;
  };

  render() {
    return (
      <>
        <NavBar />
        <Loader message="Retrieving Flights..." />

        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />

        <Container className={"back-bar-container"} fluid>
          <Row className={"back-bar"}>
            <Col lg="12" md="12">
              <a
                className={"back-link"}
                onClick={() => this.props.history.goBack()}
              >
                <FontAwesomeIcon
                  icon="chevron-left"
                  color="#777"
                  className={"back-icon"}
                />
                Back
              </a>
            </Col>
          </Row>
        </Container>

        <div className="container-fluid stores-container">
          <form onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="col-md-8">
                <div className="card">
                  <div
                    style={{
                      // padding: 15,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div className={"card-head "}>
                      <h3 className="summary-title">Flight Details</h3>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="traveldate" className="FWB FS14">
                            Travel Date
                          </label>
                          <br></br>
                          <DatePicker
                            selected={this.state.startDate}
                            className="form-control "
                            onChange={this.handleChange}
                            minDate={new Date()}
                            maxDate={this.addDays()}
                            dateFormat={dateFormat(
                              "datepicker",
                              this.props.userDetail.userdefault
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="airline" className="FWB FS14">
                            Airline
                          </label>
                          <Select
                            className="custom-form-control"
                            style={{ fontWeight: "bold", fontSize: 14 }}
                            onChange={this.onChangeAirline}
                            onFocus={this.resetError}
                            options={this.airlineFilter()}
                            placeholder={"Select Airline"}
                            value={this.airlineFilter().filter(
                              (option) =>
                                option.value === this.state.airline.value
                            )}
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                neutral50: "#5c6873", // Placeholder color
                              },
                            })}
                          />
                          {this.state.airline.error && (
                            <small className="text-danger">
                              {this.state.airline.error}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="flightdate" className="FWB FS14">
                            Flight
                          </label>
                          <Select
                            name="form-field-name"
                            formatOptionLabel={formatOptionLabel}
                            options={this.flightsFilter()}
                            onChange={this.onChangeFlight}
                            onFocus={this.resetError}
                            // value={this.state.flightId.value}
                            value={this.flightsFilter().filter(
                              (option) =>
                                option.value === this.state.flightId.value
                            )}
                            placeholder={"Select Flight"}
                          />
                          {this.state.flightId.error && (
                            <small className="text-danger">
                              {this.state.flightId.error}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="flightime" className="FWB FS14">
                            Flight Time
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={
                              this.props.formData.flighttime != null
                                ? this.props.formData.flighttime.substr(11, 8)
                                : ""
                            }
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card">
                  <div
                    style={{
                      // padding: 15,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div className={"card-head "}>
                      <h3 className="summary-title">Order Summary</h3>
                    </div>
                  </div>
                  <div className="card-body">
                    <div
                      className="d-flex justify-content-between"
                      style={{ marginBottom: 10 }}
                    >
                      <div className="FWB FS14">Sub Total</div>
                      <div className="FS14">
                        ${this.props.cartTotal.toFixed(2)}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between MB10">
                      <div className="FWB FS14">Tax</div>
                      <div className="FS14">
                        ${this.props.cartTaxTotal.toFixed(2)}
                      </div>
                    </div>
                    <div className={"seperator"}></div>

                    <ServiceFee {...this.props} />

                    <div className="d-flex justify-content-between MB10">
                      <div className="FWB FS16">Grand Total</div>
                      <div className="FWB FS16">
                        $
                        {(
                          this.props.cartTotal + this.props.cartTaxTotal + this.props.cartServiceFee + this.props.cartServiceFeeTax
                        ).toFixed(2)}
                      </div>
                    </div>
                    <button
                      type="submit"
                      style={{
                        backgroundColor: "#FED23D",
                        marginTop: 20,
                        fontSize: 14,
                        fontWeight: "bold",
                        color: "#333",
                      }}
                      className="btn btn-block btn-lg"
                    >
                      Proceed To Payment
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div className="spacer"></div>
        </div>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  cartItems: state.CartReducer.cart,
  cartTotal: state.CartReducer.cartTotal,
  cartTaxTotal: state.CartReducer.cartTaxTotal,
  cartServiceFee: state.CartReducer.cartServiceFee,
  cartServiceFeeTax: state.CartReducer.cartServiceFeeTax,
  formData: state.FlightInfoReducer.formData,
  data: state.FlightInfoReducer.data,
  userDetail: state.SignInReducer.logindetails,
});
export default connect(mapStateToProps, {
  getFlightSchedule,
  updateFlightForm,
})(Flight);
