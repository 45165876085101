import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "../common/footer";
import NavBar from "../common/navbar";
import {
  Image,
  Card,
  Row,
  Col,
  Button,
  Container,
  Toast,
  Carousel,
} from "react-bootstrap";
import { styles } from "../../styles/commontStyles";
import {
  APPLICATION_CONST,
  MAX_BRAND_SHOW,
  PRODUCT_SHOW,
} from "../../Config/CustomConfig";
import { callStoresWebService } from "../../actions/public/storesAction";
import DialogModal from "../common/dialogModal";
import Loader from "../common/loader";

import { getProductService } from "../../actions/vendor/productAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addToCart } from "../../actions/public/cartAction";
 import ReactPaginate from "react-paginate";
import InputRange from "react-input-range";
import SortBy from "../common/sortBy";

import "react-input-range/lib/css/index.css";

const STORE_CARD_WIDTH = 280;

class Stores extends Component {
  constructor(props) {
    super(props);
    this.state = {
      storelist: [],
      storeid: null,
      displayToast: false,
      toastMessage: "",
      selectedProduct: [],
      offset: 0,
      data: [],
      perPage: 10,
      currentPage: 0,
      filterProducts: [],
      value: { min: null, max: null },

      searchTag: "",
      sortBy: "product_asc",
      products: this.filterProducts(),
      max_brand_show: MAX_BRAND_SHOW,
      instance: this,
      arrowShow: true,
    };

    this.handlePageClick = this.handlePageClick.bind(this);
    this.navRef = React.createRef();
  }

  onSelect = (key) => {
    this.setState({ selected: key });
  };

  componentWillUnmount() {}

  componentDidMount = () => {
    window.scrollTo(0,0);

    const airportId = this.props.flightData.airportid;
    const direction = this.props.flightData.direction;
    const result = this.props.stores.filter(
      (store) => store.direction === direction && store.airportid === airportId
    );
    this.setState({
      storelist: result,
    });
    window.addEventListener("beforeunload", this.onUnload);

    this.props.getProductService({
      airportid: airportId,
      direction: direction,
    });

    let totalStoresContainerWidth = this.navRef.current.offsetWidth;
    let countStoresCardWidth = result.length * STORE_CARD_WIDTH;

    if (totalStoresContainerWidth > countStoresCardWidth) {
      this.setState({
        arrowShow: false,
      });
    }
  };

  _goToProductDetail = (product) => {
    const storeid = product.vendorid;
    const storename = product.vendorname;
    const stores = this.props.stores.find((store) => store.storeid === storeid);

    let category = {};
    if (stores) {
      category = stores.categories.find(
        (category) => category.categoryid === product.categoryid
      );
    } else {
      category = {};
    }

    this.props.history.push("/product/detail", {
      product: product,
      storeid: storeid,
      storename: storename,
      category: category,
    });
  };
  _addToCart = (product) => {
    if (product.siblings) {
      const storeid = product.vendorid;
      const storename = product.vendorname;
      const stores = this.props.stores.find(
        (store) => store.storeid === storeid
      );

      let category = {};
      if (stores) {
        category = stores.categories.find(
          (category) => category.categoryid === product.categoryid
        );
      } else {
        category = {};
      }

      this.props.history.push("/product/detail", {
        product: product,
        storeid: storeid,
        storename: storename,
        category: category,
      });
    } else {
      this.props.addToCart({ ...product, quantity: 1 });
    }
    this.setState({
      displayToast: true,
      toastMessage: `${product.productname} added to your cart.`,
    });
    this.setState({ flag: 1 });
  };

  onBtnClick = (storeid) => {
    this.props.callStoresWebService();
    this.setState({
      storeid: storeid,
    });
  };

  static getDerivedStateFromProps(props, state) {
    //  walkin page redirect after store success

    if (
      props.storeStatus !== state.instance.props.storeStatus &&
      state.instance.props.storeStatus !== null
    ) {
      if (props.storeStatus === "success") {
        // this.props.history.push("/categories");
        state.instance.props.history.push("/store/categories", {
          storeid: state.storeid.storeid,
        });
      }
    }

    // walkin page redirect after store success

    if (
      props.storeError !== state.instance.props.storeError &&
      state.instance.props.storeError !== null
    ) {
      return {
        displayDialog: true,
        dialogTitle: "Oops!",
        dialogBody: props.storeError,
        dialogFooter: (
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={state.instance.closeDialog}
          >
            Close
          </button>
        ),
      };
    }

    if (props.productsStatus === "success") {
      let returnData = {
        filterProducts: props.products,
      };

      if (state.instance.state.sortBy === state.sortBy) {
        returnData = { ...returnData, products: props.products };
      }

      return returnData;
    }
  }

  closeDialog = () => {
    this.setState({
      displayDialog: false,
    });
  };

  onProductBtnClick = (product) => {
    this.props.history.push("/product/detail", {
      product: product,
      storeid: product.vendorid,
      storename: product.vendorname,
    });
  };

  goToCartCheck = (productid) => {
    const cart = this.props.cartItems.filter(
      (product) => product.productid === productid
    ).length;
    return cart;
  };

  bannerFilter = () => {
    let banner = this.props.detailsBanners.filter(
      (banner) =>
        banner.airportid === this.props.flightData.airportid &&
        banner.direction === this.props.flightData.direction
    );

    banner.sort(function (a, b) {
      if (a.sequenceid < b.sequenceid) {
        return -1;
      }
      if (a.sequenceid > b.sequenceid) {
        return 1;
      }
    });

    return banner;
  };

  resetSelection = () => {
    this.setState({
      selectedProduct: [],
    });
  };

  bannerRender = (banner, index) => {
    if (banner.bannertype === "VENDOR") {
      return (
        <Carousel.Item
          key={index}
          onClick={() => this.onBtnClick({ storeid: banner.linkid })}
        >
          <Image
            src={APPLICATION_CONST.bannerImages + banner.bannerid}
            fluid
            style={styles.storeBannerImage}
          />
        </Carousel.Item>
      );
    } else if (banner.bannertype === "PRODUCT") {
      return (
        <Carousel.Item key={index} onClick={() => this.goToProduuct(banner)}>
          <Image
            src={APPLICATION_CONST.bannerImages + banner.bannerid}
            fluid
            style={styles.storeBannerImage}
          />
        </Carousel.Item>
      );
    } else if (banner.bannertype === "URL") {
      return (
        <Carousel.Item key={index} onClick={() => this.goToUrllink(banner)}>
          <Image
            src={APPLICATION_CONST.bannerImages + banner.bannerid}
            fluid
            style={styles.storeBannerImage}
          />
        </Carousel.Item>
      );
    } else {
      return (
        <Carousel.Item key={index}>
          <Image
            src={APPLICATION_CONST.bannerImages + banner.bannerid}
            fluid
            style={styles.storeBannerImage}
          />
        </Carousel.Item>
      );
    }
  };

  goToProduuct = (banner) => {
    let productid = banner.linkid;
    let findProductDetails = {};

    this.props.stores.forEach((store, index) => {
      store.categories.forEach((category, index) => {
        category.products.forEach((product, index) => {
          if (product.productid === productid) {
            findProductDetails = {
              store: store,
              product: product,
              category: category,
            };
            return false;
          }
        });
      });
    });

    if (Object.keys(findProductDetails).length !== 0) {
      let storeid = findProductDetails.store.storeid;
      let storename = findProductDetails.store.name;
      let category = findProductDetails.category;
      let product = findProductDetails.product;

      this.props.history.push("/product/detail", {
        product: product,
        storeid: storeid,
        storename: storename,
        category: category,
      });
    }
  };

  goToUrllink = (banner) => {
    let url = banner.linkid;

    this.props.history.push("/BannerPage", {
      url: url,
    });
  };

  brandRender = (product, index) => {
    return (
      <>
        <input
          type="checkbox"
          value={product.productname}
          checked={
            this.state.selectedProduct.indexOf(product.productname) !== -1
          }
          id={"chk_" + index}
          className={"sidebar-filter-chk"}
          onChange={(e) => this.changeProductFilter(e)}
        />
        <label for={"chk_" + index} className={"brand-tag"} key={index}>
          {product.productname}
        </label>
      </>
    );
  };

  changeProductFilter = ({ target }) => {
    let productname = target.value;

    if (target.checked) {
      this.setState({
        selectedProduct: [...this.state.selectedProduct, productname],
        offset: 0,
        perPage: 10,
        currentPage: 0,
      });
    } else {
      let products = this.state.selectedProduct.filter(
        (product) => product !== productname
      );

      this.setState({
        selectedProduct: products,
        currentPage: 0,
        offset: 0,
        perPage: 10,
      });
    }
  };

  getMinPrice = () => {
    const products = this.filterProducts();
    if (products.length > 0) {
      return products.reduce(
        (min, product) => (product.unitcost < min ? product.unitcost : min),
        products[0].unitcost
      );
    } else {
      return 0;
    }
  };
  getMaxPrice = () => {
    const products = this.filterProducts();
    if (products.length > 0) {
      return products.reduce(
        (max, product) => (product.unitcost > max ? product.unitcost : max),
        products[0].unitcost
      );
    } else {
      return 1000;
    }
  };

  productFilter() {
    let product = this.state.filterProducts;
    let products = [...product];
    let sortBy = this.state.sortBy;

    if (sortBy === "product_asc") {
      products.sort(function (a, b) {
        if (a.productname < b.productname) {
          return -1;
        }
        if (a.productname > b.productname) {
          return 1;
        }
      });
    }

    if (sortBy === "product_desc") {
      products.sort(function (a, b) {
        if (a.productname > b.productname) {
          return -1;
        }
        if (a.productname < b.productname) {
          return 1;
        }
      });
    }

    if (sortBy === "price_asc") {
      products.sort(function (a, b) {
        if (a.unitcost < b.unitcost) {
          return -1;
        }
        if (a.unitcost > b.unitcost) {
          return 1;
        }
      });
    }

    if (sortBy === "price_desc") {
      products.sort(function (a, b) {
        if (a.unitcost > b.unitcost) {
          return -1;
        }
        if (a.unitcost < b.unitcost) {
          return 1;
        }
      });
    }

    if (this.state.selectedProduct.length > 0) {
      products = products.filter(
        (product) =>
          this.state.selectedProduct.indexOf(product.productname) !== -1
      );
    }

    if (this.state.value.min != null && this.state.value.max != null) {
      products = products.filter(
        (product) =>
          this.state.value.min <= product.unitcost &&
          product.unitcost <= this.state.value.max
      );
    }

    if (this.state.searchTag != "") {
      products = products.filter(
        (product) =>
          product.productname
            .toLowerCase()
            .indexOf(this.state.searchTag.toLowerCase()) !== -1
      );
    }

    let slice = products.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );

    let data = {
      slice: slice,
      products: products,
    };

    return data;
  }
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.productFilter();
      }
    );
  };

  filterProducts = () => {
    let products = this.props.products;

    products.sort(function (a, b) {
      if (a.productname < b.productname) {
        return -1;
      }
      if (a.productname > b.productname) {
        return 1;
      }
    });

    return products;
  };

  Menu = (stores, selected) => {
    return stores.map((store, index) => (
      <>
        <div
          className="col-md-12 col-sm-12 col-xs-12 stores-card-outer"
          key={index}
          // className={'scroll-menu-arrow--disabled'}
          // onClick={() => this.onBtnClick(store)}
        >
          <div className="card" style={styles.productCardStyle}>
            <Image
              src={
                APPLICATION_CONST.storeImageUrl +
                store.vendorreferenceid +
                ".jpg"
              }
              alt="Airport"
              className={"stores-image"}
            />
            <div className="card-body" className={"stores-card-body"}>
              <div className={"stores-name"}>{store.name}</div>
              <div className={"stores-description"}>{store.description}</div>

              <Button
                style={styles.viewCategoryButton}
                onClick={() => this.onBtnClick({ storeid: store.storeid })}
              >
                View Categories
              </Button>
            </div>
          </div>
        </div>
      </>
    ));
  };

  handleNav = (direction) => {
    if (direction === "left") {
      return this.navRef ? (this.navRef.current.scrollLeft -= 700) : null;
    } else {
      return this.navRef ? (this.navRef.current.scrollLeft += 700) : null;
    }
  };
  

  render() {
     const airportId = this.props.flightData.airportid;
    const direction = this.props.flightData.direction;
    const stores = this.props.stores.filter(
      (store) => store.direction === direction && store.airportid === airportId
    );

    // if(stores.length === 1)
    // { 
    //   const storeId = stores[0].storeid; 
    //   console.log(storeId)
    //   this.onBtnClick({ storeid: storeId })
    //   console.log('One Vendor Found!');
    // }
    console.log('PROPS',stores.length);
    return (
      <>
        <Loader message="Processing..." />

        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />

        <NavBar />

        <Toast
          onClose={() => this.setState({ displayToast: false })}
          show={this.state.displayToast}
          delay={2000}
          autohide
          className={"toast"}
        >
          <Toast.Body>
            <span className={"toast-message "}> {this.state.toastMessage}</span>
          </Toast.Body>
        </Toast>
        <Container className={"back-bar-container"} fluid>
          <Row className={"back-bar"}>
            <Col lg="12" md="12">
              <a
                className={"back-link"}
                onClick={() => this.props.history.push("/")}
              >
                <FontAwesomeIcon
                  icon="chevron-left"
                  color="#777"
                  className={"back-icon"}
                />
                Back
              </a>
            </Col>
          </Row>
        </Container>

        {this.bannerFilter().length > 0 ? (
          <Container fluid className={"carousel-container"}>
            <Carousel>
              {this.bannerFilter().map((banner, index) =>
                this.bannerRender(banner, index)
              )}
            </Carousel>
          </Container>
        ) : null}

        <div className="container-fluid stores-container stores-slider-container">
          <div
            className="menu-container"
            id="menu-container"
            ref={(el) => (this.container = el)}
          >
            <span
              onClick={() => this.handleNav("left")}
              className={
                "previousButton slider-btn" +
                (this.state.arrowShow ? "" : " hide")
              }
            >
              <FontAwesomeIcon icon="chevron-left" color="#000" />
            </span>
            <span
              onClick={() => this.handleNav("right")}
              className={
                "nextButton slider-btn" + (this.state.arrowShow ? "" : " hide")
              }
            >
              <FontAwesomeIcon icon="chevron-right" color="#000" />
            </span>
            <div className={"nav-items"} ref={this.navRef}>
              {this.state.storelist.length > 0 ? (
                this.state.storelist.map((store, index) => (
                  <>
                    <div
                      style={{
                        width: STORE_CARD_WIDTH,
                        display: "inline-block",
                        marginRight: 25,
                      }}
                      className={"stores-card-outer"}
                      key={index}
                    >
                      <div className="card" style={styles.productCardStyle}>
                        <Image
                          src={
                            APPLICATION_CONST.storeImageUrl +
                            store.vendorreferenceid +
                            ".jpg"
                          }
                          alt="Airport"
                          className={"stores-image"}
                        />
                        <div
                          className="card-body"
                          className={"stores-card-body"}
                        >
                          <div className={"stores-name"}>{store.name}</div>
                          <div className={"stores-description"}>
                            {store.description}
                          </div>

                          <Button
                            style={styles.viewCategoryButton}
                            onClick={() =>
                              this.onBtnClick({ storeid: store.storeid })
                            }
                          >
                            View Categories
                          </Button>
                        </div>
                      </div>
                    </div>
                  </>
                ))
              ) : (
                null
                // <div className={"not-found"}>
                //   No Category found for selected store!!
                // </div>
              )}
            </div>
          </div>

          {this.filterProducts().length > 0 ? (
            <Container fluid className={"main-container-products"}>
              <div className="row">
                <section className="col-md-2 products-filter-sidebar">
                  <section>
                    <h5>PRICE</h5>
                    <div className={"price-range-container"}>
                      <InputRange
                        maxValue={this.getMaxPrice()}
                        minValue={this.getMinPrice()}
                        value={
                          this.state.value.min != null ||
                          this.state.value.max != null
                            ? {
                                min: this.state.value.min,
                                max: this.state.value.max,
                              }
                            : {
                                min: this.getMinPrice(),
                                max: this.getMaxPrice(),
                              }
                        }
                        onChange={(value) => {
                          this.setState({ value });
                        }}
                        formatLabel={(value) => "$" + value}
                      />
                    </div>
                  </section>
                  <section className={"product-search-container"}>
                    <h5>BRAND</h5>

                    {this.state.selectedProduct.length > 0 ? (
                      <a
                        href="javascript:void(0);"
                        onClick={() => this.resetSelection()}
                        className={"clear-selecion "}
                      >
                        Clear Selection
                      </a>
                    ) : null}

                    <div>
                      <input
                        onChange={(e) =>
                          this.setState({
                            searchTag: e.target.value,
                            offset: 0,
                            perPage: 10,
                            currentPage: 0,
                          })
                        }
                        type="text"
                        value={this.state.searchTag}
                        className="form-control"
                        placeholder="Search (Lindt, Rum..)"
                        disabled={this.state.edit}
                      />
                    </div>
                    <div className="brands-container">
                      <div>
                        {this.filterProducts().map((product, index) =>
                          index <= this.state.max_brand_show
                            ? this.brandRender(product, index)
                            : null
                        )}
                      </div>

                      {this.state.max_brand_show === MAX_BRAND_SHOW &&
                      this.state.max_brand_show <
                        this.filterProducts().length ? (
                        <a
                          href="javascript:void(0);"
                          onClick={() =>
                            this.setState({ max_brand_show: 50000 })
                          }
                        >
                          Show More >>
                        </a>
                      ) : null}

                      {this.state.max_brand_show > MAX_BRAND_SHOW &&
                      this.state.max_brand_show >
                        this.filterProducts().length ? (
                        <a
                          href="javascript:void(0);"
                          onClick={() => {
                            this.setState({ max_brand_show: MAX_BRAND_SHOW });
                          }}
                        >
                          Show Less >>
                        </a>
                      ) : null}
                    </div>
                  </section>
                </section>

                {this.filterProducts().length > 0 ? (
                  <>
                    <section className="col-md-10">
                      <div className="row">
                        <div className="col-md-6"></div>
                        <div className="col-md-6">
                          <div className=" pull-right">
                            <label className={"bold"}>Show&nbsp;</label>
                            <select
                              className=""
                              value={this.state.perPage}
                              onChange={(e) => {
                                this.setState({
                                  perPage: e.target.value,
                                  offset: 0,
                                  currentPage: 0,
                                });
                              }}
                            >
                              {PRODUCT_SHOW.map((show, index) => (
                                <option value={show.count} key={index}>
                                  {show.count}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div
                            className=" pull-right"
                            style={{ marginRight: 15 }}
                          >
                            <label className={"bold"}>Sort By&nbsp;</label>
                            <SortBy
                              handleChange={(value) => {
                                this.setState({
                                  sortBy: value,
                                  offset: 0,
                                  perPage: 10,
                                  currentPage: 0,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row products-container">
                        {this.productFilter().slice.map((product, index) => (
                          <div
                            className="col-md-3 col-sm-3 col-xs-6 product-card-outer"
                            key={index}
                            title={product.description}
                          >
                            {" "}
                            {product.stickerid != "" ? (
                              <img alt=""
                                src={
                                  APPLICATION_CONST.stickerImages +
                                  product.stickerid
                                }
                                className={"product-sticker"}
                              />
                            ) : null}
                            <Card style={{ ...styles.productCardStyle }}>
                              <Card.Img
                                onClick={() => this._goToProductDetail(product)}
                                variant="top"
                                className={"product-card-image"}
                                src={
                                  APPLICATION_CONST.productImageUrl +
                                  product.productreferenceid +
                                  ".png" +
                                  "?" +
                                  new Date()
                                }
                              />
                              <Card.Body className={"product-card-body"}>
                                <Card.Title
                                  className="productNameCaption"
                                  onClick={() =>
                                    this._goToProductDetail(product)
                                  }
                                >
                                  {product.productname}
                                </Card.Title>
                                <Card.Text>
                                  {product.msrp > product.unitcost ? (
                                    <>
                                      <div className="productCostText">
                                        <strike>USD $ {product.msrp}</strike>
                                      </div>
                                      <div className="productCostText">
                                        USD $ {product.unitcost}
                                      </div>
                                    </>
                                  ) : (
                                    <div className="productCostText">
                                      USD ${product.unitcost}
                                    </div>
                                  )}
                                </Card.Text>
                              </Card.Body>
                              {this.goToCartCheck(product.productid) > 0 ? (
                                <button
                                  onClick={() =>
                                    this.props.history.push("/cart")
                                  }
                                  className={"goToCartButton"}
                                >
                                  Go to Cart
                                </button>
                              ) : (
                                <button
                                  onClick={() => this._addToCart(product)}
                                  className={"addToCartButton"}
                                >
                                  Add to Cart&nbsp;&nbsp;
                                  <FontAwesomeIcon
                                    icon="shopping-cart"
                                    color="#fff"
                                    size="lg"
                                  />
                                </button>
                              )}
                            </Card>
                          </div>
                        ))}
                      </div>
                      <div className="row products-pagination-container">
                        <div className="col-md-12">
                          <ReactPaginate
                            currentPage={this.state.currentPage}
                            previousLabel={"Prev"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={Math.ceil(
                              this.productFilter().products.length /
                                this.state.perPage
                            )}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                            forcePage={this.state.currentPage}
                          />
                        </div>
                      </div>
                    </section>
                  </>
                ) : null}
              </div>
            </Container>
          ) : (
            <Container fluid className={"main-container-products"}>
              <div className="row">
                <div className="col-md-12" style={styles.errorContainer}>
                  <h5>{this.props.productError}</h5>
                </div>
              </div>
            </Container>
          )}
          <div className="spacer"></div>
        </div>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  flightData: state.FlightInfoReducer.formData,
  stores: state.StoreReducer.stores,
  logindetails: state.SignInReducer.logindetails,
  storeError: state.StoreReducer.error,
  storeStatus: state.StoreReducer.status,
  products: state.VendorProductReducer.products,
  productsStatus: state.VendorProductReducer.success,
  cartItems: state.CartReducer.cart,
  detailsBanners: state.SignInReducer.logindetails.banners.details,
  productError: state.VendorProductReducer.error,
});
export default connect(mapStateToProps, {
  callStoresWebService,
  getProductService,
  addToCart,
})(Stores);
