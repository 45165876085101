import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "../common/navbar";
import Loader from "../common/loader";
import DialogModal from "../common/dialogModal";
import { updateProductService } from "../../actions/vendor/productAction";
import Footer from "../common/footer";
import { styles } from "../../styles/commontStyles";
import {
  productEditWebService,
  getClassType,
  getClassification,
} from "../../actions/vendor/productAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Col, Row } from "react-bootstrap";
import { Multiselect } from "multiselect-react-dropdown";
class AddProduct extends Component {
  constructor(props) {
    super(props);

    if(this.props.logindetails.userdefault.supervisor === 'N'){
      this.props.history.push('/');
    }
    
    this.state = {
      product: {
        productid: "",
        productname: "",
        categoryid: "",
        unitcost: "",
        taxexempt: "N",
        noticequantity: "",
        description: "",
        parent: "N",
        parentid: "",
        classtype1: "",
        classtype2: "",
        classid1: "",
        classid2: "",
        status: "A",
        vendors: [],
        usedefaultrate:"Y",
        commissionrate:""
      },
      productErr: {
        productname: "",
        categoryid: "",
        unitcost: "",
        quantity: "",
        description: "",
      },
      actiontype: "CREATE",
      displayDialog: false,
      dialogTitle: null,
      dialogBody: null,
      dialogFooter: null,
      vendorAssignmentList: [],
      filteredVendorList: [],
      parentproductlists: [],
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);

    if (this.props.location.state) {
      const result = this.props.products.find(
        (product) => product.productid === this.props.location.state.productid
      );
      if (result) {
        this.setState({
          product: result,
          actiontype: "UPDATE",
        });

        this.setVendors(result.vendors);
      }
    } else if (this.props.location.pathname === "/products/edit") {
      this.props.history.push("/");
    }

    if(!this.props?.location?.state?.productid) {
      const loginDetail = JSON.parse(localStorage.getItem("loginDetail"));

      const applicableRates = loginDetail?.rates.find(    
        (item) => item?.defaultrate === "Y"
      );

      this.setState({
        product: {
          ...this.state.product,
          usedefaultrate:applicableRates?.defaultrate,
          defaultcommissionrate:applicableRates?.commissionrate,
        }
      });
    }

    this.setState({ filteredVendorList: this.props.logindetails.stores });

    let users = [];

    console.log("stores", this.props.logindetails.stores);
    this.props.logindetails.stores.map((user, index) => {

      console.log('user',user);
      let direction = '';
      if(user.direction === 'A')
      {
        direction = 'Arrival';
      }
      else if(user.direction === 'D')
      {
        direction = 'Departure';

      }
      users.push({
        value: user.storeid,
        label: user.name+' ('+direction+')',
        storeid: user.storeid,
      });
    });

    console.log("usersusers", users);
    this.setState({
      users: users,
    });

    this.props.getClassType({});
    this.props.getClassification({});

    let parentproductlists = [];

    this.props.products.map((product, index) =>
      product.parent === "Y" && product.status === "A"
        ? parentproductlists.push({
            value: product.productid,
            label: product.productname,
          })
        : null
    );

    this.setState({
      parentproductlists: parentproductlists,
    });
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.updateProductError !== this.props.updateProductError &&
      nextProps.updateProductError !== null
    ) {
      this.setState({
        displayDialog: true,
        dialogTitle: "Oops!",
        dialogBody: nextProps.updateProductError,
        dialogFooter: (
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={this.closeDialog}
          >
            Close
          </button>
        ),
      });
    }

    if (
      nextProps.updateProductSuccess &&
      nextProps.updateProductSuccess !== this.props.updateProductSuccess
    ) {
      this.setState({
        displayDialog: true,
        dialogTitle: "Success!",
        dialogBody: `Product ${
          this.state.actiontype === "CREATE" ? "added" : "updated"
        } successfully!`,
        dialogFooter: (
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={() => {
              this.closeDialog();
              this.props.history.push("/products");
            }}
          >
            Close
          </button>
        ),
      });
    }
  }

  closeDialog = () => {
    this.setState({
      displayDialog: false,
    });
  };

  resetError = () => {
    let productErr = {
      productname: "",
      categoryid: "",
      unitcost: "",
      quantity: "",
    };
    this.setState({ productErr: productErr });
  };

  filterClassificationList = (classid, list) => {
    if (classid === "classid1") {
      return list.filter(
        (classification) =>
          classification.classtypeid === this.state.product.classtype1
      );
    } else if (classid === "classid2") {
      return list.filter(
        (classification) =>
          classification.classtypeid === this.state.product.classtype2
      );
    }
  };

  onChangeAssignTo = (value) => {
    this.setState({
      vendorAssignmentList: value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const history = this.props.history;
    const productname = this.state.product.productname;
    const categoryid = this.state.product.categoryid;
    const unitcost = this.state.product.unitcost;
    const quantity = this.state.product.quantity;
    const description = this.state.product.description;

    const regex = /^\d+(\.\d{1,2})?$/;
    let productErr = {
      productname: null,
      categoryid: null,
      unitcost: null,
      quantity: null,
      description: null,
      parentid: null,
      noticequantity: null,
      classtype1: null,
      classtype2: null,
      classid1: null,
      classid2: null,
    };

    let props = this.state;
    if (props.product.productname.trim() === "") {
      productErr.productname = "Product Name is required";
      this.setState({ productErr: productErr });
    }
    if (
      props.product.categoryid === null ||
      props.product.categoryid === undefined ||
      props.product.categoryid === "unselectable" ||
      props.product.categoryid === ""
    ) {
      productErr.categoryid = "Category is Required";
      this.setState({ productErr: productErr });
    }
    if (props.product.unitcost === "") {
      productErr.unitcost = "Unit cost is Required";
      this.setState({ productErr: productErr });
    }
    if (
      props.product.parent === "N" &&
      props.product.parentid === "" &&
      props.product.unitcost === ""
    ) {
      productErr.unitcost = "Unit Cost is Required";
      this.setState({ productErr: productErr });
    }
    if (
      props.product.parent === "N" &&
      props.product.parentid === "" &&
      Number(props.product.unitcost) === 0 &&
      props.product.unitcost !== ""
    ) {
      productErr.unitcost = "Unit Cost cannot be 0";
      this.setState({ productErr: productErr });
    }

    if (props.product.noticequantity === "") {
      productErr.noticequantity = "Noticed Quantity is Required";
      this.setState({ productErr: productErr });
    }
    if (props.product.description.trim() === "") {
      productErr.description = "Description is Required";
      this.setState({ productErr: productErr });
    }
    if (props.product.parent === "Y" && props.product.classtype1 === "") {
      productErr.classtype1 = "Parent Product Must Have at Least 1 Class Type";
      this.setState({ productErr: productErr });
    }
    if (
      props.product.parent === "N" &&
      props.product.parentid !== "" &&
      props.product.classid1 === "" &&
      props.product.classid2 === ""
    ) {
      productErr.classid1 = "Product Must Have at Least 1 Classification";
      productErr.classid2 = "Product Must Have at Least 1 Classification";
      this.setState({ productErr: productErr });
    } else {
      var temparray = [];
      for (var i = 0; i < this.state.vendorAssignmentList.length; i++) {
        temparray.push(this.state.vendorAssignmentList[i].storeid);
      }

      // const data = {
      //   product: { ...this.state.product, vendors: temparray },
      //   edit: this.props.location.state ? true : false,
      //   productmaster: true,
      // };
      // this.props.productEditWebService(data, this.props.history);
    }

    this.setState({ productErr: productErr });

    if (
      // productname.trim() &&
      // categoryid &&
      // unitcost &&
      // regex.test(unitcost) &&
      // quantity &&
      // description.trim()

      productErr.productname === null &&
      productErr.categoryid === null &&
      productErr.unitcost === null &&
      productErr.quantity === null &&
      productErr.description === null &&
      productErr.parentid === null &&
      productErr.noticequantity === null &&
      productErr.classtype1 === null &&
      productErr.classtype2 === null &&
      productErr.classid1 === null &&
      productErr.classid2 === null
    ) {
      // const data = {
      //   username: this.props.logindetails.username,
      //   sessionid: this.props.logindetails.sessionid,
      //   product: this.state.product,
      //   actiontype: this.state.actiontype,
      // };
      // this.props.updateProductService(data, history);
      
     

      this.setState({
        product: {
          ...this.state.product,
           usedefaultrate:this.state.product.usedefaultrate,
           commissionrate:this.state.product.usedefaultrate === "Y" ? -1 : this.state.product.commissionrate
        },
      });

      const data = {
        product: { ...this.state.product, vendors: temparray },
        edit: this.props.location.state ? true : false,
        productmaster: true,
      };
      this.props.productEditWebService(data, this.props.history);
    }
  };

  categoryChange = (e) => {
    this.setState({
      product: { ...this.state.product, categoryid: e.target.value },
    });
  };

  parentProductListFilter = (products) => {
    return products.filter(
      (product) => product.parent === "Y" && product.status === "A"
    );
  };

  onParentProductChange = (value) => {
    if (value !== null) {
      const result = this.props.products.find(
        (product) => product.productid === value.value
      );

      this.setState({
        product: {
          ...this.state.product,
          parentid: value.value,
          categoryid: result.categoryid,
          noticequantity: result.noticequantity,
          classtype1: result.classtype1,
          classtype2: result.classtype2,
          classid1: result.classid1,
          classid2: result.classid2,
        },
        vendorAssignmentList: [],
      });
    } else {
      this.setState({
        product: {
          ...this.state.product,
          parentid: "",
          categoryid: "",
          noticequantity: "",
          classtype1: "",
          classtype2: "",
          classid1: "",
          classid2: "",
        },
        vendorAssignmentList: [],
      });
    }
  };

  onParentProductChange1 = (event) => {
    if (event.target.value) {
      const result = this.props.products.find(
        (product) => product.productid === event.target.value
      );

      this.setState({
        product: {
          ...this.state.product,
          parentid: event.target.value,
          categoryid: result.categoryid,
          noticequantity: result.noticequantity,
          unitcost: 0,
          classtype1: result.classtype1,
          classtype2: result.classtype2,
          classid1: result.classid1,
          classid2: result.classid2,
          taxexempt: result.taxexempt,
          usedefaultrate:result?.usedefaultrate,
          defaultcommissionrate:(result?.commissionrate < 0 ? result?.defaultcommissionrate : result?.commissionrate),
        },
        vendorAssignmentList: [],
      });
    } else {
      this.setState({
        product: {
          ...this.state.product,
          parentid: "",
          categoryid: "",
          noticequantity: "",
          classtype1: "",
          classtype2: "",
          classid1: "",
          classid2: "",
          unitcost: "",
          taxexempt: "N",
        },
        vendorAssignmentList: [],
      });
    }
  };

  classType1Change = (event) => {
    this.setState({
      product: {
        ...this.state.product,
        classtype1: event.target.value,
      },
    });
  };

  classType2Change = (event) => {
    this.setState({
      product: {
        ...this.state.product,
        classtype2: event.target.value,
      },
    });
  };

  classId1Change = (event) => {
    this.setState({
      product: {
        ...this.state.product,
        classid1: event.target.value,
      },
    });
  };

  classId2Change = (event) => {
    this.setState({
      product: {
        ...this.state.product,
        classid2: event.target.value,
      },
    });
  };

  statusChange = (value) => {
    this.setState({
      product: {
        ...this.state.product,
        status: value,
      },
    });
  };

  recommendedChange = (value) => {
    this.setState({
      product: {
        ...this.state.product,
        recommended: value,
      },
    });
  };

  setVendors = (vendors) => {
    if (vendors) {
      var temparray = [...this.state.vendorAssignmentList];
      console.log("vendors");
      for (var i = 0; i < vendors.length; i++) {

        let direction = '';
        if(vendors[i].direction === 'A')
        {
          direction = 'Arrival';
        }
        else if(vendors[i].direction === 'D')
        {
          direction = 'Departure';
  
        }

        temparray.push({
          storeid: vendors[i].storeid,
          value: vendors[i].storeid,
          label: vendors[i].name+' ('+direction+')',
        });
      }

      this.setState({
        vendorAssignmentList: temparray,
      });
    }
  };

  isParent = () => {
    this.setState({
      product: {
        ...this.state.product,
        parent: this.state.product.parent === "Y" ? "N" : "Y",
        unitcost: this.state.product.parent === "N" ? 0 : "",
        noticequantity: this.state.product.parent === "N" ? 0 : "",
        recommended: "N",
      },
    });
  };

  render() {
    return (
      <>
        <NavBar />
        <Loader message="Processing" />
        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />

        <Container className={"back-bar-container"} fluid>
          <Row className={"categoryBar back-bar"}>
            <Col lg="12" md="12">
              <a
                className={"back-link"}
                onClick={() => this.props.history.goBack()}
              >
                <FontAwesomeIcon
                  icon="chevron-left"
                  color="#777"
                  className={"back-icon "}
                />
                Back
              </a>
            </Col>
          </Row>
        </Container>

        <div className="container-fluid stores-container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <form onSubmit={this.handleSubmit}>
                <div className="card">
                  <div className={"card-header custom-card-header"}>
                    <h4>
                      {this.state.actiontype === "CREATE"
                        ? "Add Master Product"
                        : "Edit Master Product"}
                    </h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label
                              htmlFor="productname"
                              style={styles.inputLabelStyle}
                              className="required"
                            >
                              Product Name
                            </label>
                          </div>
                          <div className="col-md-8">
                            <input
                              id="productname"
                              onChange={(e) =>
                                this.setState({
                                  product: {
                                    ...this.state.product,
                                    productname: e.target.value,
                                  },
                                })
                              }
                              onFocus={this.resetError}
                              type="text"
                              value={this.state.product.productname}
                              className="form-control"
                              autoFocus={true}
                              placeholder="Enter Product Name"
                            />
                            {this.state.productErr.productname && (
                              <small className="text-danger">
                                {this.state.productErr.productname}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label
                              htmlFor="category"
                              style={styles.inputLabelStyle}
                              className="required"
                            >
                              Category
                            </label>
                          </div>
                          <div className="col-md-8">
                            <select
                              id="category"
                              className="form-control"
                              onChange={this.categoryChange}
                              onFocus={this.resetError}
                              value={this.state.product.categoryid}
                              disabled={
                                this.state.product.parentid === ""
                                  ? false
                                  : true
                              }
                            >
                              <option value="">--Select Category--</option>
                              {this.props.categories.map((category) => (
                                <option
                                  key={category.categoryid}
                                  value={category.categoryid}
                                >
                                  {category.description}
                                </option>
                              ))}
                            </select>
                            {this.state.productErr.categoryid && (
                              <small className="text-danger">
                                {this.state.productErr.categoryid}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label
                              htmlFor="vendorid"
                              style={styles.inputLabelStyle}
                            >
                              Parent
                            </label>
                          </div>
                          <div className="col-md-8">
                            <label className={"control-label"}>
                              <input
                                onChange={(e) => this.isParent()}
                                onFocus={this.resetError}
                                type="radio"
                                value={this.state.product.parent}
                                checked={
                                  this.state.product.parent === "Y"
                                    ? true
                                    : false
                                }
                                disabled={
                                  this.state.product.parentid !== ""
                                    ? true
                                    : false
                                }
                              />
                              &nbsp; Yes
                            </label>
                            &nbsp;&nbsp;&nbsp;
                            <label className="control-label">
                              <input
                                onChange={(e) => this.isParent()}
                                onFocus={this.resetError}
                                type="radio"
                                value={this.state.product.parent}
                                checked={
                                  this.state.product.parent === "N"
                                    ? true
                                    : false
                                }
                                disabled={
                                  this.state.product.parentid !== ""
                                    ? true
                                    : false
                                }
                              />
                              &nbsp; No
                            </label>
                            {this.state.productErr.parentid && (
                              <small className="text-danger">
                                {this.state.productErr.parentid}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label
                              htmlFor="parentproduct"
                              style={styles.inputLabelStyle}
                            >
                              Parent Product
                            </label>
                          </div>
                          <div className="col-md-8">
                            <select
                              id="parentproduct"
                              className="form-control"
                              onChange={this.onParentProductChange1}
                              onFocus={this.resetError}
                              value={this.state.product.parentid}
                              disabled={
                                this.state.product.parent === "Y" ? true : false
                              }
                            >
                              <option value="">
                                --Select Parent Product--
                              </option>
                              {this.parentProductListFilter(
                                this.props.products
                              ).map((category) => (
                                <option
                                  key={category.productid}
                                  value={category.productid}
                                >
                                  {category.productname}
                                </option>
                              ))}
                            </select>

                            {this.state.productErr.categoryid && (
                              <small className="text-danger">
                                {this.state.productErr.parentid}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label
                              htmlFor="unitcost"
                              style={styles.inputLabelStyle}
                              className="required"
                            >
                              Unit Cost
                            </label>
                          </div>
                          <div className="col-md-8">
                            <input
                              id="unitcost"
                              onChange={(e) =>
                                this.setState({
                                  product: {
                                    ...this.state.product,
                                    unitcost: e.target.value,
                                  },
                                })
                              }
                              onFocus={this.resetError}
                              type="number"
                              value={this.state.product.unitcost}
                              className="form-control"
                              // autoFocus={true}
                              placeholder="Enter Unit Cost"
                              disabled={
                                this.state.product.parent === "Y" ? true : false
                              }
                            />
                            {this.state.productErr.unitcost && (
                              <small className="text-danger">
                                {this.state.productErr.unitcost}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label style={styles.inputLabelStyle}>
                              Tax Exempt
                            </label>
                          </div>
                          <div className="col-md-8">
                            <label className="control-label">
                              {" "}
                              <input
                                onChange={(e) =>
                                  this.setState({
                                    product: {
                                      ...this.state.product,
                                      taxexempt:
                                        this.state.product.taxexempt === "Y"
                                          ? "N"
                                          : "Y",
                                    },
                                  })
                                }
                                onFocus={this.resetError}
                                type="radio"
                                value={this.state.product.taxexempt}
                                checked={
                                  this.state.product.taxexempt === "Y"
                                    ? true
                                    : false
                                }
                                // autoFocus={true}
                                disabled={
                                  this.state.product.parentid === ""
                                    ? false
                                    : true
                                }
                              />{" "}
                              Yes
                            </label>
                            &nbsp;&nbsp;&nbsp;{" "}
                            <label className="control-label">
                              {" "}
                              <input
                                onChange={(e) =>
                                  this.setState({
                                    product: {
                                      ...this.state.product,
                                      taxexempt:
                                        this.state.product.taxexempt === "N"
                                          ? "Y"
                                          : "N",
                                    },
                                  })
                                }
                                onFocus={this.resetError}
                                type="radio"
                                value={this.state.product.taxexempt}
                                checked={
                                  this.state.product.taxexempt === "N"
                                    ? true
                                    : false
                                }
                                // autoFocus={true}
                                disabled={
                                  this.state.product.parentid === ""
                                    ? false
                                    : true
                                }
                              />{" "}
                              No
                            </label>
                            {this.state.productErr.taxexempt && (
                              <small className="text-danger">
                                {this.state.productErr.taxexempt}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label
                              for={"noticedquantity"}
                              style={styles.inputLabelStyle}
                              className="required"
                            >
                              Noticed Quantity
                            </label>
                          </div>
                          <div className="col-md-8">
                            <input
                              onChange={(e) =>
                                this.setState({
                                  product: {
                                    ...this.state.product,
                                    noticequantity: e.target.value,
                                  },
                                })
                              }
                              id="noticedquantity"
                              onFocus={this.resetError}
                              type="number"
                              value={this.state.product.noticequantity}
                              className="form-control"
                              // autoFocus={true}
                              placeholder="Enter Noticed Quantity"
                              disabled={
                                this.state.product.parent === "Y" ? true : false
                              }
                            />
                            {this.state.productErr.noticequantity && (
                              <small className="text-danger">
                                {this.state.productErr.noticequantity}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label
                              for={"noticedquantity"}
                              style={styles.inputLabelStyle}
                            >
                              Assigned Vendor(s)
                            </label>
                          </div>
                          <div className="col-md-8">
                            {/*<Select*/}
                            {/*    style={{borderRadius: 0}}*/}
                            {/*    isMulti*/}
                            {/*    name="form-field-name"*/}
                            {/*    options={this.state.users}*/}
                            {/*    onChange={this.onChangeAssignTo}*/}
                            {/*    placeholder={"Assigned Vendors"}*/}
                            {/*    isClearable={true}*/}
                            {/*    onFocus={() =>*/}
                            {/*        this.setState({assigntoError: null})*/}
                            {/*    }*/}
                            {/*    value={this.state.vendorAssignmentList}*/}
                            {/*    isDisabled={*/}
                            {/*        this.state.product.parent === "N" &&*/}
                            {/*        this.state.product.parentid !== ""*/}
                            {/*            ? true*/}
                            {/*            : false*/}
                            {/*    }*/}
                            {/*/>*/}
                            <Multiselect
                              style={{ borderRadius: 0 }}
                              // isMulti
                              name="form-field-name"
                              options={this.state.users}
                              onSelect={this.onChangeAssignTo}
                              onRemove={this.onChangeAssignTo}
                              placeholder={"Assigned Vendor(s)"}
                              onFocus={() =>
                                this.setState({ assigntoError: null })
                              }
                              selectedValues={this.state.vendorAssignmentList}
                              disable={
                                this.state.product.parent === "N" ||
                                this.state.product.parentid !== ""
                                  ? true
                                  : false
                              }
                              displayValue="label"
                            />
                            {this.state.productErr.vendors && (
                              <small className="text-danger">
                                {this.state.productErr.vendors}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label
                              for={"classtype1"}
                              style={styles.inputLabelStyle}
                            >
                              Class Type 1
                            </label>
                          </div>
                          <div className="col-md-8">
                            <select
                              id="classtype1"
                              className="form-control"
                              onChange={this.classType1Change}
                              onFocus={this.resetError}
                              value={this.state.product.classtype1}
                              disabled={
                                this.state.product.parent === "Y" ? false : true
                              }
                            >
                              <option value="">--Select Class Type 1--</option>
                              {this.props.classtypeslist.map((category) => (
                                <option
                                  key={category.classtypeid}
                                  value={category.classtypeid}
                                >
                                  {category.description}
                                </option>
                              ))}
                            </select>
                            {this.state.productErr.classtype1 && (
                              <small className="text-danger">
                                {this.state.productErr.classtype1}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label
                              for={"classtype2"}
                              style={styles.inputLabelStyle}
                            >
                              Class Type 2
                            </label>
                          </div>
                          <div className="col-md-8">
                            <select
                              id="classtype2"
                              className="form-control"
                              onChange={this.classType2Change}
                              onFocus={this.resetError}
                              value={this.state.product.classtype2}
                              disabled={
                                this.state.product.classtype1 !== "" &&
                                this.state.product.parent === "Y"
                                  ? false
                                  : true
                              }
                            >
                              <option value="">--Select Class Type 2--</option>
                              {this.props.classtypeslist.map((category) => (
                                <option
                                  key={category.classtypeid}
                                  value={category.classtypeid}
                                >
                                  {category.description}
                                </option>
                              ))}
                            </select>
                            {this.state.productErr.classtype2 && (
                              <small className="text-danger">
                                {this.state.productErr.classtype2}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label
                              for={"classid1"}
                              style={styles.inputLabelStyle}
                            >
                              Class ID 1
                            </label>
                          </div>
                          <div className="col-md-8">
                            <select
                              id="classid1"
                              className="form-control"
                              onChange={this.classId1Change}
                              onFocus={this.resetError}
                              value={this.state.product.classid1}
                              disabled={
                                this.state.product.classtype1 !== "" &&
                                this.state.product.parent === "N"
                                  ? false
                                  : true
                              }
                            >
                              <option value="">--Select Class ID 1--</option>
                              {this.filterClassificationList(
                                "classid1",
                                this.props.classificationlist
                              ).map((category) => (
                                <option
                                  key={category.classid}
                                  value={category.classid}
                                >
                                  {category.description}
                                </option>
                              ))}
                            </select>
                            {this.state.productErr.classid1 && (
                              <small className="text-danger">
                                {this.state.productErr.classid1}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label
                              for={"classid2"}
                              style={styles.inputLabelStyle}
                            >
                              Class ID 2
                            </label>
                          </div>
                          <div className="col-md-8">
                            <select
                              id="classid2"
                              className="form-control"
                              onChange={this.classId2Change}
                              onFocus={this.resetError}
                              disabled={
                                this.state.product.classtype2 !== "" &&
                                this.state.product.parent === "N"
                                  ? false
                                  : true
                              }
                              value={this.state.product.classid2}
                            >
                              <option value="">--Select Class ID 2--</option>
                              {this.filterClassificationList(
                                "classid2",
                                this.props.classificationlist
                              ).map((category) => (
                                <option
                                  key={category.classid}
                                  value={category.classid}
                                >
                                  {category.description}
                                </option>
                              ))}
                            </select>
                            {this.state.productErr.classid2 && (
                              <small className="text-danger">
                                {this.state.productErr.classid2}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label style={styles.inputLabelStyle}>Status</label>
                          </div>
                          <div className="col-md-8">
                            <label className={"control-label"}>
                              <input
                                type="radio"
                                checked={
                                  this.state.product.status === "A"
                                    ? true
                                    : false
                                }
                                onClick={() => this.statusChange("A")}
                              />{" "}
                              Active
                            </label>
                            &nbsp;&nbsp;&nbsp;
                            <label className={"control-label"}>
                              <input
                                type="radio"
                                checked={
                                  this.state.product.status === "I"
                                    ? true
                                    : false
                                }
                                onClick={() => this.statusChange("I")}
                              />{" "}
                              Inactive
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label style={styles.inputLabelStyle}>
                              Recommended
                            </label>
                          </div>
                          <div className="col-md-8">
                            <label className={"control-label"}>
                              <input
                                disabled={
                                  this.state.product.parent === "Y"
                                    ? true
                                    : false
                                }
                                type="radio"
                                checked={
                                  this.state.product.recommended === "Y"
                                    ? true
                                    : false
                                }
                                onClick={() => this.recommendedChange("Y")}
                              />{" "}
                              Yes
                            </label>
                            &nbsp;&nbsp;&nbsp;
                            <label className={"control-label"}>
                              <input
                                disabled={
                                  this.state.product.parent === "Y"
                                    ? true
                                    : false
                                }
                                type="radio"
                                checked={
                                  this.state.product.recommended === "N"
                                    ? true
                                    : false
                                }
                                onClick={() => this.recommendedChange("N")}
                              />{" "}
                              No
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group row">
                          <div className="control-label col-md-2">
                            <label
                              style={styles.inputLabelStyle}
                              className="required"
                            >
                              Description
                            </label>
                          </div>
                          <div className="col-md-10">
                            <textarea
                              onChange={(e) =>
                                this.setState({
                                  product: {
                                    ...this.state.product,
                                    description: e.target.value,
                                  },
                                })
                              }
                              onFocus={this.resetError}
                              type="text"
                              value={this.state.product.description}
                              className="form-control"
                              // autoFocus={true}
                              placeholder="Enter Description"
                              // disabled={
                              //   this.state.product.parentid === ""
                              //     ? false
                              //     : true
                              // }
                            ></textarea>
                            {this.state.productErr.description && (
                              <small className="text-danger">
                                {this.state.productErr.description}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="from-group row">
                          <div className="control-label col-md-4">
                            <label
                              htmlFor="usedefaultrate"
                              style={styles.inputLabelStyle}
                              className={"required"}
                            >
                              Use Default Rate ({this.state.product.defaultcommissionrate})
                            </label>
                          </div>
                          <div className="col-md-8">
                            <label className="control-label">
                              <input
                                type="radio"
                                onChange={(e) =>
                                  this.setState({
                                    product: {
                                      ...this.state.product,
                                      usedefaultrate: "Y",
                                    },
                                  })
                                }
                                value="Y"
                                checked={this.state.product.usedefaultrate === "Y"}
                              />{" "}
                              Yes
                            </label>
                            &nbsp;&nbsp;&nbsp;
                            <label className="control-label">
                              <input
                                type="radio"
                                onChange={(e) =>
                                  this.setState({
                                    product: {
                                      ...this.state.product,
                                      usedefaultrate: "N",
                                    },
                                  })
                                }
                                value="N"
                                checked={this.state.product.usedefaultrate === "N"}
                              />{" "}
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    
                      <div className="col-md-6">
                        <div className="from-group row">
                          <div className="control-label col-md-4">
                            <label
                              htmlFor="commissionrate"
                              style={styles.inputLabelStyle}
                              className={"required"}
                            >
                              Commission Rate
                            </label>
                          </div>
                          <div className="col-md-8">                             
                            <input
                              type="number"
                              min="0"
                              max="1"
                              step="0.01"
                              id="commissionrate"
                              onChange={(e) =>
                                this.setState({
                                  product: {
                                    ...this.state.product,
                                    commissionrate: e.target.value,
                                  },
                                })
                              }
                              value={this.state.product.usedefaultrate === "Y" ? "" : (this.state.product.commissionrate == -1 ? "" : this.state.product.commissionrate)}
                              className="form-control"
                              placeholder="Enter Commission Rate"
                              disabled={this.state.product.usedefaultrate === "Y"}
                            />                          
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="col-md-12" style={{ padding: 0 }}>
                      {" "}
                      <button
                        type="submit"
                        className="btn pull-right submit-btn"
                        style={styles.confirmButtonStyle}
                      >
                        {this.state.actiontype === "CREATE" ? "Save" : "Update"}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="spacer"></div>
        </div>

        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  logindetails: state.SignInReducer.logindetails,
  products: state.VendorProductReducer.products,
  categories: state.VendorProductReducer.category,
  updateProductError: state.VendorProductReducer.updateProductError,
  updateProductSuccess: state.VendorProductReducer.updateProductSuccess,
  classtypeslist: state.VendorProductReducer.classtypeslist,
  classificationlist: state.VendorProductReducer.classificationlist,
});
export default connect(mapStateToProps, {
  updateProductService,
  getClassType,
  getClassification,
  productEditWebService,
})(AddProduct);
