import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import { styles } from "../../styles/commontStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { APPLICATION_CONST } from "../../Config/CustomConfig";

export default class ProcessButton extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        {" "}
        <button
          className="btn pull-right"
          style={styles.confirmButtonStyle}
          onClick={this.props.onClick}
        >
          {this.props.title}
        </button>
      </>
    );
  }
}
