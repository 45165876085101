import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "../common/navbar";
import Loader from "../common/loader";
import Footer from "../common/footer";
import DialogModal from "../common/dialogModal";
import { orderSubmit } from "../../actions/vendor/orderProcessAction";
import * as valid from "card-validator";
import Modal from "react-bootstrap/Modal";
import SignatureCanvas from "react-signature-canvas";
import { addSignature } from "../../actions/vendor/orderProcessAction";
import { styles } from "../../styles/commontStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Col, Row } from "react-bootstrap";
import { SIGNATURE_REQUIRED } from "../../Config/CustomConfig";
import { processTransaction } from "../../actions/common/OrderAction";
import { processCard } from "../../actions/common/ProcessCardAction";
import { loaderStatus } from "../../actions/vendor/loaderAction";

class Orderrefund extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardHolderName: "",
      cardNumber: "",
      expiryDate: "",
      cvv: "",
      cardtype: "",
      authorizationnumber: "",
      checked: false,
      toggle: false,
      isOpenSignature: false,
      paymentMethod: "card",
      currency: "USD",
      exchangerate: 1,
      currencycode: "USD",
      calculatedValue: parseFloat(this.props.location.state.chargeableAmount).toFixed(2),
      isOpen: false,
      errorMessage: {
        cardHolderName: "",
        cardNumber: "",
        expiryDate: "",
        cvv: "",
        cardtype: "",
        authorizationnumber: "",
      },
      displayDialog: false,
      dialogTitle: null,
      dialogBody: null,
      dialogFooter: null,
      appliedServiceFee: 0,
      appliedServiceFeeTax: 0,
    };
  }
  componentDidMount = () => {
    window.scrollTo(0,0);

    console.log('location props',this.props.location);

    const paymenttype = this.props.location.state.paymenttype;
    this.setState({
      order: this.props.location.state.order,
      paymentMethod: paymenttype,
    });
    this.calculateServiceFee_ServiceFeeTax();
  };

  setCurrency = (e) => {
    this.setState({
      exchangerate: e.target[e.target.selectedIndex].getAttribute(
        "data-exchangerate"
      ),
      currency: e.target.value,
      currencycode: e.target[e.target.selectedIndex].getAttribute(
        "data-currencycode"
      ),
    });
    if (!e.target.value) {
      this.setState({
        calculatedValue: "",
        exchangerate: "",
        currency: "",
      });
    } else {
      let calc_result = (
        parseFloat(this.props.location.state.chargeableAmount).toFixed(2) *
        e.target[e.target.selectedIndex].getAttribute("data-exchangerate")
      ).toFixed(2);
      this.setState({ calculatedValue: calc_result });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.orderProcessError !== this.props.orderProcessError &&
      nextProps.orderProcessError !== null
    ) {
      this.setState({
        displayDialog: true,
        dialogTitle: "Oops!",
        dialogBody: nextProps.orderProcessError,
        dialogFooter: (
          <button className="btn btn-info" onClick={this.closeDialog}>
            Close
          </button>
        ),
      });
    }

    if (
      nextProps.orderProcessSuccess &&
      nextProps.orderProcessSuccess !== this.props.orderProcessSuccess
    ) {
      this.setState({
        displayDialog: true,
        dialogTitle: "Success!",
        dialogBody: "Check Out Successfull.",
        dialogFooter: (
          <button
            className="btn btn-info"
            onClick={() => {
              this.closeDialog();
              this.props.history.push("/");
            }}
          >
            OK
          </button>
        ),
      });
    }
  }
  closeDialog = () => {
    this.setState({
      displayDialog: false,
    });
  };
  showModalCard = () => {
    // setIsOpen(true);
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));

    if(SIGNATURE_REQUIRED == true) {
      if (this.state.isOpenSignature === true) {
        this.setState((prevState) => ({
          isOpenSignature: !prevState.isOpenSignature,
        }));
      }
    }
  };
  showModalCash = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };
  hideModal = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };
  hideSignatureModal = () => {
    this.setState((prevState) => ({
      isOpenSignature: !prevState.isOpenSignature,
    }));
  };
  toggle = (event) => {
    this.setState((prevState) => ({
      toggle: !prevState.toggle,
    }));
  };
  paymentOption = (e) => {
    this.setState((prevState) => ({
      toggle: !prevState.toggle,
    }));
    // this.setState({
    //   currency: "",
    // });
    // this.setState({
    //   calculatedValue: "",
    // });
    this.setState({
      paymentMethod: e.target.value,
    });
  };

  cardHolderNameChange = (e) => {
    this.setState({
      cardHolderName: e.target.value.replace(/[^a-zA-Z\s]/g, ""),
    });
  };
  cardnumberChange = (e) => {
    this.setState({
      cardNumber: e.target.value.replace(/[^0-9]/g, ""),
    });
  };
  expirydateChange = (e) => {
    this.setState({
      expiryDate: e.target.value.replace(/[^0-9]/g, ""),
    });
  };
  cvvChange = (e) => {
    this.setState({
      cvv: e.target.value.replace(/[^0-9]/g, ""),
    });
  };

  setCardType = (e) => {
    this.setState({
      cardtype: e.target.value,
    });
  };

  authorizationnumberChange = (e) => {
    this.setState({
      authorizationnumber: e.target.value,
    });
  };
  resetError = () => {
    let errorMessage = {
      cardHolderName: "",
      cardNumber: "",
      expiryDate: "",
      cvv: "",
    };
    this.setState({ errorMessage: errorMessage });
  };
  saveOrder = () => {
    const actiontype = this.props.location.state.actiontype;
    if (actiontype === "REFUNDABLEORDERS") {
      let result = [];
      this.state.order.orderitems.forEach((item) => {
        result = [
          ...result,
          {
            orderitemid: item.orderitemid,
            orderedquantity: item.orderedamount,
            processedquantity: item.appliedamount,
          },
        ];
      });

      let parameter = {
        username: this.props.logindetails.username,
        sessionid: this.props.logindetails.sessionid,
        ordereditems: result,
        // extraOrder: this.props.extraOrders,
        amount: parseFloat(this.props.location.state.chargeableAmount).toFixed(2),
        processaction: "REFUNDORDER",
        exchangerate: this.state.exchangerate,
        currencycode: this.state.currencycode,
        orderid: this.props.order.orderid,
      };

      if (this.state.paymentMethod === "cash") {
        parameter = { ...parameter, paymentMethod: "cash" };
      } else if (this.state.paymentMethod === "originalcard") {
        parameter = {
          ...parameter,
          paymentMethod: "autocreditcard",
          creditcard: {
            // cardtype: this.state.cardtype,
            // cardholder: this.state.cardHolderName,
            // cardnumber: this.state.cardNumber,
            // authorizationnumber: this.state.authorizationnumber,
            amount: parseFloat(this.props.location.state.chargeableAmount).toFixed(2),
            // email: this.props.logindetails.userdefault.email,
          },
        };
      } else {
          parameter = {
          ...parameter,
          paymentMethod: "card",
          creditcard: {
            // cardtype: cardnumberData.card.type,
            // cardholder: this.state.cardHolderName,
            // cvv: this.state.cvv,
            // cardnumber: this.state.cardNumber,
            // expirydate: this.state.expiryDate,
            cardtype: this.state.cardtype,
            cardholder: this.state.cardHolderName,
            cardnumber: this.state.cardNumber,
            authorizationnumber: this.state.authorizationnumber,
            amount: parseFloat(this.props.location.state.chargeableAmount).toFixed(2),
            email: this.props.logindetails.userdefault.email,
          },
        };
      }
      this.props.orderSubmit(parameter, this.props.history);
    } else {
      let canvasSignature;

      if(SIGNATURE_REQUIRED == true) {
        if (this.props.signature === null || this.props.signature === undefined) {
          canvasSignature = this.sigPad.getCanvas().toDataURL("image/png");
          this.setState({ signatureDataURL: canvasSignature });
          this.props.addSignature(canvasSignature);

          this.setState((prevState) => ({
            isOpenSignature: !prevState.isOpenSignature,
          }));
        } else {
          canvasSignature = this.props.signature;
        }
      }

      let result = [];
      this.state.order.orderitems.forEach((item) => {
        result = [
          ...result,
          {
            orderitemid: item.orderitemid,
            orderedquantity: item.orderedamount,
            processedquantity: item.appliedamount,
          },
        ];
      });

      let processActionType;
      if (this.props.orderErrorType === "PNP_HELD_PAYMENT_FAIL") {
        processActionType = "FORCECHECKOUTORDER";
      } else {
        processActionType = "CHECKOUTORDER";
      }
      let parameter = {
        username: this.props.logindetails.username,
        sessionid: this.props.logindetails.sessionid,
        ordereditems: result,
        extraOrder: this.props.extraOrders,
        amount: parseFloat(this.props.location.state.chargeableAmount).toFixed(2),
        processaction: processActionType,
        exchangerate: this.state.exchangerate,
        currencycode: this.state.currencycode,
        orderid: this.props.order.orderid,
      };

      if(SIGNATURE_REQUIRED == true) {
        parameter = { ...parameter, signature: canvasSignature};
      }

      if (this.state.paymentMethod === "cash") {
        parameter = { ...parameter, paymentMethod: "cash" };
      } else {
        let cardNumber = this.state.cardNumber;
        const cardnumberData = valid.number(cardNumber);
        parameter = {
          ...parameter,
          paymentMethod: "card",
          creditcard: {
            cardtype: cardnumberData.card.type,
            cardholder: this.state.cardHolderName,
            cvv: this.state.cvv,
            cardnumber: this.state.cardNumber,
            expirydate: this.state.expiryDate,
          },
        };
      }
      this.props.orderSubmit(parameter, this.props.history);
    }
  };

  handleCashDetailSubmit = (e) => {
    e.preventDefault();
    const actiontype = this.props.location.state.actiontype;
    if (this.props.signature === null && actiontype !== "REFUNDABLEORDERS" && SIGNATURE_REQUIRED == true) {
      this.setState((prevState) => ({
        isOpenSignature: !prevState.isOpenSignature,
      }));
    } else {
      this.saveOrder();
    }
  };
  handleCardDetailSubmit = (event) => {
    event.preventDefault();

    const actiontype = this.props.location.state.actiontype;
    if (actiontype === "REFUNDABLEORDERS") {
      let errorMessage = {
        cardHolderName: "",
        cardNumber: "",
        cardtype: "",
        authorizationnumber: "",
      };

      let cardNumber = this.state.cardNumber;
      const cardHolderName = this.state.cardHolderName;
      const cardtype = this.state.cardtype;
      const authorizationnumber = this.state.authorizationnumber;

      if (!cardHolderName || cardHolderName.indexOf(" ") === 0) {
        errorMessage.cardHolderName = "Card Holder is required.";
      }
      if (!cardtype) {
        errorMessage.cardtype = "Card Type is required.";
      }

      if (!cardNumber) {
        errorMessage.cardNumber = "Card Number is required.";
      }

      if (!authorizationnumber) {
        errorMessage.authorizationnumber = "Authorization Number is required.";
      }

      this.setState({ errorMessage: errorMessage });
      if (cardHolderName && cardNumber && cardtype && authorizationnumber) {
        this.saveOrder();
      }
    } else {
      let errorMessage = {
        cardHolderName: "",
        cardNumber: "",
        expiryDate: "",
        cvv: "",
      };
      let cardNumber = this.state.cardNumber;

      const cardHolderName = this.state.cardHolderName;
      const expiryDate = this.state.expiryDate;
      const cvv = this.state.cvv;
      const cardnumberData = valid.number(cardNumber);

      if (!cardHolderName || cardHolderName.indexOf(" ") === 0) {
        errorMessage.cardHolderName = "Card Holder is required.";
      }
      if (!cardNumber) {
        errorMessage.cardNumber = "Card Number is required.";
      } else if (cardnumberData.card) {
        if (
          cardnumberData.card.type === "visa" ||
          cardnumberData.card.type === "mastercard"
        ) {
          if (!cardnumberData.isValid) {
            errorMessage.cardNumber = "Please enter a valid cardnumber.";
          }
        } else {
          errorMessage.cardNumber = "Please enter a valid cardnumber.";
        }
      } else {
        errorMessage.cardNumber = "Please enter a valid cardnumber.";
      }

      if (!expiryDate) {
        errorMessage.expiryDate = "ExpiryDate is required.";
      } else if (!valid.expirationDate(expiryDate).isValid) {
        errorMessage.expiryDate = "Please enter a valid expirydate.";
      }

      if (!cvv) {
        errorMessage.cvv = "CVV is required.";
      } else if (!valid.cvv(cvv, 3).isValid) {
        errorMessage.cvv = "Please enter a valid cvv.";
      }

      this.setState({ errorMessage: errorMessage });
      if (cardHolderName && cardNumber && expiryDate && cvv) {
        if (
          valid.number(cardNumber).isValid &&
          valid.expirationDate(expiryDate).isValid &&
          valid.cvv(cvv, 3).isValid &&
          cardHolderName.indexOf(" ") !== 0
        ) {
          if (
            cardnumberData.card.type !== "visa" ||
            cardnumberData.card.type !== "mastercard"
          ) {
            if (this.props.signature === null && SIGNATURE_REQUIRED == true) {
              this.setState((prevState) => ({
                isOpenSignature: !prevState.isOpenSignature,
              }));
            } else {
              this.saveOrder();
            }
          }
        }
      }
    }
  };
  handleOriginalCardRefundSubmit = async (e) => {
    e.preventDefault();
    const actiontype = this.props.location.state.actiontype;
    if (this.props.signature === null && actiontype !== "REFUNDABLEORDERS" && SIGNATURE_REQUIRED == true) {
      this.setState((prevState) => ({
        isOpenSignature: !prevState.isOpenSignature,
      }));
    } else {
      if (actiontype === "REFUNDABLEORDERS") {
        const PaymentGatewayDetail = JSON.parse(
          localStorage.getItem("PaymentGatewayDetail")
        );
        if (PaymentGatewayDetail.paymentgateway === "FAC") {
          let tmpOrderId = this.state.order.orderid;
          let tmpFACOrderId = this.state.order.pgworder.orderid;
          this.props.loaderStatus(true);
  
          let processTransactionResponse = await processTransaction({
            orderid: tmpOrderId,
            transactiontype: "STARTTRANSACTION",
            actiontype: "REFUNDORDER",
          });
  
          if (processTransactionResponse.success) {
            let processCardRequestBody = {};
            let tmpRefundAmount = parseFloat(this.state.calculatedValue);
            tmpRefundAmount = tmpRefundAmount.toFixed(2);
            
            processCardRequestBody = {
              amount: parseFloat(tmpRefundAmount),
              modificationtype: 2,
              actiontype: "MODIFYTRANSACTION",
              orderid: tmpFACOrderId,
            }
            
            console.log("processCardRequestBody", processCardRequestBody);
            const response = await processCard(processCardRequestBody);
            console.log("responses", response);
            this.props.loaderStatus(false);
  
            console.log("processCard Reponse Return", JSON.stringify(response));
            if (response.success) {
                let request = this.props.location.state.request;
                request = {
                  ...request,
                  transactionCode: 0,
                  // paymentResponse: response.data,
                };
                this.saveOrder();
              } else {
                await processTransaction({
                  orderid: processCardRequestBody.orderid,
                  transactiontype: "ENDTRANSACTION",
                  actiontype: "REFUNDORDER",
                });
                this.setState({
                  displayDialog: true,
                  dialogTitle: "Alert!",
                  dialogBody: response.statusMessage,
                  dialogFooter: (
                    <>
                      <button
                        className="btn"
                        style={styles.dialogConfirmButtonStyle}
                        onClick={() => {
                          this.closeDialog();
                        }}
                      >
                        Ok
                      </button>
                    </>
                  ),
                });
              }
            } else {
              this.props.loaderStatus(false);
  
              this.setState({
                displayDialog: true,
                dialogTitle: "Alert!",
                dialogBody: processTransactionResponse.statusMessage,
                dialogFooter: (
                  <>
                    <button
                      className="btn"
                      style={styles.dialogConfirmButtonStyle}
                      onClick={() => {
                        this.closeDialog();
                      }}
                    >
                      Ok
                    </button>
                  </>
                ),
              });
            }
        } else {
          this.saveOrder();
        }
      }      
    }
  };
  calcSubTotalPrice = () => {
    let charge = 0;
    let total = 0;
    this.props.location.state.order.orderitems.forEach((item) => {
      charge = item.unitcost * item.appliedamount;
      total += parseFloat(charge);
    });

    return total.toFixed(2);
  };

  calcTaxPrice = () => {
    let charge = 0;
    let taxTotal = 0;
    this.props.location.state.order.orderitems.forEach((item) => {
      charge = item.unitcost * item.appliedamount;
      taxTotal += parseFloat(charge) * parseFloat(item.taxrate);
    });

    return taxTotal.toFixed(2);
  };

  calculateServiceFee_ServiceFeeTax = () => {
    let orderAmount_new = 0;
    let appliedamount_new = 0;
    let extraAmount = 0;
    let extrataxAmount = 0;
    let appliedtaxamount = 0;
    let tmpAppliedServiceFee = 0;
    let tmpAppliedServiceFeeTax = 0;
    let orderServiceFeeRate = 0;
    let orderServiceFeeTaxRate = 0;
    let orderServiceFee = 0;    
    let orderServiceFeeRefundable = "N";

    let tmpServiceFee = 0;
    let tmpServiceFeeTax = 0;
    let tmpRefundableServiceFee = 0;
    let tmpRefundableServiceFeeTax = 0;

    orderServiceFeeRate = this.props.location.state.order?.servicefee?.servicefeerate;
    orderServiceFeeTaxRate = this.props.location.state.order?.servicefee?.servicefeetaxrate;
    orderServiceFee = this.props.location.state.order?.servicefee?.servicefee;
    orderServiceFeeRefundable = this.props.location.state.order?.servicefee?.servicefeerefundable;  

    const tempOrderitems = [...this.props.location.state.order.orderitems];
    tempOrderitems.forEach((orderitem) => {
      /****************************Count Applied Tax AMount**********************/
      let applied_total = orderitem.unitcost * orderitem.appliedamount;
      appliedtaxamount += parseFloat(applied_total) * parseFloat(orderitem.taxrate);
      /****************************Count Applied Tax AMount**********************/

      appliedamount_new += orderitem.unitcost * orderitem.appliedamount;

      let orderAmount_chagre = orderitem.unitcost * orderitem.orderedamount;
      let orderAmount_tax = parseFloat(orderAmount_chagre) * parseFloat(orderitem.taxrate);
      let charge = orderitem.unitcost * orderitem.orderedamount;

      if(orderitem?.servicefeeapplicable === "Y") {
        if(orderServiceFeeRefundable === "Y") {
          tmpServiceFee += parseFloat(orderAmount_chagre * orderServiceFeeRate);
          tmpAppliedServiceFee += parseFloat(applied_total * orderServiceFeeRate);
          tmpRefundableServiceFee += tmpAppliedServiceFee;
        }  
        else {
          tmpServiceFee += parseFloat(orderAmount_chagre * orderServiceFeeRate);
          tmpAppliedServiceFee += tmpServiceFee;
        }
      }     

      orderAmount_new += parseFloat(orderAmount_tax) + parseFloat(charge);
    });

    tmpServiceFee = parseFloat(tmpServiceFee.toFixed(2));
    tmpServiceFeeTax = parseFloat((tmpServiceFee * orderServiceFeeTaxRate).toFixed(2));

    tmpAppliedServiceFee = parseFloat(tmpAppliedServiceFee.toFixed(2));
    tmpAppliedServiceFeeTax = parseFloat((tmpAppliedServiceFee * orderServiceFeeTaxRate).toFixed(2));
    
    if(orderServiceFeeRefundable === "Y") {
      tmpRefundableServiceFeeTax = tmpAppliedServiceFeeTax;
    }

    this.setState({
      orderServiceFeeRefundable:orderServiceFeeRefundable,
      appliedServiceFee: tmpAppliedServiceFee,
      appliedServiceFeeTax: tmpAppliedServiceFeeTax,
      refundableServiceFee: tmpRefundableServiceFee,
      refundableServiceFeeTax: tmpRefundableServiceFeeTax,
    })
  };

  render() {
    if (!this.props.location.state) return null;
    return (
      <>
        <NavBar />
        <Loader message="Processing..." />
        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />
        {/* ******** Signature Modal */}
        <Modal
          show={this.state.isOpenSignature}
          centered
          onHide={this.hideSignatureModal}
        >
          <Modal.Header closeButton style={{ backgroundColor: "#F2F2F2" }}>
            <Modal.Title style={styles.dialogHeaderStyle}>
              Customer Signature
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SignatureCanvas
              penColor="black"
              canvasProps={{ width: 450, height: 300, className: "sigCanvas" }}
              ref={(ref) => {
                this.sigPad = ref;
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              disabled={this.sigPad}
              className="btn "
              onClick={this.saveOrder}
              style={styles.dialogConfirmButtonStyle}
            >
              Save & Proceed
            </button>
            <button
              className="btn"
              style={styles.dialogCancelButtonStyle}
              onClick={this.clear}
            >
              Reset
            </button>
          </Modal.Footer>
        </Modal>
        {/* ******** Signature Modal End*/}
        {/************Payment option Model */}
        <Modal show={this.state.isOpen} centered onHide={this.hideModal}>
          <Modal.Header style={{ backgroundColor: "#F2F2F2" }}>
            <Modal.Title style={styles.dialogHeaderStyle}>
              Select Payment option{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="radio">
              <label>
                <input
                  type="radio"
                  name="paymentOption"
                  value="cash"
                  onChange={this.paymentOption}
                  checked={this.state.paymentMethod === "cash"}
                />{" "}
                Cash
              </label>
            </div>
            { this.props.order?.pgworder?.orderid ? 
            <>
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    name="paymentOption"
                    value="card"
                    onChange={this.paymentOption}
                    checked={this.state.paymentMethod === "card"}
                  />{" "}
                  Card
                </label>
              </div>
              
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    name="paymentOption"
                    value="originalcard"
                    onChange={this.paymentOption}
                    checked={this.state.paymentMethod === "originalcard"}
                  />{" "}
                  Original Card
                </label>
              </div>
            </>
            : null
            }
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn "
              style={styles.dialogConfirmButtonStyle}
              onClick={this.hideModal}
            >
              Continue
            </button>
          </Modal.Footer>
        </Modal>
        {/************Payment option Model End*/}

        {/* <div
          style={{
            backgroundColor: "#F1F1F1",
            borderTop: "1px solid #DDD",
            borderBottom: "1px solid #DDD",
          }}
        >
          <div style={{ padding: 20, textAlign: "center" }}>
            <button
              style={{
                backgroundColor: "transparent",
                border: "none",
                color: "#777",
                marginLeft: 20,
                marginTop: 20,
                float: "left",
                display: "inline-block",
              }}
              onClick={() => {
                this.props.history.push("/");
              }}
            >
              <FontAwesomeIcon
                icon="chevron-left"
                color="#777"
                className={'back-icon'}

              />
              Back
            </button>
            <div style={styles.pageHeadingStyle}>Payment Details</div>
            <div style={styles.pageSubHeadingStyle}>
              Grand Total(USD){" "}
              <label style={{ fontWeight: "bold" }}>
                ${this.props.location.state.chargeableAmount}{" "}
              </label>
            </div>
          </div>
        </div> */}
        <Container className="back-bar-container" fluid>
          <Row className={"back-bar categoryBar"}>
            <Col lg="12" md="12">
              <a
                className={"back-link"}
                onClick={() => this.props.history.goBack()}
              >
                <FontAwesomeIcon
                  icon="chevron-left"
                  color="#777"
                  className={"back-icon"}
                />
                Back
              </a>
            </Col>
          </Row>
        </Container>

        <div
          className="container-fluid stores-container"
          style={{ paddingTop: 30, paddingBottom: 100 }}
        >
          {this.state.paymentMethod === "card" ? (
            <>
              <form onSubmit={this.handleCardDetailSubmit}>
                <div className="row">
                  <div className="col-md-8">
                    <div className="card">
                      <div
                        style={{
                          // padding: 15,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div className={"card-head "}>
                          <h3 className="summary-title">
                            Payment Details{" "}
                            <small style={{ fontSize: 11 }}>
                              Credit Card Detail
                            </small>
                          </h3>
                        </div>
                      </div>
                      <div className="card-body">
                        {this.props.location.state.actiontype !==
                        "REFUNDABLEORDERS" ? (
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="cardholder">
                                  Card Holder Name
                                </label>
                                <input
                                  placeholder={"Enter Card Holder Name"}
                                  type="text"
                                  className="form-control"
                                  autoFocus={true}
                                  value={this.state.cardHolderName}
                                  onChange={this.cardHolderNameChange}
                                  onFocus={this.resetError}
                                />
                                {this.state.errorMessage.cardHolderName && (
                                  <small className="text-danger">
                                    {this.state.errorMessage.cardHolderName}
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="cardnumber">Card Number</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  maxLength="16"
                                  value={this.state.cardNumber}
                                  onChange={this.cardnumberChange}
                                  onFocus={this.resetError}
                                  placeholder={'Enter Card Number'}
                                />
                                {this.state.errorMessage.cardNumber && (
                                  <small className="text-danger">
                                    {this.state.errorMessage.cardNumber}
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="expirydate">Expiry Date</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={this.state.expiryDate}
                                  maxLength={4}
                                  placeholder={'Enter Expiry Date in MMYY'}
                                  onChange={this.expirydateChange}
                                  onFocus={this.resetError}
                                />
                                {/* <InputMask mask="99/99" className="form-control" value={this.state.expiryDate} onChange={this.expirydateChange} onFocus={this.resetError} /> */}
                                {this.state.errorMessage.expiryDate && (
                                  <small className="text-danger">
                                    {this.state.errorMessage.expiryDate}
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="cvv">CVV:</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  maxLength="3"
                                  value={this.state.cvv}
                                  onChange={this.cvvChange}
                                  onFocus={this.resetError}
                                  placeholder={'Enter CVV'}
                                />
                                {this.state.errorMessage.cvv && (
                                  <small className="text-danger">
                                    {this.state.errorMessage.cvv}
                                  </small>
                                )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="cardholder">
                                  Card Holder Name
                                </label>
                                <input
                                  type="text"
                                  placeholder="Enter Card Holder Name"
                                  className="form-control"
                                  autoFocus={true}
                                  value={this.state.cardHolderName}
                                  onChange={this.cardHolderNameChange}
                                  onFocus={this.resetError}
                                />
                                {this.state.errorMessage.cardHolderName && (
                                  <small className="text-danger">
                                    {this.state.errorMessage.cardHolderName}
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="cardnumber">Card Type</label>
                                <select
                                  className="form-control"
                                  onChange={this.setCardType}
                                  value={this.state.cardtype}
                                >
                                  <option value="">--Select Card--</option>
                                  <option value={"VISA"}>VISA</option>
                                  <option value={"MASTERCARD"}>
                                    MASTERCARD
                                  </option>
                                </select>
                                {this.state.errorMessage.cardtype && (
                                  <small className="text-danger">
                                    {this.state.errorMessage.cardtype}
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="cardnumber">Card Number</label>
                                <input
                                  placeholder="Enter Card Number"
                                  type="text"
                                  className="form-control"
                                  maxLength="4"
                                  value={this.state.cardNumber}
                                  onChange={this.cardnumberChange}
                                  onFocus={this.resetError}
                                />
                                {this.state.errorMessage.cardNumber && (
                                  <small className="text-danger">
                                    {this.state.errorMessage.cardNumber}
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="cvv">Authorization:</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  // maxLength="3"
                                  placeholder="Enter Authorization"
                                  value={this.state.authorizationnumber}
                                  onChange={this.authorizationnumberChange}
                                  onFocus={this.resetError}
                                />
                                {this.state.errorMessage
                                  .authorizationnumber && (
                                  <small className="text-danger">
                                    {
                                      this.state.errorMessage
                                        .authorizationnumber
                                    }
                                  </small>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <div className="row">
                  <div className="col-md-12">
                    <button
                      type="button"
                      onClick={this.showModalCard}
                      className="btn btn-info btn-primary  pull-right"
                      style={styles.dialogCancelButtonStyle}
                    >
                      Change Payment Method
                    </button>
                    <button
                      type="submit"
                      className="btn btn-info btn-primary pull-right"
                      style={{
                        marginRight: 13,
                        ...styles.dialogConfirmButtonStyle,
                      }}
                    >
                      Pay
                      </button>
                  </div>
                </div> */}
                  <div className="col-md-4">
                    <div className="card">
                      <div
                        style={{
                          padding: 15,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                            fontSize: 18,
                            color: "#555",
                          }}
                        >
                          <h3 className="summary-title">Order Summary</h3>
                        </div>
                      </div>
                      <div className="card-body">
                        <div
                          className="d-flex justify-content-between"
                          style={{ marginBottom: 10 }}
                        >
                          <div className="FS14 FWB">Sub Total</div>
                          <div className="FS14">
                            ${this.calcSubTotalPrice()}
                          </div>
                        </div>
                        <div className="d-flex justify-content-between MB10">
                          <div className="FS14 FWB">Tax</div>
                          <div className="FS14">${this.calcTaxPrice()}</div>
                        </div>

                        {  this.state.appliedServiceFee !==0 ?
                        <div className="d-flex justify-content-between MB10">
                          <div className="FS14 FWB">{this.state.orderServiceFeeRefundable === "Y" ? <><span className="asterisk">*</span></> : null} Service Fee</div>
                          <div className="FS14">${this.state.appliedServiceFee.toFixed(2)}</div>
                        </div>
                        : null 
                        }

                        { this.state.appliedServiceFeeTax !==0 ?
                        <div className="d-flex justify-content-between MB10">
                          <div className="FS14 FWB">{this.state.orderServiceFeeRefundable === "Y" ? <><span className="asterisk">*</span></> : null} Service Fee Tax</div>
                          <div className="FS14">${this.state.appliedServiceFeeTax.toFixed(2)}</div>
                        </div>
                        : null 
                        }

                        <div className={"seperator"}></div>
                        <div className="d-flex justify-content-between MB10">
                          <div className="FS16 FWB">Grand Total</div>
                          <div className="FS16 FWB">
                            $
                            {(
                              parseFloat(this.calcSubTotalPrice()) +
                              parseFloat(this.calcTaxPrice()) + this.state.refundableServiceFee + this.state.refundableServiceFeeTax
                            ).toFixed(2)}
                          </div>
                        </div>

                        {this.state.orderServiceFeeRefundable === "Y" ? <div className="FS14"><span className="asterisk">*</span> Service Fee & Service Fee Tax are non-refundable</div> : null}        

                        <button
                          type="button"
                          onClick={this.showModalCard}
                          style={{
                            backgroundColor: "#FED23D",
                            marginTop: 20,
                            fontSize: 14,
                            fontWeight: "bold",
                            color: "#333",
                          }}
                          className="btn btn-block btn-lg"
                        >
                          Change Payment Method
                        </button>
                        <button
                          type="submit"
                          style={{
                            backgroundColor: "#FED23D",
                            marginTop: 20,
                            fontSize: 14,
                            fontWeight: "bold",
                            color: "#333",
                          }}
                          className="btn btn-block btn-lg"
                        >
                          Refund
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </>
          ) : null}
          {this.state.paymentMethod === "cash" ? (
            <>
              <form onSubmit={this.handleCashDetailSubmit}>
                <div className="row">
                  <div className="col-md-8">
                    <div className="card">
                      <div
                        style={{
                          // padding: 15,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div className={"card-head "}>
                          <h3 className="summary-title">
                            Cash Payment Details{" "}
                            <small style={{ fontSize: 11 }}></small>
                          </h3>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="amount">Amount:</label>
                              <input
                                disabled
                                type="text"
                                className="form-control"
                                value={
                                  (parseFloat(this.props.location.state.chargeableAmount)).toFixed(2)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="amount">From:</label>
                              <input
                                disabled
                                type="text"
                                className="form-control"
                                value="US Dollar (USD)"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="amount">To:</label>
                              <select
                                className="form-control"
                                onChange={this.setCurrency}
                                value={this.state.currency}
                              >
                                <option value="">--Select Currency--</option>
                                {this.props.userDetail.currencies.map(
                                  (item) => (
                                    <>
                                      {/* {item.currencycode !== "USD" ? ( */}
                                      <option
                                        data-exchangerate={item.exchangerate}
                                        data-currencycode={item.currencycode}
                                        value={item.currencycode}
                                      >
                                        {item.description} ({item.currencycode})
                                      </option>
                                      {/* ) : null} */}
                                    </>
                                  )
                                )}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="amount">Amount to Charge:</label>
                              <input
                                disabled
                                type="text"
                                className="form-control"
                                value={this.state.calculatedValue}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div
                        style={{
                          padding: 15,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                            fontSize: 18,
                            color: "#555",
                          }}
                        >
                          <h3 className="summary-title">Order Summary</h3>
                        </div>
                      </div>
                      <div className="card-body">
                        <div
                          className="d-flex justify-content-between"
                          style={{ marginBottom: 10 }}
                        >
                          <div className="FS14 FWB">Sub Total</div>
                          <div className="FS14">
                            ${this.calcSubTotalPrice()}
                          </div>
                        </div>
                        <div className="d-flex justify-content-between MB10">
                          <div className="FS14 FWB">Tax</div>
                          <div className="FS14">${this.calcTaxPrice()}</div>
                        </div>

                        {  this.state.appliedServiceFee !==0 ?
                        <div className="d-flex justify-content-between MB10">
                          <div className="FS14 FWB">{this.state.orderServiceFeeRefundable === "Y" ? <><span className="asterisk">*</span></> : null} Service Fee</div>
                          <div className="FS14">${this.state.appliedServiceFee.toFixed(2)}</div>
                        </div>
                        : null 
                        }

                        { this.state.appliedServiceFeeTax !==0 ?
                        <div className="d-flex justify-content-between MB10">
                          <div className="FS14 FWB">{this.state.orderServiceFeeRefundable === "Y" ? <><span className="asterisk">*</span></> : null} Service Fee Tax</div>
                          <div className="FS14">${this.state.appliedServiceFeeTax.toFixed(2)}</div>
                        </div>
                        : null 
                        }

                        <div className={"seperator"}></div>
                        <div className="d-flex justify-content-between MB10">
                          <div className="FS16 FWB">Grand Total</div>
                          <div className="FS16 FWB">
                            $
                            {(
                              parseFloat(this.calcSubTotalPrice()) +
                              parseFloat(this.calcTaxPrice()) + this.state.refundableServiceFee + this.state.refundableServiceFeeTax
                            ).toFixed(2)}
                          </div>
                        </div>

                        {this.state.orderServiceFeeRefundable === "Y" ? <div className="FS14"><span className="asterisk">*</span> Service Fee & Service Fee Tax are non-refundable</div> : null}        

                        { this.props.order?.pgworder?.orderid ? 
                        <button
                          type="button"
                          onClick={this.showModalCard}
                          style={{
                            backgroundColor: "#FED23D",
                            marginTop: 20,
                            fontSize: 14,
                            fontWeight: "bold",
                            color: "#333",
                          }}
                          className="btn btn-block btn-lg"
                        >
                          Change Payment Method
                        </button>
                        : null
                        }
                        <button
                          type="submit"
                          style={{
                            backgroundColor: "#FED23D",
                            marginTop: 20,
                            fontSize: 14,
                            fontWeight: "bold",
                            color: "#333",
                          }}
                          className="btn btn-block btn-lg"
                        >
                          Refund
                          {/* {this.state.currencycode} */}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </>
          ) : null}
          {this.state.paymentMethod === "originalcard" ? (
            <>
              <form onSubmit={this.handleOriginalCardRefundSubmit}>
                <div className="row">
                  <div className="col-md-8">
                    <div className="card">
                      <div
                        style={{
                          // padding: 15,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div className={"card-head "}>
                          <h3 className="summary-title">
                            Original Card Refund Details{" "}
                            <small style={{ fontSize: 11 }}></small>
                          </h3>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="amount">Amount:</label>
                              <input
                                disabled
                                type="text"
                                className="form-control"
                                value={
                                  (parseFloat(this.props.location.state.chargeableAmount)).toFixed(2)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="amount">Amount to Refund:</label>
                              <input
                                disabled
                                type="text"
                                className="form-control"
                                value={this.state.calculatedValue}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div
                        style={{
                          padding: 15,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                            fontSize: 18,
                            color: "#555",
                          }}
                        >
                          <h3 className="summary-title">Order Summary</h3>
                        </div>
                      </div>
                      <div className="card-body">
                        <div
                          className="d-flex justify-content-between"
                          style={{ marginBottom: 10 }}
                        >
                          <div className="FS14 FWB">Sub Total</div>
                          <div className="FS14">
                            ${this.calcSubTotalPrice()}
                          </div>
                        </div>
                        <div className="d-flex justify-content-between MB10">
                          <div className="FS14 FWB">Tax</div>
                          <div className="FS14">${this.calcTaxPrice()}</div>
                        </div>

                        {  this.state.appliedServiceFee !==0 ?
                        <div className="d-flex justify-content-between MB10">
                          <div className="FS14 FWB">{this.state.orderServiceFeeRefundable === "Y" ? <><span className="asterisk">*</span></> : null} Service Fee</div>
                          <div className="FS14">${this.state.appliedServiceFee.toFixed(2)}</div>
                        </div>
                        : null 
                        }

                        { this.state.appliedServiceFeeTax !==0 ?
                        <div className="d-flex justify-content-between MB10">
                          <div className="FS14 FWB">{this.state.orderServiceFeeRefundable === "Y" ? <><span className="asterisk">*</span></> : null} Service Fee Tax</div>
                          <div className="FS14">${this.state.appliedServiceFeeTax.toFixed(2)}</div>
                        </div>
                        : null 
                        }

                        <div className={"seperator"}></div>
                        <div className="d-flex justify-content-between MB10">
                          <div className="FS16 FWB">Grand Total</div>
                          <div className="FS16 FWB">
                            $
                            {(
                              parseFloat(this.calcSubTotalPrice()) +
                              parseFloat(this.calcTaxPrice()) + this.state.refundableServiceFee + this.state.refundableServiceFeeTax
                            ).toFixed(2)}
                          </div>
                        </div>
                        {this.state.orderServiceFeeRefundable === "Y" ? <div className="FS14"><span className="asterisk">*</span> Service Fee & Service Fee Tax are non-refundable</div> : null}        
                        <button
                          type="button"
                          onClick={this.showModalCard}
                          style={{
                            backgroundColor: "#FED23D",
                            marginTop: 20,
                            fontSize: 14,
                            fontWeight: "bold",
                            color: "#333",
                          }}
                          className="btn btn-block btn-lg"
                        >
                          Change Payment Method
                        </button>
                        <button
                          type="submit"
                          style={{
                            backgroundColor: "#FED23D",
                            marginTop: 20,
                            fontSize: 14,
                            fontWeight: "bold",
                            color: "#333",
                          }}
                          className="btn btn-block btn-lg"
                        >
                          Refund
                          {/* {this.state.currencycode} */}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </>
          ) : null}
        </div>

        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  orders: state.VendorOrderReducer.orders,
  userDetail: state.SignInReducer.logindetails,
  logindetails: state.SignInReducer.logindetails,
  users: state.UserReducer.users,
  extraOrders: state.OrderProcessReducer.extraOrder,
  orderProcessError: state.OrderProcessReducer.orderProcessError,
  orderProcessSuccess: state.OrderProcessReducer.orderProcessSuccess,
  order: state.OrderProcessReducer.order,
  signature: state.OrderProcessReducer.signature,
  orderErrorType: state.OrderProcessReducer.orderErrorType,
});

export default connect(mapStateToProps, { orderSubmit, addSignature, loaderStatus })(
  Orderrefund
);
